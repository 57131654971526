





















import { Component, Vue, Prop } from "vue-property-decorator";
import ProcessCellListItem from "@/components/apps/assetdetails/ProcessCellListItem.vue";
import { ProcessCell, Asset } from "@/models/asset";
import assetService from "@remote-api/asset.service";
import { filterAssets } from "@/services/asset.ui-service";
import { FilterModel } from "@/ui-models";
@Component({
  components: { ProcessCellListItem }
})
export default class ProcessCellList extends Vue {
  @Prop()
  processCell!: ProcessCell;

  private assets: Asset[] = [];
  private filterModel: FilterModel = new FilterModel();

  async mounted() {
    if (this.processCell) {
      this.assets = await assetService.fetchAssetsInProcessCell(this.processCell.id);
    }
  }

  get filteredAssets(): Asset[] {
    return filterAssets(this.assets, this.filterModel);
  }

  filterByText(filter: string) {
    this.filterModel.freeText = filter || "";
  }
}
