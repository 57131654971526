import { Storage, StorageClass } from "@aws-amplify/storage";
import globalConfig from "@/globals/Configuration";
import awsconfig from "@/configs/aws-exports";

// Adds support for multiple buckets using Storage API based on the undocumented
// bucket config parameter: https://github.com/aws-amplify/amplify-js/issues/329

const buildStorageClient = (
  bucket: string | undefined = undefined
): Pick<StorageClass, "get" | "put" | "remove" | "list"> => ({
  get: (key: string, config?: any): Promise<string | Record<string, any>> => {
    return Storage.get(key, { ...config, bucket });
  },
  put: (key: string, object: any, config?: any): Promise<Record<string, any>> => {
    return Storage.put(key, object, { ...config, bucket });
  },
  remove: (key: string, config?: any): Promise<any> => {
    return Storage.remove(key, { ...config, bucket });
  },
  list: (key: string, config?: any): Promise<any> => {
    return Storage.list(key, { ...config, bucket });
  }
});

export const defaultStorage = buildStorageClient(awsconfig.Storage.AWSS3.bucket);
export const smartGuideStorage = buildStorageClient(globalConfig.smartGuide.bucketName);
export const smartCollegeStorage = buildStorageClient("fette-smarttcollegetestbucket1");
