import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import { getContact } from "@/services/contacts.service";
import type VueI18n from "vue-i18n";
import { LearnClusterModel } from "./LearnClusterModel";

export class SmartCollegeModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}
  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("smartcollege.details.teaser.text")
      },
      features: [
        {
          title: this.$i18n.tc("smartcollege.details.features.oemContent.title"),
          text: this.$i18n.tc("smartcollege.details.features.oemContent.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_OEMcontent_3233352_000000.svg")
        },
        {
          title: this.$i18n.tc("smartcollege.details.features.ondemand.title"),
          text: this.$i18n.tc("smartcollege.details.features.ondemand.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_ondemandtraining_2946579_000000.svg")
        },
        {
          title: this.$i18n.tc("smartcollege.details.features.3d.title"),
          text: this.$i18n.tc("smartcollege.details.features.3d.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_3d-animation_1640041_000000.svg")
        }
      ],
      contactTeaser: this.$i18n.tc("smartcollege.details.contactTeaser"),
      explanation: {
        title: this.$i18n.tc("smartcollege.details.explanation.title"),
        text: this.$i18n.tc("smartcollege.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/smartcollege_ipad.png")
      },
      featureTeaser: this.$i18n.tc("smartcollege.details.featureTeaser")
    };
  }

  get logo(): string {
    return require("@/assets/ui-assets/assets/alva_logo.png");
  }

  get cluster(): ClusterModel {
    return new LearnClusterModel(this.$i18n);
  }

  get product(): ProductModel {
    return {
      id: "alva",
      title: this.$i18n.tc("smartcollege.product.title"),
      description: this.$i18n.tc("smartcollege.product.description"),
      image: require("@/assets/ui-assets/assets/design01/SmartCollege_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/SmartCollege_mehrfarbig_RGB.svg")
    };
  }

  get contacts(): ContactModel[] {
    return [getContact("tschley")];
  }
}
