import * as queries from "@/graphql/conditionmonitor/queries";
import {
  AssetState,
  AssetSummaryState,
  TimelineSeriesGrouped,
  TimeSeriesData
} from "@/models/asset";
import { conditionMonitorClient } from "@/plugins/graphql";
import { RemoteStatusService } from "@/services/remote-status.service";

export interface ConditionService {
  fetchAssetSummaryStates(assetIds: string[]): Promise<AssetSummaryState[]>;
  fetchAssetSummaryState(assetId: string): Promise<AssetSummaryState>;
  fetchAssetState(assetId: string, siteId?: string, after?: number): Promise<AssetState[]>;
  fetchPressureV2(
    assetId: string,
    after: number,
    timeWindow: string,
    aggregateFunction: string
  ): Promise<[string, string | number][]>;
  fetchSRel(
    assetId: string,
    currentBatchName: string
  ): Promise<Record<string, [string, string | number][]>>;
  fetchEnergyConsumption(assetId: string, currentBatchName: string): Promise<TimelineSeriesGrouped>;
  fetchOee(assetId: string, currentBatchName: string): Promise<TimelineSeriesGrouped>;
  fetchBatchHistory(assetId: string, currentBatchName: string): Promise<TimeSeriesData>;
}

export class RemoteConditionService implements ConditionService {
  fetchBatchHistory(assetId: string, currentBatchName: string): Promise<TimeSeriesData> {
    this.statusService.beforeRemoteCall();
    return conditionMonitorClient
      .query({
        query: queries.getBatchHistory,
        variables: { assetId, batchName: currentBatchName },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getBatchHistory } }: any) => JSON.parse(getBatchHistory)?.["DP0025"] ?? [])
      .finally(() => this.statusService.afterRemoteCall());
  }
  async fetchOee(assetId: string, currentBatchName: string): Promise<TimelineSeriesGrouped> {
    this.statusService.beforeRemoteCall();
    return conditionMonitorClient
      .query({
        query: queries.getOee,
        variables: { assetId, batchName: currentBatchName },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getOee } }: any) => JSON.parse(getOee))
      .finally(() => this.statusService.afterRemoteCall());
  }

  fetchEnergyConsumption(
    assetId: string,
    currentBatchName: string
  ): Promise<TimelineSeriesGrouped> {
    this.statusService.beforeRemoteCall();

    return conditionMonitorClient
      .query({
        query: queries.getEnergyConsumption,
        variables: { assetId, batchName: currentBatchName },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getEnergyConsumption } }: any) => JSON.parse(getEnergyConsumption))
      .finally(() => this.statusService.afterRemoteCall());
  }
  async fetchSRel(
    assetId: string,
    currentBatchName: string
  ): Promise<Record<string, [string, string | number][]>> {
    this.statusService.beforeRemoteCall();
    return conditionMonitorClient
      .query({
        query: queries.getMainCompressionForceSRel,
        variables: { assetId, batchName: currentBatchName },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getMainCompressionForceSRel } }: any) =>
        JSON.parse(getMainCompressionForceSRel)
      )
      .finally(() => this.statusService.afterRemoteCall());
  }

  fetchPressureV2(
    assetId: string,
    after: number,
    timeWindow: string,
    aggregateFunction: string
  ): Promise<[string, string | number][]> {
    this.statusService.beforeRemoteCall();

    return conditionMonitorClient
      .query({
        query: queries.getPressureV2,
        variables: { assetId, beforeHours: after, aggregateFunction, timeWindow },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getPressureV2 } }: any) => getPressureV2)
      .finally(() => this.statusService.afterRemoteCall());
  }

  private readonly statusService = new RemoteStatusService();

  async fetchAssetState(assetId: string, siteId?: string, after?: number): Promise<AssetState[]> {
    const gqlResult = await conditionMonitorClient.query<queries.Query>({
      query: queries.getAssetStates,
      variables: { assetId, siteId: String(siteId), after: after || null },
      fetchPolicy: "no-cache"
    });

    const { data } = gqlResult;
    return data.getAssetStates;
  }

  fetchAssetSummaryState(assetId: string): Promise<AssetSummaryState> {
    return conditionMonitorClient
      .query({
        query: queries.getAssetSummaryState,
        variables: { assetId }
      })
      .then(({ data: { getAssetSummaryState } }: any) => getAssetSummaryState);
  }

  fetchAssetSummaryStates(assetIds: string[]): Promise<AssetSummaryState[]> {
    this.statusService.beforeRemoteCall();

    return conditionMonitorClient
      .query({
        query: queries.getAssetSummaryStates,
        variables: { assetIds },
        fetchPolicy: "no-cache"
      })
      .then(({ data: { getAssetSummaryStates } }: any) => getAssetSummaryStates)
      .finally(() => this.statusService.afterRemoteCall());
  }
}

export default new RemoteConditionService();
