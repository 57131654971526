



































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import Asset from "@/components/apps/assetmanager/Asset.vue";
import AddAssetCard from "@/components/apps/assetmanager/AddAssetCard.vue";
import {
  Site as SiteModel,
  ProcessCell as ProcessCellModel,
  Asset as AssetModel
} from "@/models/asset";
import * as R from "remeda";

@Component({
  components: {
    Asset,
    AddAssetCard
  }
})
export default class ProcessCell extends Vue {
  @Prop()
  private assets!: AssetModel[];

  @Emit()
  addAsset() {
    return true;
  }

  @Emit()
  edit() {
    return this.processCell;
  }

  @Emit()
  openMapOverlay() {
    return this.assets[0];
  }

  get sortedAssets(): AssetModel[] {
    return R.sortBy(this.assets, (it) => it.name.toLowerCase());
  }

  get site(): SiteModel {
    const assetRepresentor = this.assets[0];
    return assetRepresentor.site;
  }

  get processCell(): ProcessCellModel {
    const assetRepresentor = this.assets[0];
    return assetRepresentor!.processCell!;
  }
}
