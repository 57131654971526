import deDefault from "./locales/de.json";
import enDefault from "./locales/en.json";
import esDefault from "./locales/es.json";
import ptDefault from "./locales/pt.json";
import zhDefault from "./locales/zh.json";

import deCondMon from "@/i18n/locales/products/de.condmon.json";
import enCondMon from "@/i18n/locales/products/en.condmon.json";
import esCondMon from "@/i18n/locales/products/es.condmon.json";
import ptCondMon from "@/i18n/locales/products/pt.condmon.json";
import zhCondMon from "@/i18n/locales/products/zh.condmon.json";

import dePredMain from "@/i18n/locales/products/de.predMain.json";
import enPredMain from "@/i18n/locales/products/en.predMain.json";
import esPredMain from "@/i18n/locales/products/es.predMain.json";
import ptPredMain from "@/i18n/locales/products/pt.predMain.json";
import zhPredMain from "@/i18n/locales/products/zh.predMain.json";

import dePam from "@/i18n/locales/products/de.pam.json";
import enPam from "@/i18n/locales/products/en.pam.json";
import esPam from "@/i18n/locales/products/es.pam.json";
import ptPam from "@/i18n/locales/products/pt.pam.json";
import zhPam from "@/i18n/locales/products/zh.pam.json";

import deVRCampus from "@/i18n/locales/products/de.vrcampus.json";
import enVRCampus from "@/i18n/locales/products/en.vrcampus.json";
import esVRCampus from "@/i18n/locales/products/es.vrcampus.json";
import ptVRCampus from "@/i18n/locales/products/pt.vrcampus.json";
import zhVRCampus from "@/i18n/locales/products/zh.vrcampus.json";

import deSmartCollege from "@/i18n/locales/products/de.smartcollege.json";
import enSmartCollege from "@/i18n/locales/products/en.smartcollege.json";
import esSmartCollege from "@/i18n/locales/products/es.smartcollege.json";
import ptSmartCollege from "@/i18n/locales/products/pt.smartcollege.json";
import zhSmartCollege from "@/i18n/locales/products/zh.smartcollege.json";

import deToolMan from "@/i18n/locales/products/de.toolman.json";
import enToolMan from "@/i18n/locales/products/en.toolman.json";
import esToolMan from "@/i18n/locales/products/es.toolman.json";
import ptToolMan from "@/i18n/locales/products/pt.toolman.json";
import zhToolMan from "@/i18n/locales/products/zh.toolman.json";

import deSmartGuide from "@/i18n/locales/products/de.smartguide.json";
import enSmartGuide from "@/i18n/locales/products/en.smartguide.json";
import esSmartGuide from "@/i18n/locales/products/es.smartguide.json";
import ptSmartGuide from "@/i18n/locales/products/pt.smartguide.json";
import zhSmartGuide from "@/i18n/locales/products/zh.smartguide.json";

import deSmartInterface from "@/i18n/locales/products/de.smartinterface.json";
import enSmartInterface from "@/i18n/locales/products/en.smartinterface.json";
import esSmartInterface from "@/i18n/locales/products/es.smartinterface.json";
import ptSmartInterface from "@/i18n/locales/products/pt.smartinterface.json";
import zhSmartInterface from "@/i18n/locales/products/zh.smartinterface.json";

import dePerfMan from "@/i18n/locales/products/de.perfman.json";
import enPerfMan from "@/i18n/locales/products/en.perfman.json";
import esPerfMan from "@/i18n/locales/products/es.perfman.json";
import ptPerfMan from "@/i18n/locales/products/pt.perfman.json";
import zhPerfMan from "@/i18n/locales/products/zh.perfman.json";

import deLiveGuide from "@/i18n/locales/products/de.liveguide.json";
import enLiveGuide from "@/i18n/locales/products/en.liveguide.json";
import esLiveGuide from "@/i18n/locales/products/es.liveguide.json";
import ptLiveGuide from "@/i18n/locales/products/pt.liveguide.json";
import zhLiveGuide from "@/i18n/locales/products/zh.liveguide.json";

import deDataPrivacy from "@/i18n/locales/privacyPolicy/de.dataPrivacy.json";
import enDataPrivacy from "@/i18n/locales/privacyPolicy/en.dataPrivacy.json";
// import esDataPrivacy from "@/i18n/locales/privacyPolicy/es.dataPrivacy.json";
// import ptDataPrivacy from "@/i18n/locales/privacyPolicy/pt.dataPrivacy.json";
// import zhDataPrivacy from "@/i18n/locales/privacyPolicy/zh.dataPrivacy.json";

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: window.navigator.language,
  fallbackLocale: "en",
  messages: {
    en: {
      ...enDefault,
      ...enCondMon,
      ...enPam,
      ...enVRCampus,
      ...enSmartCollege,
      ...enLiveGuide,
      ...enSmartGuide,
      ...enPerfMan,
      ...enSmartInterface,
      ...enPredMain,
      ...enToolMan,
      ...enDataPrivacy
    },
    de: {
      ...deDefault,
      ...deCondMon,
      ...dePam,
      ...deVRCampus,
      ...deSmartCollege,
      ...deLiveGuide,
      ...deSmartGuide,
      ...dePerfMan,
      ...deSmartInterface,
      ...dePredMain,
      ...deToolMan,
      ...deDataPrivacy
    },
    // pt: {
    //   ...ptDefault,
    //   ...ptCondMon,
    //   ...ptPam,
    //   ...ptVRCampus,
    //   ...ptSmartCollege,
    //   ...ptLiveGuide,
    //   ...ptSmartGuide,
    //   ...ptPerfMan,
    //   ...ptSmartInterface,
    //   ...ptPredMain,
    //   ...ptToolMan
    // },
    es: {
      ...esDefault,
      ...esCondMon,
      ...esPam,
      ...esVRCampus,
      ...esSmartCollege,
      ...esLiveGuide,
      ...esSmartGuide,
      ...esPerfMan,
      ...esSmartInterface,
      ...esPredMain,
      ...esToolMan
    },
    zh: {
      ...zhDefault,
      ...zhCondMon,
      ...zhPam,
      ...zhVRCampus,
      ...zhSmartCollege,
      ...zhLiveGuide,
      ...zhSmartGuide,
      ...zhPerfMan,
      ...zhSmartInterface,
      ...zhPredMain,
      ...zhToolMan
    }
  },
  silentTranslationWarn: true,
  sync: false
});
