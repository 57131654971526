























import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { LatLngBounds, Marker, FeatureGroup } from "leaflet";
import { Location, Asset } from "@/models/asset";

@Component({
  components: { LMap, LTileLayer, LMarker, LTooltip }
})
export default class SitesMap extends Vue {
  @Prop()
  private sites!: Record<string, Asset[]>;

  @Prop()
  private state!: boolean;

  @Emit()
  private siteSelected(siteId: string) {
    return siteId;
  }

  private tooltipOptions = {
    permanent: true,
    direction: "right"
  };

  get locations(): (Location & { count: number; siteId: string })[] {
    if (!this.sites) {
      return [];
    }
    return Object.keys(this.sites).map((key) => {
      const site = this.sites[key][0].site;
      const location = site.location;
      const count = this.sites[key].length || 0;
      return { ...location, count, siteId: site.id };
    });
  }

  private bounds = new LatLngBounds([0, 0], [0, 0]);
  private zoom = 10;

  private tileProvider = {
    name: "OpenStreetMap",
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png"
  };

  @Watch("sites")
  sitesChanged() {
    if (this.sites) {
      this.bounds = this.calculateBounds(this.locations);
      this.zoom = (this.$children[0] as LMap).mapObject.getBoundsZoom(this.bounds);
    }
  }

  @Watch("state")
  stateChanged() {
    (this.$children[0] as LMap).mapObject.invalidateSize();
    this.sitesChanged();
  }

  mounted() {
    if (this.sites) {
      this.sitesChanged();
    }
  }

  private calculateBounds(locations: Location[]): LatLngBounds {
    const markers = locations.map((it) => {
      return new Marker({ lat: it.latitude, lng: it.longitude });
    });
    const markerGroup = new FeatureGroup(markers);
    return markerGroup.getBounds();
  }
}
