import logger from "@/plugins/logger";
import router from "@/router";
import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { getTenantFromGroups } from "./tenant";

export async function getCurrentAuthenticatedUser(): Promise<CognitoUser | any> {
  return Auth.currentAuthenticatedUser();
}

export async function getUserGroups(): Promise<string[]> {
  return (await Auth.currentSession()).getAccessToken().payload["cognito:groups"];
}

export async function getTenant(): Promise<string> {
  const groups = await getUserGroups();
  return getTenantFromGroups(groups);
}

export async function getJwToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export function signOut() {
  return Auth.signOut()
    .then(() => router.push("/"))
    .catch((error) => {
      logger.error("Unable to log user out.", error);
    });
}
