




















































import { Component, Vue, Emit, Prop, PropSync } from "vue-property-decorator";
import { FilterModel, FilterTuple } from "@/ui-models";
import { sortBy, uniqBy } from "remeda";
import { Asset } from "@/models/asset";

@Component
export default class FilterBox extends Vue {
  @Prop()
  assets!: Asset[];

  @PropSync("filter", { type: FilterModel, default: () => new FilterModel() })
  filterModel!: any;

  @Emit()
  openMapOverlay(): boolean {
    return true;
  }

  private selectedSite = "";

  sortByName = (element: { name: string }): string => element.name.toLowerCase();

  get tuples() {
    const assetTypeTuples: FilterTuple[] = [];
    const siteTuples: FilterTuple[] = [];
    const processCellTuples: FilterTuple[] = [];
    this.assets.forEach((asset) => {
      assetTypeTuples.push(new FilterTuple(asset.type.name, asset.type.id));
      siteTuples.push(new FilterTuple(asset.site.name, asset.site.id));
      if (asset.processCell) {
        processCellTuples.push(new FilterTuple(asset.processCell.name, asset.processCell.id));
      }
    });
    return {
      assetTypeTuples: sortBy(
        uniqBy(assetTypeTuples, (it) => it.id),
        this.sortByName
      ),
      siteTuples: sortBy(
        uniqBy(siteTuples, (it) => it.id),
        this.sortByName
      ),
      processCellTuples: sortBy(
        uniqBy(processCellTuples, (it) => it.id),
        this.sortByName
      )
    };
  }

  filterBySite(siteId: string): void {
    this.filterModel.siteId = siteId ?? "";
  }

  filterByAssetType(assetTypeId: string): void {
    this.filterModel.assetTypeId = assetTypeId ?? "";
  }

  filterByProcessCell(processCellId: string): void {
    this.filterModel.processCellId = processCellId ?? "";
  }

  filterByText(textFilter: string): void {
    this.filterModel.freeText = textFilter ?? "";
  }

  clearAllFilters(): void {
    this.filterModel = new FilterModel();
  }
}
