import * as mutations from "@/graphql/smartguide/mutations";
import * as queries from "@/graphql/smartguide/queries";
import { SmartGuide, SmartGuidePage } from "@/models/smartguide";
import { smartGuideClient } from "@/plugins/graphql";
import { Asset } from "../../models/asset";

export interface SmartGuideService {
  // SmartGuide:
  fetchSmartGuide(id: string): Promise<SmartGuide | undefined>;
  fetchSmartGuides(): Promise<SmartGuide[]>;
  fetchSmartGuidesForAsset(asset: Asset): Promise<SmartGuide[]>;
  updateSmartGuide(smartGuide: SmartGuide): Promise<SmartGuide>;
  deleteSmartGuide(smartGuideId: string, tenant: string): Promise<void>;
  deleteSmartGuideMedia(smartGuideId: string, tenant: string): Promise<void>;
  // SmartGuidePage:
  fetchSmartGuidePage(pageId: string): Promise<SmartGuidePage>;
  fetchSmartGuidePages(smartGuideId: string): Promise<SmartGuidePage[]>;
  updateSmartGuidePage(smartGuidePage: SmartGuidePage): Promise<SmartGuidePage>;
  deleteSmartGuidePages(pageIds: string[], tenant: string): Promise<void>;
}

class RemoteSmartGuideService implements SmartGuideService {
  fetchSmartGuides(): Promise<SmartGuide[]> {
    return smartGuideClient
      .query<queries.Query>({
        query: queries.getSmartGuides,
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getSmartGuides);
  }

  fetchSmartGuide(id: string): Promise<SmartGuide | undefined> {
    return smartGuideClient
      .query<queries.Query>({
        query: queries.getSmartGuide,
        fetchPolicy: "no-cache",
        variables: { id }
      })
      .then(({ data }) => data.getSmartGuide);
  }

  fetchSmartGuidesForAsset(asset: Asset): Promise<SmartGuide[]> {
    // TODO: filter in resolver
    return smartGuideClient
      .query<queries.Query>({
        query: queries.getSmartGuides,
        fetchPolicy: "no-cache"
      })
      .then(({ data }) =>
        data.getSmartGuides.filter(
          (smartguide) =>
            smartguide.assetTypes.includes(asset.type.id) ||
            smartguide.relevantForAssets.includes(asset.assetId)
        )
      );
  }

  fetchSmartGuidePage(pageId: string): Promise<SmartGuidePage> {
    return smartGuideClient
      .query<queries.Query>({
        query: queries.getSmartGuidePage,
        variables: { id: pageId },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getSmartGuidePage);
  }

  updateSmartGuide(smartGuide: SmartGuide): Promise<SmartGuide> {
    return smartGuideClient
      .mutate<mutations.Mutation>({
        mutation: mutations.updateSmartGuide,
        variables: { smartGuide }
      })
      .then(({ data }) => this.resultOrThrow(data, "updateSmartGuide") as SmartGuide);
  }

  deleteSmartGuide(smartGuideId: string, tenant: string): Promise<void> {
    return smartGuideClient
      .mutate<mutations.Mutation>({
        mutation: mutations.deleteSmartGuide,
        variables: { id: smartGuideId, tenant }
      })
      .then(({ data }) => data?.deleteSmartGuide);
  }

  deleteSmartGuideMedia(smartGuideId: string, tenant: string): Promise<void> {
    return smartGuideClient
      .mutate<mutations.Mutation>({
        mutation: mutations.deleteSmartGuideMedia,
        variables: { id: smartGuideId, tenant }
      })
      .then(({ data }) => data?.deleteSmartGuideMedia);
  }

  async fetchSmartGuidePages(smartGuideId: string): Promise<SmartGuidePage[]> {
    const smartGuide = await this.fetchSmartGuide(smartGuideId);
    if (!smartGuide) {
      throw Error(`Smart guide with id ${smartGuideId} not found`);
    }

    try {
      const startPage = await this.fetchSmartGuidePage(smartGuide.startPageId);

      const allPages: SmartGuidePage[] = [];
      const resolveNextPages = async (page: SmartGuidePage) => {
        const nextPages = await Promise.all(
          page.nextSteps
            .filter((step) => step.pageId !== "support" && step.pageId !== "finished")
            .map((nextStep) => this.fetchSmartGuidePage(nextStep.pageId))
        );
        allPages.push(...nextPages);

        await Promise.all(nextPages.map((nextPage) => resolveNextPages(nextPage)));
      };

      await resolveNextPages(startPage);

      return [startPage, ...allPages];
    } catch (e) {
      throw Error(`The smartguide with the id ${smartGuideId} is broken!\n${e}`);
    }
  }

  updateSmartGuidePage(smartGuidePage: SmartGuidePage): Promise<SmartGuidePage> {
    return smartGuideClient
      .mutate<mutations.Mutation>({
        mutation: mutations.updateSmartGuidePage,
        variables: { smartGuidePage }
      })
      .then(({ data }) => this.resultOrThrow(data, "updateSmartGuide") as SmartGuidePage);
  }

  private resultOrThrow(
    data: mutations.Mutation | null | undefined,
    operation: keyof mutations.Mutation
  ) {
    const operationType = data?.[operation];
    if (!operationType) {
      throw new Error("Empty response");
    }
    return operationType;
  }

  async deleteSmartGuidePages(pageIds: string[], tenant: string): Promise<void> {
    // batchDeleteItems only supports deleting up to 25 items at once:
    const chunkedArray = (allIds: string[], arraySize = 25): string[][] => {
      const chunkedPageIds = [];
      for (let i = 0; i < allIds.length; i = i + arraySize) {
        chunkedPageIds.push(allIds.slice(i, i + arraySize));
      }
      return chunkedPageIds;
    };

    // TODO: extract `data` attributes (failed ids) from allResults and return them
    await Promise.all(
      chunkedArray(pageIds).map((ids) =>
        smartGuideClient.mutate<mutations.Mutation>({
          mutation: mutations.deleteSmartGuidePages,
          variables: { pageIds: ids, tenant }
        })
      )
    );
  }
}

export default new RemoteSmartGuideService();
