

























import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset as AssetModel } from "@/models/asset";
import { constructCustomerAssetUrl } from "@/services/static-assets.service";
@Component
export default class ProcessCellListItem extends Vue {
  @Prop()
  asset!: AssetModel;

  private s3ImageUrl = "";

  async mounted() {
    if (this.asset && this.asset.type) {
      const { image } = this.asset.type;
      this.s3ImageUrl = await constructCustomerAssetUrl(image);
    }
  }
}
