




import { Component, Vue, Prop } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
import globalConfig from "@/globals/Configuration";
@Component
export default class PieChart extends Vue {
  @Prop()
  series!: number[];

  @Prop()
  labels!: string[];

  get options(): ApexOptions {
    return {
      ...this.chartOptions,
      labels: this.labels
    };
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "pie"
    },
    responsive: [
      {
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    colors: globalConfig.chart.colors
  };
}
