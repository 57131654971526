































import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset } from "@/models/asset";
import StackedBarChart from "@/components/global/charts/StackedBarChart.vue";
import PieChart from "@/components/global/charts/PieChart.vue";
import * as R from "remeda";

@Component({
  components: { StackedBarChart, PieChart }
})
export default class TransparencyManager extends Vue {
  @Prop()
  assets!: Asset[];

  get typeData() {
    const typeGroup = R.groupBy(this.assets, (it) => it.type.name);
    return {
      series: Object.values(typeGroup).map((it) => it.length),
      labels: Object.keys(typeGroup)
    };
  }

  get ageData() {
    type Accumulator = {
      [key: string]: { [key: number]: number };
    };

    const years = new Set(this.assets.map((it) => it.constructionYear || 0));
    const sortedYears = Array.from(years).sort();

    const groupedTypes = this.assets.reduce((acc, it) => {
      const constructionYear = it.constructionYear || 0;
      if (!acc[it.type.name]) {
        acc[it.type.name] = {};
        // init with all available years
        sortedYears.forEach((year) => {
          acc[it.type.name][year] = 0;
        });
      }
      acc[it.type.name][constructionYear] += 1;
      return acc;
    }, {} as Accumulator);

    const series = Object.keys(groupedTypes).map((typeName) => {
      const countPerYears = groupedTypes[typeName];
      return { name: typeName, data: Object.values(countPerYears) };
    });

    return {
      labels: sortedYears,
      series
    };
  }
}
