/* eslint-disable @typescript-eslint/ban-types */
import globalConfig from "@/globals/Configuration";
const noop = () => undefined;
export interface Logger {
  debug: Function;
  info: Function;
  warn: Function;
  error: Function;
}

export interface LoggingConfiguration {
  isDebugMode: boolean;
}

export class ConsoleLoggingService implements Logger {
  private readonly debugMode: boolean;

  constructor(config: LoggingConfiguration) {
    this.debugMode = config.isDebugMode;
  }
  /* eslint-disable no-console */
  get debug() {
    if (this.debugMode) {
      return console.debug.bind(console);
    } else {
      return noop;
    }
  }

  get info() {
    if (this.debugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.debugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    return console.error.bind(console);
  }
  /* eslint-enable no-console */
}

const isDebugMode = globalConfig.isDev;

export default new ConsoleLoggingService({ isDebugMode });
