
























































































































































import PrivacyPolicyModal from "@/components/apps/liveguide/PrivacyPolicyModal.vue";
import logger from "@/plugins/logger";
import {
  requiredValidationRule,
  translatedEmailValidationRules
} from "@/services/input-validation.service";
import { notification } from "@/store/store.ui-service";
import { VueForm } from "@/types";
import liveguideService, { ContactRequest } from "@remote-api/liveguide.service";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component({
  components: { PrivacyPolicyModal }
})
export default class Contact extends Vue {
  private licenseTypes = [
    "liveguide.contact.start.form.licenseType.standard",
    "liveguide.contact.start.form.licenseType.premium"
  ];
  private salutation = [
    "liveguide.contact.start.form.salutation.option.mrs",
    "liveguide.contact.start.form.salutation.option.mr"
  ];
  private dataProtectionNote = false;
  private formModel: ContactRequest = {
    licenseType: "",
    licenseCount: 1,
    salutation: "",
    name: "",
    email: "",
    company: "",
    message: ""
  };

  valid = false;
  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  get emailRules() {
    return translatedEmailValidationRules();
  }

  validationRule(forElement: string) {
    return [requiredValidationRule(forElement)];
  }

  get translatedSalutations(): string[] {
    return this.salutation.map((salutationKey) => this.$t(salutationKey).toString());
  }

  get translatedLicenseTypes(): string[] {
    return this.licenseTypes.map((licenseKey) => this.$t(licenseKey).toString());
  }

  @Emit()
  success(): boolean {
    return true;
  }

  acceptDataProtection(acceptFlag: boolean) {
    this.dataProtectionNote = acceptFlag;
  }

  async submit() {
    if (this.form.validate() === false) {
      return;
    }
    try {
      await liveguideService.contact(this.formModel);
      this.success();
      notification.notifyUser({
        message: this.$t("liveguide.contact.start.form.success").toString(),
        type: "success"
      });
    } catch (error) {
      logger.error("Could not send liveguide contact request.", error);
      notification.notifyUser({
        message: this.$t("liveguide.contact.start.form.fail").toString(),
        type: "error"
      });
    }
  }
}
