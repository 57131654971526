import { render, staticRenderFns } from "./UserListItem.vue?vue&type=template&id=d4d687e2&scoped=true&"
import script from "./UserListItem.vue?vue&type=script&lang=ts&"
export * from "./UserListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4d687e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VLazy})
