

















import { Component, Vue } from "vue-property-decorator";
import Cluster from "@/components/Cluster.vue";
import ContactsCluster from "@/components/ContactsCluster.vue";
import Contact from "@/components/Contact.vue";
import Teaser from "@/components/Teaser.vue";
import { LandingPageContainerModel } from "@/models/cluster";
import { fetchClusters } from "@/services/landing-page.service";

@Component({
  components: { Cluster, Contact, Teaser, ContactsCluster }
})
export default class LandingPage extends Vue {
  get model(): LandingPageContainerModel {
    return fetchClusters(this.$i18n);
  }

  get padding() {
    let padding = "";
    switch (this.$vuetify?.breakpoint.name) {
      case "xs":
        padding = "padding:0 8px";
        break;
      case "sm":
        padding = "padding:0 16px";
        break;
      case "md":
        padding = "padding:0 32px";
        break;
      case "lg":
      case "xl":
        padding = "padding:0 80px";
        break;
      default:
        padding = "padding:0 80px;";
    }
    return padding;
  }
}
