






































































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class DataPrivacyNotice extends Vue {
  get padding() {
    let padding = "";
    switch (this.$vuetify?.breakpoint.name) {
      case "xs":
        padding = "padding:0 8px";
        break;
      case "sm":
        padding = "padding:0 16px";
        break;
      case "md":
        padding = "padding:0 32px";
        break;
      case "lg":
      case "xl":
        padding = "padding:0 80px";
        break;
      default:
        padding = "padding:0 80px;";
    }
    return padding;
  }
}
