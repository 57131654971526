













import { last } from "remeda";
import { Component, Prop, Vue } from "vue-property-decorator";
import BasicCard from "@/components/apps/assetdetails/widgets/BasicCard.vue";

@Component({
  components: { BasicCard }
})
export default class Recipe extends Vue {
  @Prop()
  recipes!: string[];
  @Prop()
  batchNames!: string[];

  src = require("@/assets/ui-assets/assets/pills.png");

  get currentRecipe(): string {
    return last(this.recipes) ?? "-";
  }

  get currentBatchName(): string {
    return (last(this.batchNames) ?? "-").substring(0, 30);
  }

  get style(): string {
    return `background: url(${this.src}); background-size: contain; transform: scale(1.3);`;
  }
}
