import { TabletThickness } from "@/models/TabletThickness";
import type { IVueI18n } from "vue-i18n";
import type { WidgetData } from "@/ui-models";

export class TabletThicknessDataMapper {
  private readonly tabletThicknesses: TabletThickness[];
  private readonly i18n: IVueI18n;
  constructor(tabletThicknesses: TabletThickness[], i18n: IVueI18n) {
    this.tabletThicknesses = Array.isArray(tabletThicknesses) ? tabletThicknesses : [];
    this.i18n = i18n;
  }

  get mappedTabletThickness(): WidgetData {
    return {
      labels: this.tabletThicknesses.map((it) => it.timestamp),
      series: [
        {
          name: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.actual.title")
          ),
          legendName: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.actual")
          ),
          unit: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.actual.unit")
          ),
          data: this.tabletThicknesses.map(
            (tabletThickness) => tabletThickness.meanValueActual || null
          )
        },
        {
          name: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.set.title")
          ),
          legendName: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.set")
          ),
          unit: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.set.unit")
          ),
          data: this.tabletThicknesses.map(
            (tabletThickness) => tabletThickness.meanValueSet || null
          )
        },
        //TODO @dt => translate + better naming
        {
          name: String(this.i18n.t("+T2")),
          legendName: String(this.i18n.t("+T2")),
          unit: String(this.i18n.t("mm")),
          data: this.tabletThicknesses.map((tabletThickness) => tabletThickness.t2Positive || null)
        },
        {
          name: String(this.i18n.t("+T1")),
          legendName: String(this.i18n.t("+T1")),
          unit: String(this.i18n.t("mm")),
          data: this.tabletThicknesses.map((tabletThickness) => tabletThickness.t1Positive || null)
        },
        {
          name: String(this.i18n.t("-T1")),
          legendName: String(this.i18n.t("-T1")),
          unit: String(this.i18n.t("mm")),
          data: this.tabletThicknesses.map((tabletThickness) => tabletThickness.t1Negative || null)
        },
        {
          name: String(this.i18n.t("-T2")),
          legendName: String(this.i18n.t("-T2")),
          unit: String(this.i18n.t("mm")),
          data: this.tabletThicknesses.map((tabletThickness) => tabletThickness.t2Negative || null)
        }
      ]
    };
  }

  get mappedTabletThicknessDeviation(): WidgetData {
    return {
      labels: this.tabletThicknesses.map((aTableThickness) => aTableThickness.timestamp),
      series: [
        {
          name: String(
            this.i18n.t(
              "product.pam.asset.conditions.widget.tablet-thickness.deviation.actual.title"
            )
          ),
          legendName: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.deviation.actual")
          ),
          data: this.tabletThicknesses.map(
            (aTableThickness) => aTableThickness.standardDeviationActual ?? null
          )
        },
        {
          name: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.deviation.set.title")
          ),
          legendName: String(
            this.i18n.t("product.pam.asset.conditions.widget.tablet-thickness.deviation.set")
          ),
          data: this.tabletThicknesses.map(
            (aTableThickness) => aTableThickness.standardDeviationSet ?? null
          )
        }
      ]
    };
  }
}
