



















import { Component, Vue, Prop } from "vue-property-decorator";
import { ProductTeaserModel } from "@/models/cluster";
import { ProductModel } from "@/models/cluster";
import ProductLink from "@/components/ProductLink.vue";
@Component({
  components: {
    ProductLink
  }
})
export default class ProductTeaser extends Vue {
  @Prop({ default: true })
  readonly showBackButton: boolean | undefined;

  @Prop()
  product!: ProductModel;

  @Prop()
  teaser!: ProductTeaserModel;
}
