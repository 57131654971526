





























































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { SmartGuidePage } from "@/models/smartguide";
import { ResolvedMedia, resolveMediaURLs } from "@/models/media";
import { smartGuideStorage } from "@/plugins/storage";

@Component
export default class SmartGuidePlayerPage extends Vue {
  @Prop()
  private page!: SmartGuidePage;

  @Prop()
  private isFirstPage!: boolean;

  private selection = "";
  private resolvedMedia: ResolvedMedia[] = [];

  async mounted() {
    this.resolvedMedia = await resolveMediaURLs(this.page.media, smartGuideStorage);
  }

  get showAttachments(): boolean {
    return this.page.id !== "support" && this.page.id !== "finished";
  }

  goJump() {
    if (this.selection) {
      const jump = this.selection;
      this.selection = "";
      this.jumpTo(jump);
    }
  }

  @Emit()
  jumpTo(pageId: string) {
    return pageId;
  }

  @Emit()
  goBack() {
    return true;
  }
}
