



















































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import UpdateAssetType from "@/components/apps/assetmanager/crud/UpdateAssetType.vue";
import { Asset, AssetType } from "@/models/asset";
import { constructCustomerAssetUrl } from "@/services/static-assets.service";

@Component({
  components: { OpenDialog, UpdateAssetType }
})
export default class AssetSummary extends Vue {
  @Prop()
  asset!: Asset;

  private assetTypeImgUrl = "";
  private isAssetTypeEditVisible = false;
  private assetTypeToEdit: AssetType = {} as AssetType;

  @Emit()
  openContactDrawer() {
    return true;
  }

  @Emit()
  openMapDrawer() {
    return true;
  }

  @Emit()
  openProcessCellDrawer() {
    return true;
  }

  @Emit()
  editAsset() {
    return true;
  }

  get hasImage() {
    return this.asset && this.asset.type && this.asset.type.image;
  }

  async mounted() {
    if (this.hasImage) {
      this.assetTypeImgUrl = await constructCustomerAssetUrl(this.hasImage);
    }
  }

  private updateAssetType() {
    this.assetTypeToEdit = this.asset.type;
    this.isAssetTypeEditVisible = true;
  }

  private assetTypeUpdated(assetType: AssetType) {
    this.asset.type = assetType;
    this.isAssetTypeEditVisible = false;
  }
}
