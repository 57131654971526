








import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class NotificationBadge extends Vue {
  @Prop({ default: "#D3FCFF" })
  readonly color!: string;

  @Prop({ default: "0" })
  readonly text!: string;
}
