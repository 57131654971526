
































import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset } from "@/models/asset";
import assetService from "@remote-api/asset.service";
@Component
export default class AssetDelete extends Vue {
  @Prop()
  asset!: Asset;

  private dialogVisible = false;

  async doDelete() {
    await assetService.removeAsset(this.asset.assetId);
    this.dialogVisible = false;
    this.$router.push({ name: "AssetManager" });
  }
}
