






























import BatchBasedWidget from "@/components/apps/assetdetails/widgets/BatchBasedWidget";
import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import { ChartColors } from "@/components/global/charts/ChartColors";
import RadialBar from "@/components/global/charts/RadialBar.vue";
import { TimelineSeriesGrouped } from "@/models/asset";
import logger from "@/plugins/logger";
import conditionService from "@remote-api/condition.service";
import { last } from "remeda";
import { Component } from "vue-property-decorator";

export function mapFromTimeSeries(timeSeriesData: TimelineSeriesGrouped): number[] {
  return timeSeriesData.oee?.map((currentOee) => {
    const [timestamp, qualityFactor, performanceFactor, efficiencyFactor] = currentOee;
    return Math.floor(
      (Number(qualityFactor) ??
        1 * Number(performanceFactor) ??
        1 * Number(efficiencyFactor) ??
        1) * 100
    );
  });
}

@Component({
  components: { RadialBar, WidgetCard }
})
export default class BatchOee extends BatchBasedWidget {
  batchOee: number[] = [];
  readonly gradient = [ChartColors.green];

  get currentOee(): number | undefined {
    return last(this.batchOee);
  }

  async doFetchRemoteData(): Promise<void> {
    try {
      const oeeDataSeries = await conditionService.fetchOee(this.assetId, this.currentBatchName);
      this.batchOee = mapFromTimeSeries(oeeDataSeries) ?? [];
    } catch (error) {
      logger.error("Could not fetch remote oee data", error);
    }
  }
}
