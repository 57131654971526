var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"document-link text--brand"},[_c('div',{staticClass:"d-inline"},[_c('v-icon',{attrs:{"color":"brand"}},[_vm._v("mdi-file-document-outline")])],1),_c('div',{staticClass:"d-inline pl-2"},[_vm._v(_vm._s(_vm.$t("product.pam.detail.documents")))])])]),_c('v-expansion-panel-content',[_c('div',[(_vm.asset.type)?_c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticClass:"fc-text-headline H200",attrs:{"cols":"12","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$t("product.pam.detail.documents.title", { count: _vm.numberOfDocuments, type: _vm.asset.name }))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('product.pam.detail.documents.type'),"clearable":"","item-text":"name","item-value":"id","items":_vm.documentTypeTuples},on:{"change":_vm.filterByDocumentType}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"clearable":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.filterByText}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","tile":"","color":"brand","dark":""},on:{"click":function($event){_vm.isAddDocVisible = true}}},[_c('v-icon',[_vm._v("mdi-upload")]),_vm._v(" "+_vm._s(_vm.$t("product.pam.detail.documents.upload"))+" ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredDocuments,"sort-by":"name","locale":_vm.locale},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.constructRemoteUrl(item.urlPath)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey lighten-1","dark":""}},[_vm._v(_vm._s(item.type))])]}},{key:"item.uploaded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestamp")(item.uploaded))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("humanizeBytes")(item.size))+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.doCheckDelete(item)}}},[_vm._v("mdi-delete")])]}}],null,false,3839457656)})],1)],1)],1):_vm._e()],1),_c('OpenDialog',{attrs:{"overlay":_vm.isAddDocVisible},on:{"close":function($event){_vm.isAddDocVisible = false}}},[(_vm.isAddDocVisible)?_c('AddDocument',{attrs:{"size-of-documents":_vm.sizeOfDocuments,"asset-id":_vm.asset.assetId},on:{"done":function($event){return _vm.documentsAdded($event)}}}):_vm._e()],1),_c('DeleteDocument',{attrs:{"dialog-visible":_vm.isDeleteDocVisible},on:{"close":function($event){_vm.isDeleteDocVisible = false},"done":_vm.deleteDocument}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }