import { SmartGuide, SmartGuidePage } from "@/models/smartguide";
import { SmartGuideFilterModel } from "@/ui-models";

export function filterSmartGuides(
  smartguides: SmartGuide[],
  filter: SmartGuideFilterModel = new SmartGuideFilterModel()
): SmartGuide[] {
  if (Array.isArray(smartguides) === false || smartguides.length < 1) {
    return [];
  }

  if (!filter.isActive()) {
    return smartguides;
  }

  return smartguides.filter((smartGuide) => {
    const foundByAssetTypeId =
      !filter.assetTypeId || smartGuide.assetTypes.includes(filter.assetTypeId);
    const foundByText =
      !filter.freeText || smartGuide.name.toLowerCase().includes(filter.freeText.toLowerCase());

    return foundByAssetTypeId && foundByText;
  });
}

export const staticSupportPage: SmartGuidePage = {
  tenant: "-",
  id: "support",
  pageNumber: "Support",
  text: `
    <h3 class="mb-4">Sorry! We can not solve your problem!</h3>
    <p>Please contact the support or use our <a href="https://app.fette-compacting.com/" target="_blank">LiveGuide</a> Tool.</p>
  `,
  nextSteps: [],
  media: [],
  progress: 100
};

export const staticFinishedPage: SmartGuidePage = {
  tenant: "-",
  id: "finished",
  pageNumber: "Finished",
  text: `
    <h3 class="mb-4">You have finished this guide!</h3>
    <p>Thank you for using the SmartGuide App.</p>
  `,
  nextSteps: [],
  media: [],
  progress: 100
};
