









import { Component, Prop, Vue } from "vue-property-decorator";
import BulletLineWidget from "@/components/apps/assetdetails/BulletLineWidget.vue";
import { RangeType } from "@/components/global/charts/BulletChart.vue";
import { StationData } from "@/models/asset";
import { last } from "remeda";
import { ChartColors } from "@/components/global/charts/ChartColors";

@Component({
  components: {
    BulletLineWidget
  }
})
export default class PreCompressionForce extends Vue {
  @Prop()
  widgetData!: StationData<number[]>;

  get preCompressionForce(): StationData<number> {
    return {
      stationOne: last(this.widgetData?.stationOne) ?? 0,
      stationTwo: last(this.widgetData?.stationTwo) ?? 0
    };
  }
  ranges: RangeType[] = [
    {
      minValue: 0,
      maxValue: 3.49,
      color: ChartColors.red
    },
    {
      minValue: 3.5,
      maxValue: 6,
      color: ChartColors.yellow
    },
    {
      minValue: 6.01,
      maxValue: 49.99,
      color: ChartColors.green
    },
    {
      minValue: 50,
      maxValue: 70,
      color: ChartColors.yellow
    },
    {
      minValue: 70.1,
      maxValue: 100,
      color: ChartColors.red
    }
  ];
}
