











import { Component, Vue } from "vue-property-decorator";
import UserManager from "@/components/apps/backoffice/usermanagement/UserManager.vue";

@Component({
  components: { UserManager }
})
export default class BackofficeOverview extends Vue {}
