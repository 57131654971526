












import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class FormCard extends Vue {
  @Prop()
  title!: string;
}
