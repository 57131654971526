











































































import { Component, Vue } from "vue-property-decorator";
import { NavigationItemModel } from "@/models/apps";
import { signOut } from "@/services/authentication.service";
import LanguageSelect from "@/components/global/LanguageSelect.vue";
import { getModule } from "vuex-module-decorators";
import * as authzService from "@/services/authorization.service";
import AuthModule from "@/store/modules/auth";
import logger from "@/plugins/logger";
const authStore = getModule(AuthModule);

@Component({ components: { LanguageSelect } })
export default class Navigation extends Vue {
  private authorizedUser: authzService.AuthorizedUser = authzService.unknown;

  get items(): NavigationItemModel[] {
    return [
      { title: this.$t("product.pam").toString(), icon: "mdi-cube-outline", link: "/assets/" },
      {
        title: this.$t("product.smartguide").toString(),
        icon: "mdi-routes",
        link: "/smartguide/"
      },
      {
        title: this.$t("product.smartcollege").toString(),
        icon: "mdi-school-outline",
        link: "/alva/"
      }
    ];
  }

  get isLoggedIn(): boolean {
    return authStore.isLoggedIn;
  }

  async mounted() {
    try {
      this.authorizedUser = await authzService.getAuthorizedUser();
    } catch (error) {
      logger.warn("Could not access autorized user");
    }
  }

  logout() {
    authStore.logout();
    signOut();
  }
}
