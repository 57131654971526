import globalConfig from "@/globals/Configuration";
import { defaultStorage } from "@/plugins/storage";
import type { StorageClass } from "@aws-amplify/storage";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function constructCustomerAssetUrl(
  urlPath: string,
  storage: Partial<StorageClass> = defaultStorage
): Promise<string> {
  if (!urlPath) {
    return "";
  }
  if (globalConfig.isDev) {
    return urlPath;
  } else {
    return storage.get!(urlPath) as Promise<string>;
  }
}

export const buildAssetPathPrefix = (id: string, tenant: string) => {
  const visibility = globalConfig.customerAssets.defaultVisibility;
  const documentsFolder = globalConfig.customerAssets.smartGuides.folderName;
  return `${visibility}/${tenant}/${documentsFolder}/${id}`;
};

export function retryUntilFileExists(path: string, storage: Partial<StorageClass>): Promise<any> {
  const attemptToDownload = async () => {
    const signedUrl = await (storage.get!(path) as Promise<string>);
    const response = await fetch(signedUrl);
    if (response.status === 404) {
      await sleep(2000);
      await attemptToDownload();
    }
  };

  return Promise.race([sleep(1000 * 20), attemptToDownload()]);
}
