import { ClusterModel, ProductModel } from "@/models/cluster";
import VueI18n from "vue-i18n";
import { LiveGuideModel } from "./LiveGuideModel";

export class GuideClusterModel implements ClusterModel {
  constructor(private $i18n: VueI18n) {}
  id = "guide";
  get title(): string {
    return this.$i18n.tc("productCluster.guide.title");
  }
  get description(): string {
    return this.$i18n.tc("productCluster.guide.description");
  }
  get products(): ProductModel[] {
    return [new LiveGuideModel(this.$i18n).product];
  }
}
