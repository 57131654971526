

















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class CreateEntityFormCard extends Vue {
  @Prop()
  title!: string;
  @Prop()
  formValid!: boolean;

  @Emit()
  save() {
    return true;
  }
  @Emit()
  cancel() {
    return false;
  }
}
