




import GenericProductDetails from "@/components/products/GenericProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { Component, Vue } from "vue-property-decorator";
import { AssetManagerModel } from "./AssetManagerModel";
@Component({
  components: {
    GenericProductDetails
  }
})
export default class AssetManager extends Vue {
  get container(): ProductDetailsContainerModel {
    return new AssetManagerModel(this.$i18n);
  }
}
