



























































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { SmartGuidePage } from "@/models/smartguide";
import S3ImageList from "./S3ImageList.vue";
import { Media } from "@/models/media";

@Component({
  components: { S3ImageList }
})
export default class PageDetailsEditor extends Vue {
  @Prop()
  page!: SmartGuidePage;

  @Prop()
  uploading!: boolean;

  private pageMedia: Media[] = [];

  private filesToUpload: File[] = [];

  private fileRules = [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) =>
      !value || this.calculateFilesSize(value) < 100 * 1000 * 1000 || "The maximum file size 100MB"
  ];

  mounted() {
    this.pageMedia = this.page.media;
  }

  calculateFilesSize(files: File[]) {
    return files.reduce((acc, item) => (acc = acc + item.size), 0);
  }

  get formValid() {
    return this.page.text.length > 0;
  }

  get isUploading() {
    return this.uploading;
  }

  @Emit()
  savePageChanges() {
    return this.page;
  }

  @Emit()
  uploadPageMedia() {
    if (this.filesToUpload?.length > 0) {
      const files = this.filesToUpload;
      this.filesToUpload = [];
      return files;
    }
  }

  deleteMediaItem(mediaItem: Media) {
    const indexToRemove = this.page.media.findIndex((media) => media.path === mediaItem.path);
    this.pageMedia.splice(indexToRemove, 1);
    this.page.media = this.pageMedia;
  }
}
