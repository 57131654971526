













import { Component, Vue, Prop } from "vue-property-decorator";
import LoadingStore from "@/store/modules/loading";
import { getModule } from "vuex-module-decorators";
const loadingStore = getModule(LoadingStore);

@Component({
  components: {}
})
export default class HeaderStatus extends Vue {
  @Prop()
  height = "56px";

  get loading() {
    return loadingStore.isLoading;
  }
}
