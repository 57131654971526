

















































import { User } from "@/ui-models";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import UserListItem from "@/components/apps/backoffice/usermanagement/UserListItem.vue";
import UserStatusIcon from "@/components/apps/backoffice/usermanagement/UserStatusIcon.vue";

@Component({
  components: { UserListItem, UserStatusIcon }
})
export default class UserList extends Vue {
  @Prop()
  users!: User[];
  search = "";

  @Emit()
  userUpdated(theUpdatedUser: User): User {
    return theUpdatedUser;
  }
  @Emit()
  userDeleted(theDeletedUser: User): User {
    return theDeletedUser;
  }

  expanded = [];

  get headers() {
    return [
      {
        text: this.$t("backoffice.user.user.email"),
        align: "start",
        value: "email"
      },
      {
        text: this.$t("backoffice.user.user.enabled"),
        value: "enabled"
      },
      {
        text: this.$t("backoffice.user.user.status"),
        value: "status"
      },
      { text: "", value: "data-table-expand" }
    ];
  }
}
