




























































































import { Component, Vue, Prop } from "vue-property-decorator";
import AssetStateBadge from "@/components/apps/assetmanager/AssetStateBadge.vue";
import { AssetSummaryState, Asset, AssetState, Progress } from "@/models/asset";
import AreaChart from "@/components/global/charts/AreaChart.vue";
import AreaWidget from "@/components/apps/assetdetails/AreaWidget.vue";
import RadialBar from "@/components/global/charts/RadialBar.vue";
import conditionService from "@remote-api/condition.service";
import { ProgressDataMapper } from "./widgets/mapping/ProgressDataMapper";

@Component({
  components: { AssetStateBadge, AreaChart, AreaWidget, RadialBar }
})
export default class AssetDataSummary extends Vue {
  @Prop()
  asset!: Asset;
  @Prop()
  assetStates!: AssetState[];

  get lastAssetState() {
    if (this.assetStates && this.assetStates.length > 0) {
      return this.assetStates[this.assetStates.length - 1];
    } else {
      return undefined;
    }
  }

  get progress(): Partial<Progress> {
    return new ProgressDataMapper(this.assetStates).mappedProgress;
  }

  private assetSummaryState: AssetSummaryState = {} as AssetSummaryState;

  async mounted() {
    if (this.asset && this.asset.isConnected) {
      this.assetSummaryState = await conditionService.fetchAssetSummaryState(this.asset.assetId);
    }
  }
}
