






























import { Component, Prop, Vue } from "vue-property-decorator";
import globalConfig from "@/globals/Configuration";
import LanguageSelect from "@/components/global/LanguageSelect.vue";

@Component({
  components: { LanguageSelect }
})
export default class NavigationMicrosite extends Vue {
  get headerHeight(): number {
    return globalConfig.offsets.header;
  }
}
