




import GenericProductDetails from "@/components/products/GenericProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { Component, Vue } from "vue-property-decorator";
import { SmartInterfaceModel } from "./SmartInterfaceModel";
@Component({
  components: {
    GenericProductDetails
  }
})
export default class SmartInterface extends Vue {
  get container(): ProductDetailsContainerModel {
    return new SmartInterfaceModel(this.$i18n);
  }
}
