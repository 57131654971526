import store from "@/store";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AssetState, AssetSummaryState } from "@/models/asset";

@Module({ dynamic: true, store, name: "condition" })
export default class ConditionModule extends VuexModule {
  private assetSummaryStateList: AssetSummaryState[] = [];
  private latestAssetStateList: AssetState[] = [];

  get assetSummaryStates(): AssetSummaryState[] {
    return this.assetSummaryStateList;
  }

  get latestAssetStates(): AssetState[] {
    return this.latestAssetStateList;
  }

  get assetSummaryState(): (assetId: string) => AssetSummaryState | undefined {
    return (assetId: string): AssetSummaryState | undefined => {
      return this.assetSummaryStateList.find((it) => it.assetId === assetId);
    };
  }

  @Mutation
  updateAssetSummaryStates(assetSummaryStates: AssetSummaryState[]): void {
    this.assetSummaryStateList = [...assetSummaryStates];
  }
  @Mutation
  updateLatestAssetStates(polledAssetStates: AssetState[]): void {
    this.latestAssetStateList = [...polledAssetStates];
  }
}
