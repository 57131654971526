import { Analytics } from "@aws-amplify/analytics";
import GoogleAnalyticsProvider from "./googleanalytics";
import awsconfig from "@/configs/aws-exports";
import globalConfig from "@/globals/Configuration";

Analytics.addPluggable(
  new GoogleAnalyticsProvider({
    ...awsconfig.Analytics,
    disabled: globalConfig.isDev
  })
);

Analytics.configure({
  AWSPinpointProvider: { disabled: true },
  GoogleAnalyticsProvider: {
    disabled: false,
    ...awsconfig.Analytics
  }
});
//@see https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js#page-view-tracking for configuration
Analytics.autoTrack("session", {
  enable: true,
  provider: GoogleAnalyticsProvider.providerName,
  attributes: {
    source: "osdi-spa"
  }
});
Analytics.autoTrack("pageView", {
  enable: true,
  eventName: "pageView",
  type: "SPA",
  provider: GoogleAnalyticsProvider.providerName,
  attributes: {
    source: "osdi-spa"
  }
});
