import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import type VueI18n from "vue-i18n";
import { getContact } from "@/services/contacts.service";
import { GuideClusterModel } from "./GuideClusterModel";
import { DescriptionSectionModel } from "@/ui-models";

export interface LicenseOverviewModel {
  title: string;
  type: {
    guest: string;
    guestRemark: string;
    guestFeatures: string;
    guestFeatureInitiateCase: string;
    standard: string;
    standardRemark: string;
    standardFeatures: string;
    premium: string;
    premiumRemark: string;
    premiumFeatures: string;
    premiumInviteGuestsRemark: string;
  };
  feature: {
    features: string;
    initiateCase: string;
    inviteColleagues: string;
    inviteGuests: string;
    solveWithExpert: string;
    caseOnlyLicense: string;
    additionalLicenses: string;
    digitalCaseArchives: string;
    translation: string;
    smartGlass: string;
  };
}

export class LiveGuideModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}

  get product(): ProductModel {
    return {
      id: "liveguide",
      title: this.$i18n.tc("liveguide.product.title"),
      description: this.$i18n.tc("liveguide.product.description"),
      image: require("@/assets/ui-assets/assets/design01/SmartRemote_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/SmartRemote_mehrfarbig_RGB.svg"),
      remoteUrl: "https://app.fette-compacting.com"
    };
  }

  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("liveguide.details.teaser.text")
      },
      features: [
        {
          title: this.$i18n.tc("liveguide.details.features.right.title"),
          text: this.$i18n.tc("liveguide.details.features.right.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_OEMcontent_3233352_000000.svg")
        },
        {
          title: this.$i18n.tc("liveguide.details.features.augmented.title"),
          text: this.$i18n.tc("liveguide.details.features.augmented.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_edit_3248033_000000.svg")
        },
        {
          title: this.$i18n.tc("liveguide.details.features.noconnection.title"),
          text: this.$i18n.tc("liveguide.details.features.noconnection.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_nocloud_3248487_000000.svg")
        }
      ],
      contactTeaser: this.$i18n.tc("liveguide.details.contactTeaser"),
      explanation: {
        title: this.$i18n.tc("liveguide.details.explanation.title"),
        text: this.$i18n.tc("liveguide.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/design01/SmartGuide_mehrfarbig_RGB.svg"),
        video: "https://www.youtube.com/embed/pkHtRjXtHa0"
      },
      featureTeaser: this.$i18n.tc("liveguide.details.featureTeaser")
    };
  }

  get advantages(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("liveguide.advantages.title").toString(),
      text: this.$i18n.t("liveguide.advantages.text").toString(),
      image: require("@/assets/ui-assets/assets/design01/SmartRemote_mehrfarbig_RGB_460x311.png"),
      containImage: true
    };
  }

  get specifications(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("liveguide.specifications.title").toString(),
      text: this.$i18n.t("liveguide.specifications.text").toString(),
      optionalFooter: [
        this.$i18n.t("liveguide.specifications.footer.0").toString(),
        this.$i18n.t("liveguide.specifications.footer.1").toString()
      ],
      image: require("@/assets/ui-assets/assets/design01/liveguide_f10i_460x311.png")
    };
  }

  get licenseOverview(): LicenseOverviewModel {
    return {
      title: "liveguide.licenseOverview.title",
      type: {
        guest: "liveguide.licenseOverview.type.guest.title",
        guestRemark: "liveguide.licenseOverview.type.guest.remark",
        guestFeatures: "liveguide.licenseOverview.type.guest.features",
        guestFeatureInitiateCase: "liveguide.licenseOverview.type.guest.feature.initiateCase",
        standard: "liveguide.licenseOverview.type.standard.title",
        standardRemark: "liveguide.licenseOverview.type.standard.remark",
        standardFeatures: "liveguide.licenseOverview.type.standard.features",
        premium: "liveguide.licenseOverview.type.premium.title",
        premiumRemark: "liveguide.licenseOverview.type.premium.remark",
        premiumInviteGuestsRemark:
          "liveguide.licenseOverview.type.premium.feature.inviteGuestsRemark",
        premiumFeatures: "liveguide.licenseOverview.type.premium.features"
      },
      feature: {
        features: "liveguide.licenseOverview.feature.features",
        initiateCase: "liveguide.licenseOverview.feature.initiateCase",
        inviteColleagues: "liveguide.licenseOverview.feature.inviteColleagues",
        inviteGuests: "liveguide.licenseOverview.feature.inviteGuests",
        solveWithExpert: "liveguide.licenseOverview.feature.solveWithExpert",
        caseOnlyLicense: "liveguide.licenseOverview.feature.caseOnlyLicense",
        additionalLicenses: "liveguide.licenseOverview.feature.additionalLicenses",
        digitalCaseArchives: "liveguide.licenseOverview.feature.digitalCaseArchives",
        translation: "liveguide.licenseOverview.feature.translation",
        smartGlass: "liveguide.licenseOverview.feature.smartGlass"
      }
    };
  }

  get interest(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("liveguide.interest.title").toString(),
      text: this.$i18n.t("liveguide.interest.text").toString()
    };
  }
  get cluster(): ClusterModel {
    return new GuideClusterModel(this.$i18n);
  }

  get contacts(): ContactModel[] {
    return [getContact("nmang")];
  }
}
