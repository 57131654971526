


























import type { User } from "@/ui-models";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import userManagementService from "@remote-api/user-management.service";
import { notification } from "@/store/store.ui-service";

@Component
export default class UserDelete extends Vue {
  @Prop()
  user!: User;

  @Emit()
  deleted() {
    return true;
  }

  dialog = false;

  async deleteUser() {
    try {
      await userManagementService.deleteUser(this.user.username);
      this.deleted();
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-delete.success")),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-delete.failure")),
        type: "error"
      });
    } finally {
      this.dialog = false;
    }
  }
}
