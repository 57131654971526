


























































import { Component, Prop, Vue } from "vue-property-decorator";
import { last } from "remeda";
import ConditionModule from "@/store/modules/condition";
import { getModule } from "vuex-module-decorators";
import conditionService from "@remote-api/condition.service";
import { defaultTreshold, StationData, TimeSeriesData, Treshold } from "@/models/asset";
import logger from "@/plugins/logger";
import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import { MachineStation } from "@/ui-models";
const conditionStore = getModule(ConditionModule);

const enum SRelColor {
  RED = "red",
  GREEN = "green",
  DEFAULT = "black",
  ORANGE = "orange"
}

const emptyStationData = {
  stationOne: [],
  stationTwo: []
};

@Component({
  components: { WidgetCard }
})
export default class MainCompressionForceSRelWidget extends Vue {
  readonly gradient = ["red", "orange", "green", "green", "orange", "red"];

  @Prop({ default: () => emptyStationData })
  widgetData!: StationData<number[]>;

  @Prop({
    default: (): StationData<Treshold> => {
      return {
        stationOne: defaultTreshold,
        stationTwo: defaultTreshold
      };
    }
  })
  treshold!: StationData<Treshold>;

  @Prop()
  batchNames!: string[];

  @Prop()
  assetId!: string;

  get sRel(): StationData<number> {
    return {
      stationOne: last(this.widgetData.stationOne) ?? 0,
      stationTwo: last(this.widgetData.stationTwo) ?? 0
    };
  }

  colorizeSRel(sRel: number, station: MachineStation = MachineStation.ONE): SRelColor {
    const treshold =
      station === MachineStation.ONE ? this.treshold.stationOne : this.treshold.stationTwo;
    if (sRel > 0 && sRel <= treshold.normal) return SRelColor.GREEN;
    if (sRel > treshold.normal && sRel <= treshold.warning) return SRelColor.ORANGE;
    if (sRel > treshold.warning && sRel <= treshold.danger) return SRelColor.RED;
    return SRelColor.DEFAULT;
  }

  get currentBatchName(): string {
    return (
      conditionStore.assetSummaryState(this.assetId)?.currentBatchName ??
      last(this.batchNames) ??
      ""
    );
  }

  sRelDataSeries: StationData<TimeSeriesData> = emptyStationData;

  async fetchRemoteData(): Promise<void> {
    if (!this.assetId || !this.currentBatchName) {
      return;
    }
    try {
      const sRelDataSeries = await conditionService.fetchSRel(this.assetId, this.currentBatchName);
      this.sRelDataSeries = {
        stationOne: sRelDataSeries.DP0009 ?? [],
        stationTwo: sRelDataSeries.DP0038 ?? []
      };
    } catch (error) {
      logger.error("Could not fetch remote sRel data", error);
    }
  }

  async mounted(): Promise<void> {
    await this.fetchRemoteData();
  }

  get hasStationTwo(): boolean {
    return this.sRel.stationTwo > 0 && Array.isArray(this.sRelDataSeries?.stationTwo);
  }

  get sRelForSparkline(): StationData<number[]> {
    return {
      stationOne: [
        ...this.sRelDataSeries?.stationOne.map(([, value]) => Number(value)),
        this.sRel.stationOne
      ],
      stationTwo: [
        ...this.sRelDataSeries?.stationTwo.map(([, value]) => Number(value)),
        this.sRel.stationTwo
      ]
    };
  }
}
