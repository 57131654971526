




















import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/apps/Header.vue";
import SmartCollegeCard from "@/components/apps/smartcollege/SmartCollegeCard.vue";
import { getModule } from "vuex-module-decorators";
import SmartCollegeStore from "@/components/apps/smartcollege/smartcollege-module";
import { Course } from "@/components/apps/smartcollege/smart-college";

const smartCollegeStore = getModule(SmartCollegeStore);

@Component({
  components: { Header, SmartCollegeCard }
})
export default class CourseList extends Vue {
  private breadcrumbItems = [{ text: "product.smartcollege", disabled: true }];

  async mounted() {
    this.courses = smartCollegeStore.courses;
  }

  private courses: Course[] | undefined = [];
}
