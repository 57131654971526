













import { Component, Vue, Prop } from "vue-property-decorator";
import Login from "@/components/global/authentication/Login.vue";
import RequireNewPassword from "@/components/global/authentication/RequireNewPassword.vue";
import ForgotPassword from "@/components/global/authentication/ForgotPassword.vue";
import { getModule } from "vuex-module-decorators";
import AuthModule, { AuthenticationState } from "@/store/modules/auth";
import AuthenticatorDisplayMap from "@/components/global/authentication/AuthenticatorDisplayMap";
import store from "@/store";
import router from "@/router";
const authStore = getModule(AuthModule);

@Component({
  components: { Login, RequireNewPassword, ForgotPassword }
})
export default class Authenticator extends Vue {
  @Prop()
  to!: string;

  private displayMap = AuthenticatorDisplayMap;
  private storeSubscription: any = undefined;

  mounted() {
    this.storeSubscription = store.subscribe((mutation) => {
      if (mutation.type === "updateAuthn" && mutation.payload === "signedIn") {
        if (this.storeSubscription) {
          // unregister from subscription
          this.storeSubscription();
        }
        router.push({ name: this.to || "/" });
      }
    });
  }

  destroyed() {
    if (this.storeSubscription) {
      // unregister from subscription
      this.storeSubscription();
    }
  }

  get authnState(): AuthenticationState {
    return authStore.authnState;
  }
}
