












































import { Component, Prop } from "vue-property-decorator";
import { requiredValidationRule } from "@/services/input-validation.service";
import { v4 as uuid } from "uuid";
import { AssetType } from "@/models/asset";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import globalConfig from "@/globals/Configuration";
import * as authnService from "@/services/authentication.service";
import assetService from "@remote-api/asset.service";
import logger from "@/plugins/logger";
import { defaultStorage } from "@/plugins/storage";

@Component
export default class UpdateAssetType extends CreateEntityInForm<AssetType> {
  @Prop()
  private assetType!: AssetType;

  private assetTypeName = "";
  private assetTypeManufacturer = "";
  private image: File | null = null;

  rules = [
    (value: any) => !value || value.size < 2000000 || "Asset image size should be less than 2 MB!"
  ];
  nameRules = [requiredValidationRule("Asset type name")];
  manufacturerRules = [requiredValidationRule("Asset type manufacturer")];

  mounted() {
    this.assetTypeName = this.assetType.name;
    this.assetTypeManufacturer = this.assetType.manufacturer;
  }

  async doSaveImage() {
    let key = this.assetType.image || "";
    if (this.image) {
      const { name, type: mimeType } = this.image!;
      if (!key) {
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)!;
        const visibility = globalConfig.customerAssets.defaultVisibility;
        const tenant = await authnService.getTenant();
        key = `${visibility}/${tenant}/${uuid()}${extension && "."}${extension}`;
      }

      const s3Upload = (await defaultStorage.put(key, this.image!, {
        contentType: mimeType
      })) as { key: string };
      return s3Upload.key;
    } else {
      return key;
    }
  }

  async doSave() {
    try {
      const s3UploadKey = await this.doSaveImage();
      const updatedAssetType = await assetService.updateAssetType(
        this.assetType.id,
        this.assetTypeName,
        this.assetTypeManufacturer,
        s3UploadKey
      );
      this.notifyUser({ message: "Asset type updated!", type: "success" });
      logger.info("Asset type updated!", updatedAssetType);
      this.done(updatedAssetType);
    } catch (error) {
      this.notifyUser({ message: "Asset type creation failed", type: "warning" });
      logger.error("Could not save asset type", error);
    }
  }
}
