


















































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/apps/Header.vue";
import FilterBox from "@/components/apps/smartguide/FilterBox.vue";
import AddSmartGuideHeaderButton from "@/components/apps/smartguide/AddSmartGuideHeaderButton.vue";
import { SmartGuide } from "@/models/smartguide";
import { AssetType } from "@/models/asset";
import { SmartGuideFilterModel } from "@/ui-models/index";
import smartGuideService from "@remote-api/smartguide.service";
import assetService from "@remote-api/asset.service";
import { filterSmartGuides } from "@/services/smartguide.ui-service";

type ConfirmationDialogState = {
  isVisible: boolean;
  idToDelete?: string;
  smartGuideName?: string;
};

@Component({
  components: { Header, FilterBox, AddSmartGuideHeaderButton }
})
export default class SmartGuideOverview extends Vue {
  private breadcrumbItems = [{ text: "product.smartguide", disabled: true }];
  private smartGuides: SmartGuide[] = [];
  private assetTypes: AssetType[] = [];
  private filteredSmartGuides: SmartGuide[] = [];
  private filterModel = new SmartGuideFilterModel();

  private confirmationDialogState: ConfirmationDialogState = {
    isVisible: false,
    idToDelete: undefined,
    smartGuideName: undefined
  };

  async mounted() {
    this.smartGuides = await smartGuideService.fetchSmartGuides();
    this.assetTypes = await assetService.fetchAssetTypes();

    const assetTypeId = (this.$route.query.assetTypeId as string) || undefined;
    const text = (this.$route.query.text as string) || undefined;
    this.filterModel = new SmartGuideFilterModel(assetTypeId, text);
    this.applyFilter(this.filterModel);
  }

  get isDialogVisible(): boolean {
    return this.confirmationDialogState.isVisible;
  }

  showDeletionDialog(smartguide: SmartGuide) {
    this.confirmationDialogState.smartGuideName = smartguide.name;
    this.confirmationDialogState.idToDelete = smartguide.id;
    this.confirmationDialogState.isVisible = true;
  }

  confirmDeletionDialog() {
    if (this.confirmationDialogState.idToDelete) {
      this.deleteSmartGuide(this.confirmationDialogState.idToDelete);
    }
    this.hideConfirmationDialog();
  }

  cancelDeletionDialog() {
    this.hideConfirmationDialog();
  }

  private hideConfirmationDialog() {
    this.confirmationDialogState.smartGuideName = undefined;
    this.confirmationDialogState.idToDelete = undefined;
    this.confirmationDialogState.isVisible = false;
  }

  get headers() {
    return [
      {
        text: this.$t("product.smartguide.all-guides.name"),
        align: "start",
        value: "name"
      },
      {
        text: this.$t("product.smartguide.all-guides.type"),
        value: "smartGuideType"
      },
      {
        text: this.$t("product.smartguide.all-guides.for-asset-type"),
        value: "assetTypes"
      },
      {
        text: this.$t("product.smartguide.all-guides.status"),
        value: "state"
      },
      {
        text: this.$t("product.smartguide.all-guides.actions"),
        value: "actions",
        sortable: false
      }
    ];
  }

  applyFilter(filter: SmartGuideFilterModel) {
    this.filteredSmartGuides = filterSmartGuides(this.smartGuides, filter);
    this.replaceQueryParameters(filter);
  }

  assetTypeNames(guide: SmartGuide) {
    return guide.assetTypes
      .map((assetTypeId) => {
        const assetType = this.assetTypes.find((type) => type.id === assetTypeId);
        return assetType?.name;
      })
      .filter((name) => name !== undefined);
  }

  hasMoreItems(assetTypeIds: string[]): boolean {
    return assetTypeIds.length > 2;
  }

  playSmartGuide(guide: SmartGuide) {
    this.$router.push({
      name: "SmartGuideDetails",
      params: { smartGuideId: guide.id }
    });
  }

  editSmartGuide(guide: SmartGuide) {
    // TODO: show Actions column according to user role.
    this.$router.push({
      name: "SmartGuideEditor",
      params: { smartGuideId: guide.id }
    });
  }

  deleteSmartGuide(smartGuideId: string) {
    this.$router.push({
      name: "DeleteSmartGuide",
      params: { smartGuideId: smartGuideId }
    });
  }

  addSmartGuide() {
    this.$router.push({
      name: "CreateNewSmartGuide"
    });
  }

  replaceQueryParameters(filters: SmartGuideFilterModel) {
    const params = new URLSearchParams(window.location.search);

    if (filters.assetTypeId) {
      params.set("assetTypeId", filters.assetTypeId);
    } else {
      params.delete("assetTypeId");
    }

    if (filters.freeText) {
      params.set("text", filters.freeText);
    } else {
      params.delete("text");
    }

    history.replaceState(null, "", `?${params.toString()}`);
  }
}
