import logger from "@/plugins/logger";
import { getCurrentAuthenticatedUser } from "@/services/authentication.service";
import store from "@/store";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type AuthenticationState =
  | "forgotPassword"
  | "signIn"
  | "requireNewPassword"
  | "signedOut"
  | "signedIn";

@Module({ dynamic: true, store, name: "auth" })
export default class AuthModule extends VuexModule {
  loggedInUser: CognitoUser | null = null;
  authenticationState: AuthenticationState = "signedOut";

  get isLoggedIn(): boolean {
    return this.loggedInUser !== null && this.authenticationState === "signedIn";
  }

  get user(): CognitoUser | null {
    return this.loggedInUser;
  }

  get authnState(): AuthenticationState {
    return this.authenticationState;
  }

  @Mutation
  login(cognitoUser: CognitoUser | null) {
    this.loggedInUser = cognitoUser;
  }

  @Mutation
  logout() {
    this.loggedInUser = null;
    this.authenticationState = "signedOut";
  }

  @Mutation
  updateAuthn(authnState: AuthenticationState) {
    this.authenticationState = authnState;
  }

  @Action
  async reloadUser() {
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      this.context.commit("login", currentUser);
      this.context.commit("updateAuthn", "signedIn" as AuthenticationState);
    } catch (error) {
      logger.info("Could not find a logged in user.");
      this.context.commit("logout");
    }
  }
}
