import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import { getContact } from "@/services/contacts.service";
import type VueI18n from "vue-i18n";
import { ControlClusterModel } from "./ControlClusterModel";

export class ConditionMonitorModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}
  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("conditionMonitor.details.teaser.text")
      },
      featureTeaser: this.$i18n.tc("conditionMonitor.details.featureTeaser"),
      features: [
        {
          title: this.$i18n.tc("conditionMonitor.details.features.realTimeVisibility.title"),
          text: this.$i18n.tc("conditionMonitor.details.features.realTimeVisibility.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_realtime_591026_000000.svg")
        },
        {
          title: this.$i18n.tc("conditionMonitor.details.features.ownYourDashboard.title"),
          text: this.$i18n.tc("conditionMonitor.details.features.ownYourDashboard.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_dashboard_3048219_000000.svg")
        },
        {
          title: this.$i18n.tc("conditionMonitor.details.features.unrestricted.title"),
          text: this.$i18n.tc("conditionMonitor.details.features.unrestricted.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_edit_3248033_000000.svg")
        }
      ],
      explanation: {
        title: this.$i18n.tc("conditionMonitor.details.explanation.title"),
        text: this.$i18n.tc("conditionMonitor.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/condition_monitor_screenshot.png")
      },
      contactTeaser: this.$i18n.tc("conditionMonitor.details.contactTeaser")
    };
  }

  get cluster(): ClusterModel {
    return new ControlClusterModel(this.$i18n);
  }
  get product(): ProductModel {
    return {
      id: "condmon",
      title: this.$i18n.tc("conditionMonitor.product.title"),
      description: this.$i18n.tc("conditionMonitor.product.description"),
      image: require("@/assets/ui-assets/assets/design01/ConditionMonitor_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/ConditionMonitor_mehrfarbig_RGB.svg"),
      link: "/assets/"
    };
  }

  get contacts(): ContactModel[] {
    return [getContact("svulp")];
  }
}
