import { ClusterModel, ProductModel } from "@/models/cluster";
import VueI18n from "vue-i18n";
import { AssetManagerModel } from "./AssetManagerModel";
import { ConditionMonitorModel } from "./ConditionMonitorModel";
import { PerformanceManagerModel } from "./PerformanceManagerModel";
import { SmartInterfaceModel } from "./SmartInterfaceModel";
import { PredictiveMaintenanceModel } from "./PredictiveMaintenanceModel";
import { ToolManagementModel } from "./ToolManagementModel";

export class ControlClusterModel implements ClusterModel {
  constructor(private $i18n: VueI18n) {}
  id = "control";
  get title(): string {
    return this.$i18n.tc("productCluster.control.title");
  }
  get description(): string {
    return this.$i18n.tc("productCluster.control.description");
  }
  get products(): ProductModel[] {
    return [
      new ConditionMonitorModel(this.$i18n).product,
      new AssetManagerModel(this.$i18n).product,
      new PerformanceManagerModel(this.$i18n).product,
      new SmartInterfaceModel(this.$i18n).product,
      new PredictiveMaintenanceModel(this.$i18n).product,
      new ToolManagementModel(this.$i18n).product
    ];
  }
}
