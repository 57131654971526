import * as mutations from "@/graphql/usermanagement/mutations";
import * as queries from "@/graphql/usermanagement/queries";
import { userManagementClient } from "@/plugins/graphql";
import { RemoteStatusService } from "@/services/remote-status.service";
import { User, UserRole, UserRoleString } from "@/ui-models";
import gql from "graphql-tag";

export interface UserManagementService {
  fetchUsersInTenantGroup(): Promise<User[]>;
  fetchGroupsForUser(username: string): Promise<string[]>;
  fetchAvailableRoles(): Promise<UserRole[]>;
  updateUserRoles(username: string, roles: UserRoleString[]): Promise<boolean>;
  resetUserPassword(username: string): Promise<boolean>;
  enableUser(username: string): Promise<boolean>;
  disableUser(username: string): Promise<boolean>;
  createUserInTenantGroup(emailAsUsername: string): Promise<User>;
  deleteUser(username: string): Promise<boolean>;
}

class RemoteUserManagementService implements UserManagementService {
  private readonly statusService = new RemoteStatusService();

  fetchAvailableRoles(): Promise<UserRole[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query({
        query: gql`
          query ListEnumValues {
            enum: __type(name: "UserRoles") {
              enumValues {
                name
              }
            }
          }
        `
      })
      .then(({ data }) => {
        const gqlEnums = ((data as any)?.enum?.enumValues as any) as {
          name: keyof typeof UserRole;
        }[];
        return gqlEnums.map((gqlEnum) => UserRole[gqlEnum.name]);
      })
      .finally(this.statusService.afterRemoteCall);
  }
  fetchGroupsForUser(username: string): Promise<string[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.listGroupsForUser,
        variables: { username },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.listGroupsForUser)
      .finally(this.statusService.afterRemoteCall);
  }

  private async *fetchPaginatedUsersInTenantGroup() {
    let nextToken = "";
    do {
      const paginatedUsers = await userManagementClient
        .query<queries.Query>({
          query: queries.listUsersInTenantGroup,
          variables: { nextToken },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          return {
            users: data?.listUsersInTenantGroup?.users,
            nextToken: data?.listUsersInTenantGroup?.nextToken
          };
        });
      yield paginatedUsers.users;
      nextToken = paginatedUsers.nextToken ?? "";
    } while (nextToken);
  }

  async fetchUsersInTenantGroup(): Promise<User[]> {
    this.statusService.beforeRemoteCall();
    let tenantUsers: User[] = [];
    for await (const users of this.fetchPaginatedUsersInTenantGroup()) {
      tenantUsers = [...tenantUsers, ...users];
    }
    this.statusService.afterRemoteCall();
    return tenantUsers;
  }

  async updateUserRoles(username: string, roles: UserRoleString[]): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.updateUserRoles,
        variables: { username, roles }
      })
      .then(({ data }) => data?.updateUserRoles ?? false)
      .finally(this.statusService.afterRemoteCall);
  }

  async resetUserPassword(username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.resetUserPassword,
        variables: { username }
      })
      .then(({ data }) => data?.resetUserPassword ?? false)
      .finally(this.statusService.afterRemoteCall);
  }

  createUserInTenantGroup(emailAsUsername: string): Promise<User> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.createUserInTenantGroup,
        variables: { emailAsUsername }
      })
      .then(({ data }) => this.resultOrThrow(data, "createUserInTenantGroup") as User)
      .finally(this.statusService.afterRemoteCall);
  }

  enableUser(username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.enableUser,
        variables: { username }
      })
      .then(({ data }) => data?.enableUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  disableUser(username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.disableUser,
        variables: { username }
      })
      .then(({ data }) => data?.disableUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  private resultOrThrow(
    data: mutations.Mutation | null | undefined,
    operation: keyof mutations.Mutation
  ) {
    const operationType = data?.[operation];
    if (!operationType) {
      throw new Error("Empty response");
    }
    return operationType;
  }

  deleteUser(username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.deleteUser,
        variables: { username }
      })
      .then(({ data }) => data?.deleteUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }
}

export default new RemoteUserManagementService();
