









import { Component, Vue, Emit } from "vue-property-decorator";
@Component
export default class HeaderAddButton extends Vue {
  @Emit()
  clicked() {
    return true;
  }
}
