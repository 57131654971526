































import { User } from "@/ui-models";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import UserRoles from "@/components/apps/backoffice/usermanagement/UserRoles.vue";
import UserEdit from "@/components/apps/backoffice/usermanagement/UserEdit.vue";

@Component({
  components: { UserRoles, UserEdit }
})
export default class UserListItem extends Vue {
  @Prop()
  user!: User;

  @Emit()
  userUpdated(theUpdatedUser: User) {
    return theUpdatedUser;
  }

  @Emit()
  userDeleted() {
    return this.user;
  }
}
