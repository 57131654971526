























import { Component, Vue, Prop } from "vue-property-decorator";
import Contact from "@/components/Contact.vue";
import { ContactClusterModel } from "@/models/cluster";
@Component({
  components: { Contact }
})
export default class ContactsCluster extends Vue {
  @Prop()
  contactCluster!: ContactClusterModel;
}
