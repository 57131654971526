
























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class DeleteDocument extends Vue {
  @Prop()
  private dialogVisible = false;

  @Emit()
  private close() {
    return true;
  }

  @Emit()
  private done() {
    return true;
  }
}
