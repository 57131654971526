import store from "@/store";
import { UserNotification } from "@/ui-models";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ dynamic: true, store, name: "snackbar" })
export default class SnackbarModule extends VuexModule {
  private userNotification: UserNotification | null = null;

  get notification() {
    return this.userNotification;
  }

  @Mutation
  notifyUser(notification: UserNotification) {
    this.userNotification = notification;
  }
}
