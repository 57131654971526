







import { Component, Vue, Prop } from "vue-property-decorator";
import { BadgeModel, assetStateLut } from "@/services/asset.ui-service";
import { AssetSummaryState } from "@/models/asset";
import ConditionModule from "@/store/modules/condition";
import { getModule } from "vuex-module-decorators";
import differenceInMinutes from "date-fns/differenceInMinutes";
import subDays from "date-fns/subDays";
import getTime from "date-fns/getTime";
const conditionStore = getModule(ConditionModule);

const TRESHOLD = 30; //minutes
@Component
export default class AssetStateBadge extends Vue {
  @Prop()
  private assetId!: string;

  @Prop()
  private isConnected!: boolean;

  get assetSummaryState(): AssetSummaryState {
    const noRemoteAssetState = {
      state: -1,
      timestamp: getTime(subDays(Date.now(), 1))
    } as AssetSummaryState;

    if (!this.assetId || !this.isConnected) {
      return noRemoteAssetState;
    }

    return conditionStore.assetSummaryState(this.assetId) || noRemoteAssetState;
  }

  isStatusOutdated() {
    return differenceInMinutes(Date.now(), this.assetSummaryState.timestamp) > TRESHOLD;
  }

  get state(): BadgeModel {
    if (this.isStatusOutdated()) {
      return assetStateLut["-1"];
    } else {
      return assetStateLut[this.assetSummaryState.state];
    }
  }
}
