




import { Component, Vue, Prop } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
import globalConfig from "@/globals/Configuration";
@Component
export default class BarChart extends Vue {
  @Prop()
  series!: { data: number[]; name: string }[];

  @Prop()
  labels!: string[];

  get options(): ApexOptions {
    return {
      ...this.chartOptions,
      xaxis: {
        categories: this.labels
      }
    };
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    responsive: [
      {
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    legend: {
      position: "right",
      offsetY: 40
    },
    fill: {
      opacity: 1
    },
    colors: globalConfig.chart.colors
  };
}
