import type { SmartGuide, SmartGuidePage } from "@/models/smartguide";
import gql from "graphql-tag";

export interface Mutation {
  updateSmartGuide: SmartGuide;
  updateSmartGuidePage: SmartGuidePage;
  deleteSmartGuide: void;
  deleteSmartGuideMedia: void;
}

export const updateSmartGuide = gql`
  mutation UpdateSmartGuide($smartGuide: UpdateSmartGuideInput!) {
    updateSmartGuide(smartGuide: $smartGuide) {
      tenant
      id
      name
      smartGuideType
      state
      assetTypes
      relevantForAssets
      startPageId
    }
  }
`;

export const deleteSmartGuide = gql`
  mutation DeleteSmartGuide($id: String!, $tenant: String!) {
    deleteSmartGuide(id: $id, tenant: $tenant)
  }
`;

export const deleteSmartGuideMedia = gql`
  mutation DeleteSmartGuideMedia($id: String!, $tenant: String!) {
    deleteSmartGuideMedia(id: $id, tenant: $tenant)
  }
`;

export const updateSmartGuidePage = gql`
  mutation UpdateSmartGuidePage($smartGuidePage: UpdateSmartGuidePageInput!) {
    updateSmartGuidePage(smartGuidePage: $smartGuidePage) {
      tenant
      id
      pageNumber
      warning
      text
      nextSteps {
        pageId
        text
      }
      media {
        path
        mediaType
        mimeType
      }
      progress
    }
  }
`;

// TODO: in resolver return IDs which could not be deleted
export const deleteSmartGuidePages = gql`
  mutation DeleteSmartGuidePages($pageIds: [String]!, $tenant: String!) {
    deleteSmartGuidePages(pageIds: $pageIds, tenant: $tenant)
  }
`;
