







































import { Component, Vue, Emit } from "vue-property-decorator";
import assetService from "@remote-api/asset.service";
import { AssetType } from "@/models/asset";
import { v4 as uuid } from "uuid";
import { requiredValidationRule } from "@/services/input-validation.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar";
import logger from "@/plugins/logger";
import { UserNotification } from "@/ui-models";
const notificationStore = getModule(SnackbarModule);
import globalConfig from "@/globals/Configuration";
import * as authnService from "@/services/authentication.service";
import { VueForm } from "@/types.d";
import { defaultStorage } from "@/plugins/storage";

@Component
export default class CreateAssetType extends Vue {
  @Emit()
  cancel() {
    return false;
  }

  @Emit()
  done(newAssetType: AssetType): AssetType {
    return newAssetType;
  }

  private formValid = true;

  private assetTypeName = "";
  private assetTypeManufacturer = "";
  private image: File | null = null;

  rules = [
    (value: any) => !value || value.size < 2000000 || "Asset image size should be less than 2 MB!"
  ];
  nameRules = [requiredValidationRule("Asset type name")];
  manufacturerRules = [requiredValidationRule("Asset type manufacturer")];

  private notifyUser(notification: UserNotification) {
    notificationStore.notifyUser(notification);
  }

  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  save() {
    if (this.form.validate()) {
      this.doSave();
    }
  }

  async doSaveImage(): Promise<string | undefined> {
    if (this.image) {
      const { name, type: mimeType } = this.image!;
      const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)!;
      const visibility = globalConfig.customerAssets.defaultVisibility;
      const tenant = await authnService.getTenant();
      const key = `${visibility}/${tenant}/${uuid()}${extension && "."}${extension}`;

      const s3Upload = (await defaultStorage.put(key, this.image!, {
        contentType: mimeType
      })) as { key: string };
      return s3Upload.key;
    } else {
      return;
    }
  }

  async doSave() {
    try {
      const s3UploadKey = await this.doSaveImage();
      const newAssetType = await assetService.createAssetType(
        this.assetTypeName,
        this.assetTypeManufacturer,
        s3UploadKey
      );
      this.notifyUser({ message: "Asset type created", type: "success" });
      logger.info("Created new asset type", newAssetType);
      this.done(newAssetType);
    } catch (error) {
      this.notifyUser({ message: "Asset type creation failed", type: "warning" });
      logger.error("Could not save asset type", error);
    }
  }
}
