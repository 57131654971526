import { AssetState, Progress } from "@/models/asset";
import { last } from "remeda";

export class ProgressDataMapper {
  constructor(readonly assetStates: AssetState[]) {}

  get mappedProgress(): Partial<Progress> {
    const latestState = last(this.assetStates);
    const set = latestState?.DP0012;
    const actual = latestState?.DP0013;
    let percent = 0;
    if (set && actual) {
      percent = (actual / set) * 100;
    }
    return {
      set,
      actual,
      percent,
      remainingTime: latestState?.DP0078 ?? "00:00"
    };
  }
}
