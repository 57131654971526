







































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { LicenseOverviewModel } from "@/components/products/LiveGuideModel";

@Component
export default class LicenseOverview extends Vue {
  @Prop()
  dataModel!: LicenseOverviewModel;
}
