


























import CreateEntityFormCard from "@/components/apps/assetmanager/crud/partials/CreateEntityFormCard.vue";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import logger from "@/plugins/logger";
import assetService from "@remote-api/asset.service";
import { requiredValidationRule } from "@/services/input-validation.service";
import { Component, Prop } from "vue-property-decorator";
import { ProcessCell } from "@/models/asset";

@Component({
  components: { CreateEntityFormCard }
})
export default class CreateProcessCell extends CreateEntityInForm<ProcessCell> {
  @Prop()
  siteId!: string;
  private processCellName = "";
  private processCellDescription = "";
  errorMessages: string | string[] = [];
  nameRules = [requiredValidationRule(this.$tc("product.pam.field.create-process-cell"))];
  private assignedProcessCells: Partial<ProcessCell>[] = [];

  async mounted(): Promise<void> {
    this.assignedProcessCells = (await assetService.fetchProcessCellNames()) ?? [];
  }

  onProcessCellNameChange(newProcessCellName: string): void {
    const nameCollision = this.assignedProcessCells.find(
      (processCell) => newProcessCellName.toLowerCase() === processCell.name?.toLowerCase()
    );
    if (nameCollision) {
      this.errorMessages = this.$tc("product.pam.field.create-process-cell.exists");
    } else {
      this.errorMessages = [];
    }
  }

  async doSave(): Promise<void> {
    try {
      const newProcessCell = await assetService.createProcessCell(
        this.siteId,
        this.processCellName,
        this.processCellDescription
      );
      if (!newProcessCell) {
        throw new Error("Could not save process cell");
      }
      this.notifyUser({ message: "Process cell created", type: "success" });
      logger.info("Created new process cell", newProcessCell);
      this.done(newProcessCell);
    } catch (error) {
      this.notifyUser({ message: "Process cell creation failed", type: "warning" });
      logger.error("Could not save process cell", error);
    }
  }
}
