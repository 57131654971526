
















































































import CreateEntityFormCard from "@/components/apps/assetmanager/crud/partials/CreateEntityFormCard.vue";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import logger from "@/plugins/logger";
import assetService from "@remote-api/asset.service";
import {
  requiredValidationRule,
  emailValidationRules,
  phoneValidationRules
} from "@/services/input-validation.service";
import { Component, Prop } from "vue-property-decorator";
import { Contact, ContactInput } from "@/models/asset";
import globalConfig from "@/globals/Configuration";
import * as authnService from "@/services/authentication.service";
import { v4 as uuid } from "uuid";
import { defaultStorage } from "@/plugins/storage";

@Component({
  components: { CreateEntityFormCard }
})
export default class CreateContactPerson extends CreateEntityInForm<Contact> {
  @Prop()
  siteId!: string;
  //private contactPersonName = "";
  //private contactPersonDescription = "";
  private image: File | null = null;

  private newContact = {} as ContactInput;
  private emailRules = emailValidationRules;
  private phoneRules = phoneValidationRules;
  private rules = [
    (value: any) => !value || value.size < 2000000 || "Asset image size should be less than 2 MB!"
  ];

  nameRules = [requiredValidationRule("Contact person name")];

  async doSaveImage(): Promise<string | undefined> {
    if (this.image) {
      const { name, type: mimeType } = this.image!;
      const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)!;
      const visibility = globalConfig.customerAssets.defaultVisibility;
      const tenant = await authnService.getTenant();
      const key = `${visibility}/${tenant}/${uuid()}${extension && "."}${extension}`;

      const s3Upload = (await defaultStorage.put(key, this.image!, {
        contentType: mimeType
      })) as { key: string };
      return s3Upload.key;
    } else {
      return;
    }
  }

  async doSave() {
    try {
      this.newContact.image = await this.doSaveImage();
      const newContactPerson = await assetService.createContactPerson(this.newContact);
      this.notifyUser({ message: "Contact person created", type: "success" });
      logger.info("Created new contact person", newContactPerson);
      this.done(newContactPerson);
    } catch (error) {
      this.notifyUser({ message: "Contact person creation failed", type: "warning" });
      logger.error("Could not save contact person", error);
    }
  }
}
