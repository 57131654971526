































































import { Component, Vue } from "vue-property-decorator";
import SmartGuideEditorPageNode from "@/components/apps/smartguide/SmartGuideEditorPageNode.vue";
import { getModule } from "vuex-module-decorators";
import SmartGuideEditorModule from "@/store/modules/smartguide-editor";
import EditorDrawer from "@/components/apps/smartguide/EditorDrawer.vue";
import { SmartGuidePage, SmartGuide } from "../../../models/smartguide";
import Header from "../Header.vue";
import Navigation from "@/components/apps/Navigation.vue";
import { UserNotification } from "@/ui-models";
import SnackbarModule from "@/store/modules/snackbar";
import LoadingStore from "@/store/modules/loading";
import * as authnService from "@/services/authentication.service";
import logger from "@/plugins/logger";

const smartGuideEditorStore = getModule(SmartGuideEditorModule);
const notificationStore = getModule(SnackbarModule);
const loadingStore = getModule(LoadingStore);

type EditorMode = "add" | "delete" | "edit";

@Component({ components: { EditorDrawer, SmartGuideEditorPageNode, Header, Navigation } })
export default class SmartGuideEditor extends Vue {
  private editGuide = false;
  private editorMode: EditorMode = "edit";
  private zoomFactor = 1;
  private uploading = false;

  maxZoom() {
    return 4;
  }

  minZoom(): number {
    return 1;
  }

  zoomOut() {
    this.zoomFactor = Math.min(this.zoomFactor + 1, this.maxZoom());
  }

  zoomIn() {
    this.zoomFactor = Math.max(this.zoomFactor - 1, this.minZoom());
  }

  zoomFactorClass(): string {
    return `zoom-${this.zoomFactor}`;
  }

  async mounted() {
    const smartGuideId = this.$route.params.smartGuideId;

    switch (this.$route.name) {
      case "CreateNewSmartGuide":
        this.editorMode = "add";
        try {
          loadingStore.startLoading();
          await smartGuideEditorStore.createNewSmartGuide(await authnService.getTenant());
        } finally {
          loadingStore.stopLoading();
        }
        break;

      case "SmartGuideEditor":
        this.editorMode = "edit";
        try {
          loadingStore.startLoading();
          await smartGuideEditorStore.loadSmartGuide(smartGuideId);
        } catch (error) {
          this.notifyUser({
            message: this.$i18n.t("product.smartguide.error.loading-failed").toString(),
            type: "error"
          });
          logger.error("Error loading smartGuide", error);
        } finally {
          loadingStore.stopLoading();
        }
        break;

      case "DeleteSmartGuide":
        this.editorMode = "delete";
        try {
          loadingStore.startLoading();
          await smartGuideEditorStore.deleteGuideAndPages(smartGuideId);
        } catch (error) {
          this.notifyUser({
            message: this.$i18n.t("product.smartguide.error.deleting-failed").toString(),
            type: "error"
          });
          logger.error(`Error deleting smartGuide ${smartGuideId}`, error);
        } finally {
          loadingStore.stopLoading();
          this.$router.replace({
            name: "SmartGuideOverview"
          });
        }
        break;

      default:
        throw Error(`Unhandled route in SmartGuideEditor: ${this.$route.name}`);
    }
  }

  destroyed() {
    smartGuideEditorStore.clear();
  }

  get editedPage(): SmartGuidePage | null {
    return smartGuideEditorStore.currentPage;
  }

  get isLoading() {
    return loadingStore.isLoading;
  }

  get hasUnsavedChanges() {
    return smartGuideEditorStore.hasUnsavedChanges;
  }

  get breadcrumbs() {
    const baseNavigationItem = {
      text: "product.smartguide",
      to: "/smartguide",
      exact: true,
      disabled: false
    };
    if (this.smartGuide) {
      return [baseNavigationItem, { text: this.smartGuide.name, disabled: false }];
    }
    return [baseNavigationItem];
  }

  get smartGuide(): SmartGuide | null {
    return smartGuideEditorStore.currentSmartGuide;
  }

  get rootPage() {
    return smartGuideEditorStore.rootPage;
  }

  hideDrawer() {
    smartGuideEditorStore.selectPage(undefined);
    this.editGuide = false;
  }

  notifyUser(notification: UserNotification) {
    notificationStore.notifyUser(notification);
  }

  showPageInDrawer(id: string) {
    const page = smartGuideEditorStore.pageById(id);
    smartGuideEditorStore.selectPage(page);
    this.editGuide = false;
  }

  showGuideInDrawer() {
    smartGuideEditorStore.selectPage(undefined);
    this.editGuide = true;
  }

  saveUpdatedPage(page: SmartGuidePage) {
    smartGuideEditorStore.updatePage(page);
  }

  saveUpdatedGuide(guide: SmartGuide) {
    smartGuideEditorStore.updateGuide(guide);
  }

  async uploadPageMedia(files: File[]) {
    if (this.editedPage) {
      this.uploading = true;
      await smartGuideEditorStore.uploadFilesForPage({
        files,
        smartGuide: this.smartGuide!,
        page: this.editedPage
      });
      this.uploading = false;
    }
  }

  async saveGuideAndPages() {
    try {
      loadingStore.startLoading();
      if (this.editorMode === "add") {
        await smartGuideEditorStore.saveGuideAndPages();
        this.$router.replace({
          name: "SmartGuideEditor",
          params: { smartGuideId: this.smartGuide!.id }
        });
      } else {
        await smartGuideEditorStore.saveGuideAndPages();
        await smartGuideEditorStore.loadSmartGuide(this.smartGuide!.id);
      }
    } finally {
      loadingStore.stopLoading();
    }
  }
}
