export interface ColorInterval {
  color: string;
  start: number;
  end: number;
}

export function colorByRange(value: number, ranges: ColorInterval[]): "black" | string {
  const defaultColor = ranges[0]?.color ?? "black";
  return ranges?.find((range) => range.start <= value && value <= range.end)?.color ?? defaultColor;
}
