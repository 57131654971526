import { AnalyticsProvider } from "@aws-amplify/analytics";
import axios from "axios";
import logger from "./logger";

export interface GoogleAnalyticsProviderConfig {
  clientId?: string;
  endpoint: string;
  apiSecret: string;
  measurementId: string;
  disabled?: boolean;
}
/**
 * Uses the ALPHA Measurement Protocol(Google Analytics 4)
 * @see https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=gtag
 */
export default class GoogleAnalyticsProvider implements AnalyticsProvider {
  static category = "Analytics";
  static providerName = "GoogleAnalytics";
  private readonly config: GoogleAnalyticsProviderConfig;

  constructor(config: GoogleAnalyticsProviderConfig) {
    this.config = {
      ...config,
      clientId: String(Date.now())
    };
  }

  configure(notUsedNativeConfig: GoogleAnalyticsProviderConfig): any {
    return notUsedNativeConfig;
  }

  private get endpointUrl(): string {
    return `${this.config.endpoint}?measurement_id=${this.config.measurementId}&api_secret=${this.config.apiSecret}`;
  }

  async record(params: { event: { name: string; attributes: any[] } }): Promise<boolean> {
    if (this.config.disabled) {
      return true;
    }

    const payload = {
      client_id: this.config.clientId,
      events: [
        {
          name: params.event.name,
          params: params.event.attributes
        }
      ]
    };
    logger.debug("Analytics record:", payload);
    return axios.post(this.endpointUrl, payload);
  }

  getCategory(): string {
    return GoogleAnalyticsProvider.category;
  }

  getProviderName(): string {
    return GoogleAnalyticsProvider.providerName;
  }
}
