























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Media, readableFileName } from "@/models/media";
import RemoteImage from "@/components/apps/smartguide/RemoteImage.vue";

@Component({
  components: { RemoteImage }
})
export default class S3ImageList extends Vue {
  @Prop()
  media!: Media[];

  get originalMedia() {
    return this.media;
  }

  fileName(media: Media): string {
    return readableFileName(media);
  }

  @Emit()
  deleteMediaItem(mediaItem: Media) {
    return mediaItem;
  }
}
