


























import globalConfig from "@/globals/Configuration";
import { Component, Vue, Prop } from "vue-property-decorator";
import BreadCrumb from "./Breadcrumb.vue";
import HeaderStatus from "./HeaderStatus.vue";
import NotificationBadge from "./NotificationBadge.vue";
import { BreadcrumbItem } from "@/components/apps/NavigationBreadcrumb.vue";
import LoadingStore from "@/store/modules/loading";
import { getModule } from "vuex-module-decorators";
const loadingStore = getModule(LoadingStore);

@Component({
  components: { BreadCrumb, NotificationBadge, HeaderStatus }
})
export default class Header extends Vue {
  @Prop()
  breadcrumbItems!: BreadcrumbItem[];

  get headerHeight(): string {
    return `${globalConfig.offsets.appHeader}px`;
  }

  get loading() {
    return loadingStore.isLoading;
  }
}
