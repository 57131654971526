






















































































































































import { Component, Vue, Emit } from "vue-property-decorator";
import CreateAssetType from "@/components/apps/assetmanager/crud/CreateAssetType.vue";
import CreateProcessCell from "@/components/apps/assetmanager/crud/CreateProcessCell.vue";
import { AssetType, Site, Asset, ProcessCell } from "@/models/asset";
import assetService from "@remote-api/asset.service";
import {
  requiredValidationRule,
  constructionYearValidation
} from "@/services/input-validation.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar";
import logger from "@/plugins/logger";
import { UserNotification } from "@/ui-models";
import { sortBy } from "remeda";
import CreateSite from "@/components/apps/assetmanager/crud/CreateSite.vue";
const notificationStore = getModule(SnackbarModule);

@Component({
  components: {
    CreateAssetType,
    CreateProcessCell,
    CreateSite
  }
})
export default class AssetAdd extends Vue {
  formValid = true;
  showCreateAssetType = false;
  showCreateProcessCell = false;
  showCreateSite = false;

  assetTypes: AssetType[] = [];
  sites: Site[] = [];
  processCells: ProcessCell[] = [];
  newAsset: Asset = {} as Asset;

  nameRules = [requiredValidationRule("Asset name")];
  siteRules = [requiredValidationRule("Site")];
  constructionYearRules = [(year: any) => constructionYearValidation(year)];

  @Emit()
  done(newAsset: Asset): Asset {
    return newAsset;
  }

  async mounted() {
    await this.fetchRemoteData();
  }

  private async fetchRemoteData() {
    this.assetTypes = sortBy(await assetService.fetchAssetTypes(), (type) =>
      type.name.toLowerCase()
    );
    this.sites = sortBy(await assetService.fetchSites(), (site) => site.name.toLowerCase());
    this.processCells = sortBy(
      await assetService.fetchProcessCells(),
      (cell) => cell.name.toLowerCase
    );
  }

  private notifyUser(notification: UserNotification) {
    notificationStore.notifyUser(notification);
  }

  createAssetType() {
    this.showCreateAssetType = !this.showCreateAssetType;
  }

  createSite() {
    this.showCreateSite = !this.showCreateSite;
  }

  assetTypeCreated(newAssetType: AssetType) {
    this.showCreateAssetType = false;
    this.assetTypes.push(newAssetType);
    this.newAsset.type = newAssetType;
  }

  processCellCreated(newProcessCell: ProcessCell) {
    this.showCreateProcessCell = false;
    this.processCells.push(newProcessCell);
    this.newAsset.processCell = newProcessCell;
  }

  siteCreated(theNewSite: Site) {
    this.showCreateSite = false;
    this.sites.push(theNewSite);
    this.newAsset.site = theNewSite;
  }

  createAsset() {
    return this.doCreateAsset();
  }

  async doCreateAsset() {
    try {
      logger.debug("Trying to create new asset", this.newAsset);
      const asset = await assetService.createAsset(this.newAsset);
      this.notifyUser({ message: "Asset created", type: "success" });
      logger.info("Created new asset", asset);
      this.done({ ...this.newAsset, assetId: asset.assetId });
    } catch (error) {
      this.notifyUser({ message: "Asset creation failed", type: "warning" });
      logger.error("Could not create asset", error);
    }
  }
}
