
























import { Component, Vue, Prop } from "vue-property-decorator";
import { ClusterModel, ProductModel } from "@/models/cluster";
import Product from "@/components/Product.vue";
@Component({
  components: { Product }
})
export default class ProductOtherCluster extends Vue {
  @Prop()
  cluster!: ClusterModel;

  @Prop()
  product!: ProductModel;

  get otherProducts(): ProductModel[] {
    return this.cluster.products.filter((item) => item.id !== this.product.id);
  }
}
