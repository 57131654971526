



import GenericProductDetails from "@/components/products/GenericProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { Component, Vue } from "vue-property-decorator";
import { PerformanceManagerModel } from "./PerformanceManagerModel";
@Component({
  components: {
    GenericProductDetails
  }
})
export default class PerformanceManager extends Vue {
  get container(): ProductDetailsContainerModel {
    return new PerformanceManagerModel(this.$i18n);
  }
}
