var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"breadcrumb-items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('AddSmartGuideHeaderButton',{on:{"clicked":_vm.addSmartGuide}})]},proxy:true}])}),_c('v-main',{staticClass:"px-1"},[_c('v-container',[_c('FilterBox',{attrs:{"smartGuides":_vm.filteredSmartGuides,"assetTypes":this.assetTypes,"filterModel":this.filterModel},on:{"filter":_vm.applyFilter}}),_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("product.smartguide.overview.all-guides")))]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSmartGuides,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function () { return _vm.playSmartGuide(item); }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.smartGuideType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey lighten-1","dark":""}},[_vm._v(_vm._s(item.smartGuideType))])]}},{key:"item.assetTypes",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.assetTypeNames(item)),function(assetType){return _c('v-chip',{key:assetType,staticClass:"mr-1 mt-1 mb-1",attrs:{"color":"grey lighten-1","dark":""}},[_vm._v(" "+_vm._s(assetType)+" ")])}),(_vm.hasMoreItems(item.assetTypes))?_c('span',[_vm._v("...")]):_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state == 0)?_c('v-icon',[_vm._v("mdi-close-circle")]):_vm._e(),(item.state == 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editSmartGuide(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeletionDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete Smart Guide")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("product.smartguide.confirmation.delete-smartguide.text", { name: _vm.confirmationDialogState.smartGuideName }))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t("product.smartguide.confirmation.delete-smartguide.warning"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.cancelDeletionDialog}},[_vm._v(_vm._s(_vm.$t("global.actions.cancel")))]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.confirmDeletionDialog}},[_vm._v(_vm._s(_vm.$t("global.actions.delete")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }