import { MediaType } from "@/models/media";
import { createPage, SmartGuide, SmartGuidePage, SmartGuidePageType } from "@/models/smartguide";
import logger from "@/plugins/logger";
import { smartGuideStorage } from "@/plugins/storage";
import { buildAssetPathPrefix } from "@/services/static-assets.service";
import store from "@/store";
import smartGuideService from "@remote-api/smartguide.service";
import * as R from "remeda";
import { v4 as uuid } from "uuid";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

const findPage = (allPages: SmartGuidePage[], id: string): SmartGuidePage => {
  const page = allPages.find((page) => page.id === id);
  if (!page) {
    throw new Error(`Page with id ${id} not found`);
  }
  return page;
};

const findParentPage = (allPages: SmartGuidePage[], id: string): SmartGuidePage => {
  const parentPage = allPages.find((page) => page.nextSteps.find((step) => step.pageId === id));
  if (!parentPage) {
    throw new Error(`Parent page for page with id ${id} not found`);
  }
  return parentPage;
};

export const findAllChildren = (allPages: SmartGuidePage[], id: string): SmartGuidePage[] => {
  const findAllChildPages = (
    allPages: SmartGuidePage[],
    foundChildren: SmartGuidePage[],
    page: SmartGuidePage
  ): SmartGuidePage[] => {
    const directChildren = page.nextSteps.map((step) => findPage(allPages, step.pageId));
    return [
      ...directChildren,
      ...directChildren.flatMap((page) => findAllChildPages(allPages, foundChildren, page))
    ];
  };
  const page = findPage(allPages, id);
  return findAllChildPages(allPages, [], page);
};

const amountOfChildPagesOfNewSmartGuide = 3;

@Module({ dynamic: true, store, name: "smartguide-editor" })
export default class SmartGuideEditorModule extends VuexModule {
  private smartGuide?: SmartGuide = undefined;
  private selectedPage?: SmartGuidePage = undefined;
  private allPages: SmartGuidePage[] = [];
  private initiallyLoadedPageIds: string[] = [];
  private pageIdsToDelete: string[] = [];
  private unsavedChanges = false;

  get hasUnsavedChanges() {
    return this.unsavedChanges;
  }

  get currentSmartGuide(): SmartGuide | null {
    if (!this.smartGuide) {
      return null;
    }
    return { ...this.smartGuide };
  }

  get rootPage(): SmartGuidePage | null {
    if (!this.smartGuide) {
      return null;
    }
    return this.pageById(this.smartGuide.startPageId);
  }

  get pageById() {
    return (id: string): SmartGuidePage => {
      return { ...findPage(this.allPages, id) };
    };
  }

  get decisionTextForPage() {
    return (id: string, parentPageId: string): string => {
      const parentPage = findPage(this.allPages, parentPageId);
      const step = parentPage.nextSteps.find((step) => step.pageId === id);
      return step?.text || "";
    };
  }

  get currentPage() {
    if (!this.selectedPage) {
      return null;
    }
    return { ...this.selectedPage, media: [...this.selectedPage.media] };
  }

  @Mutation
  selectPage(page: SmartGuidePage | undefined) {
    this.selectedPage = page;
  }

  @Mutation
  insertPageAfter(parentPage: SmartGuidePage): SmartGuidePage {
    const newPage = createPage(parentPage.tenant, uuid());
    this.allPages.push(newPage);
    parentPage.nextSteps.push({ pageId: newPage.id, text: "Decision title" });
    this.unsavedChanges = true;
    return newPage;
  }

  @Mutation
  removePage(payload: { pageId: string; parentPageId: string }) {
    const { pageId, parentPageId } = payload;

    if (pageId === "finished" || pageId === "support") {
      // remove only from nextSteps of parent:
      this.allPages = this.allPages.map((page) => {
        if (page.id === parentPageId) {
          return {
            ...page,
            nextSteps: page.nextSteps.filter((step) => step.pageId !== pageId)
          };
        } else {
          return page;
        }
      });
    } else {
      const parentPage = findPage(this.allPages, parentPageId);
      const pageToDelete = findPage(this.allPages, pageId);
      const childPagesToDelete = findAllChildren(this.allPages, pageToDelete.id);

      // Record all pages which need to be deleted later:
      this.pageIdsToDelete.push(pageId);
      this.pageIdsToDelete.push(...childPagesToDelete.map((page) => page.id));

      // Remove from parent's next steps:
      parentPage.nextSteps = parentPage.nextSteps.filter((step) => step.pageId !== pageId);
    }

    this.unsavedChanges = true;
  }

  @Mutation
  changePageType(payload: { pageId: string; newType: SmartGuidePageType }) {
    const { pageId, newType } = payload;

    if (newType === "finished" || newType === "support") {
      // delete old page, change nextSteps link to newType:
      const parentPage = findParentPage(this.allPages, pageId);
      parentPage.nextSteps = parentPage.nextSteps.map((step) => {
        if (step.pageId === pageId) {
          return { ...step, pageId: newType };
        } else {
          return step;
        }
      });

      this.allPages = this.allPages.filter((page) => page.id !== pageId);
      this.pageIdsToDelete.push(pageId);
    } else if (newType === "regular") {
      throw Error("Can not change type to regular. Delete it instead.");
    } else {
      throw Error("Unknown page type: " + newType);
    }

    this.unsavedChanges = true;
  }

  @Mutation
  updatePage(pageToUpdate: SmartGuidePage) {
    const page = findPage(this.allPages, pageToUpdate.id);
    page.pageNumber = pageToUpdate.pageNumber;
    page.text = pageToUpdate.text;
    page.warning = pageToUpdate.warning;
    page.progress = pageToUpdate.progress;
    page.informationText = pageToUpdate.informationText;
    page.recommendationText = pageToUpdate.recommendationText;
    page.media = pageToUpdate.media;
    this.unsavedChanges = true;
  }

  @Mutation
  updateDecisionText(payload: { parentPageId: string; pageId: string; text: string }) {
    const { parentPageId, pageId, text } = payload;
    const parentPage = findPage(this.allPages, parentPageId);
    parentPage.nextSteps = parentPage.nextSteps.map((step) => {
      if (step.pageId === pageId) {
        return { ...step, text };
      } else {
        return step;
      }
    });
    this.unsavedChanges = true;
  }

  @Mutation
  updateGuide(guideToUpdate: SmartGuide) {
    if (!this.smartGuide) {
      throw Error("Smart guide is not loaded yet");
    }
    this.smartGuide.name = guideToUpdate.name;
    this.smartGuide.smartGuideType = guideToUpdate.smartGuideType;
    this.smartGuide.state = guideToUpdate.state;
    this.smartGuide.assetTypes = guideToUpdate.assetTypes;
    this.smartGuide.relevantForAssets = guideToUpdate.relevantForAssets;
    this.unsavedChanges = true;
  }

  @Mutation
  storeSmartGuideInEditorState(data: { smartGuide: SmartGuide; pages: SmartGuidePage[] }) {
    const { smartGuide, pages } = data;
    this.initiallyLoadedPageIds = pages.map((page) => page.id);
    this.smartGuide = smartGuide;
    this.allPages = pages;
  }

  @Mutation
  clear() {
    this.smartGuide = undefined;
    this.selectedPage = undefined;
    this.allPages = [];
    this.pageIdsToDelete = [];
    this.initiallyLoadedPageIds = [];
    this.unsavedChanges = false;
  }

  @Mutation
  resetAfterSave() {
    this.pageIdsToDelete = [];
    this.unsavedChanges = false;
  }

  @Mutation
  setDirty() {
    this.unsavedChanges = true;
  }

  @Mutation
  addMediaToPage(payload: {
    uploadedFiles: { fileName: string; mimeType: string }[];
    smartGuide: SmartGuide;
    page: SmartGuidePage;
  }) {
    const { uploadedFiles, smartGuide, page } = payload;
    const newMedia = uploadedFiles.map(({ fileName, mimeType }) => {
      const path = `${buildAssetPathPrefix(smartGuide.id, smartGuide.tenant)}/${fileName}`;
      const mediaType: MediaType = mimeType.includes("image") ? "image" : "video";
      return {
        path,
        mediaType: mediaType,
        mimeType
      };
    });

    newMedia.forEach((media) => {
      page.media.push(media);
    });
  }

  @Action({ rawError: true })
  async createNewSmartGuide(tenant: string) {
    const startPage = createPage(tenant, uuid(), "Step 1", "1");
    const newSmartGuide: SmartGuide = {
      tenant,
      id: uuid(),
      name: "New SmartGuide",
      smartGuideType: "Guide",
      state: 0,
      assetTypes: [],
      relevantForAssets: [],
      startPageId: startPage.id
    };

    const subPages: SmartGuidePage[] = [];

    for (let i = 0; i < amountOfChildPagesOfNewSmartGuide; i++) {
      const newPage = createPage(tenant, uuid(), `Step ${i + 2}`, `${i + 2}`);
      subPages.push(newPage);
      startPage.nextSteps.push({ pageId: newPage.id, text: `Option ${i + 1}` });
    }

    this.storeSmartGuideInEditorState({
      smartGuide: newSmartGuide,
      pages: [startPage, ...subPages]
    });

    this.setDirty();
  }

  @Action({ rawError: true })
  async loadSmartGuide(id: string) {
    this.clear();

    const smartGuide = await smartGuideService.fetchSmartGuide(id);
    if (!smartGuide) {
      throw Error(`SmartGuide with id ${id} could not be found`);
    }

    const pages = await smartGuideService.fetchSmartGuidePages(id);
    this.storeSmartGuideInEditorState({ smartGuide, pages });
  }

  @Action({ rawError: true })
  async saveGuideAndPages() {
    if (!this.currentSmartGuide) {
      throw Error("Cannot save when smartGuide hasn't been loaded.");
    }
    await smartGuideService.updateSmartGuide(this.currentSmartGuide);
    await Promise.all(this.allPages.map((page) => smartGuideService.updateSmartGuidePage(page)));

    // It is only necessary to delete pages which have been persisted previously:
    const existingIdsToRemove = this.pageIdsToDelete.filter((idToDelete) =>
      this.initiallyLoadedPageIds.includes(idToDelete)
    );
    await smartGuideService.deleteSmartGuidePages(
      existingIdsToRemove,
      this.currentSmartGuide.tenant
    );

    this.resetAfterSave();
  }

  @Action({ rawError: true })
  async uploadFilesForPage(payload: {
    files: File[];
    smartGuide: SmartGuide;
    page: SmartGuidePage;
  }) {
    const { files, smartGuide, page } = payload;
    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        const { name, type: mimeType } = file;
        const entropy = R.randomString(5);
        const fileName = `${entropy}-${name}`;

        await smartGuideStorage.put(
          `${buildAssetPathPrefix(smartGuide.id, smartGuide.tenant)}/${fileName}`,
          file,
          {
            contentType: mimeType
          }
        );

        return { fileName, mimeType };
      })
    );

    this.addMediaToPage({ uploadedFiles, page, smartGuide });
  }

  @Action({ rawError: true })
  async deleteGuideAndPages(id: string) {
    const smartGuide = await smartGuideService.fetchSmartGuide(id);
    if (!smartGuide) {
      throw Error(`SmartGuide with id ${id} could not be found`);
    }

    // eslint-disable-next-line no-useless-catch
    try {
      const pages = await smartGuideService.fetchSmartGuidePages(id);
      logger.debug("delete smartGuidePages");
      await smartGuideService.deleteSmartGuidePages(
        pages.map((page) => page.id),
        smartGuide.tenant
      );

      logger.debug("deleteSmartGuide", { id, tenant: smartGuide.tenant });
      await smartGuideService.deleteSmartGuide(id, smartGuide.tenant);

      logger.debug("deleteSmartGuideMedia", { id, tenant: smartGuide.tenant });
      await smartGuideService.deleteSmartGuideMedia(id, smartGuide.tenant);
    } catch (error) {
      throw error;
    }
  }
}
