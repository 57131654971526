import {
  Asset,
  AssetDocument,
  AssetKey,
  AssetType,
  Contact,
  ProcessCell,
  Site
} from "@/models/asset";
import gql from "graphql-tag";

export interface Mutation {
  addAsset: Asset;
  addAssetType: AssetType;
  updateAssetType: AssetType;
  addProcessCell: ProcessCell;
  addSite: Site;
  addContactPerson: Contact;
  addDocument: AssetDocument;
  updateAsset: Asset;
  updateProcessCell: ProcessCell;
  updateContactPerson: Contact;
  removeAsset: AssetKey;
  removeDocument: { id: string };
}

export const addAsset = gql`
  mutation AddAsset($asset: AssetInput!) {
    addAsset(asset: $asset) {
      assetId
    }
  }
`;

export const addAssetType = gql`
  mutation AddAssetType($name: String!, $manufacturer: String!, $image: String) {
    addAssetType(name: $name, manufacturer: $manufacturer, image: $image) {
      id
      name
      manufacturer
    }
  }
`;

export const updateAssetType = gql`
  mutation UpdateAssetType($id: String!, $name: String!, $manufacturer: String!, $image: String) {
    updateAssetType(id: $id, name: $name, manufacturer: $manufacturer, image: $image) {
      id
      name
      manufacturer
      image
    }
  }
`;

export const addProcessCell = gql`
  mutation AddProcessCell($siteId: String!, $name: String!, $description: String) {
    addProcessCell(siteId: $siteId, name: $name, description: $description) {
      id
      name
    }
  }
`;

export const addSite = gql`
  mutation AddSite($newSite: SiteInput!) {
    addSite(newSite: $newSite) {
      id
      name
      description
      location {
        latitude
        longitude
      }
    }
  }
`;

export const updateProcessCell = gql`
  mutation UpdateProcessCell($id: String!, $siteId: String!, $name: String!, $description: String) {
    updateProcessCell(id: $id, siteId: $siteId, name: $name, description: $description) {
      id
      siteId
      name
      description
    }
  }
`;

export const addContactPerson = gql`
  mutation AddContactPerson($contact: ContactInput) {
    addContactPerson(contact: $contact) {
      id
      name
      description
      role
      company
      email
      phone
      image
    }
  }
`;

export const updateContactPerson = gql`
  mutation UpdateContactPerson($Id: String!, $contact: ContactInput) {
    updateProcessCell(id: $Id, contact: $contact) {
      id
    }
  }
`;

export const addDocument = gql`
  mutation AddDocument($documentInput: AssetDocumentInput!) {
    addDocument(document: $documentInput) {
      id
      assetId
      urlPath
      name
      type
      fileType
      size
      uploaded
    }
  }
`;

export const removeDocument = gql`
  mutation RemoveDocument($assetId: String!, $id: ID!) {
    removeDocument(assetId: $assetId, id: $id) {
      id
    }
  }
`;

export const updateAsset = gql`
  mutation UpdateAsset($assetId: String!, $asset: AssetInput!) {
    updateAsset(assetId: $assetId, asset: $asset) {
      assetId
    }
  }
`;

export const removeAsset = gql`
  mutation RemoveAsset($assetId: String!) {
    removeAsset(assetId: $assetId) {
      assetId
    }
  }
`;
