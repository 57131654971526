








import { Component, Vue } from "vue-property-decorator";
import BackofficeOverview from "@/components/apps/backoffice/BackofficeOverview.vue";
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/apps/Navigation.vue";
import Centered from "@/components/global/Centered.vue";
import Unauthorized from "@/components/global/authorization/Unauthorized.vue";
import * as authzService from "@/services/authorization.service";
import logger from "@/plugins/logger";
@Component({
  components: { BackofficeOverview, Navigation, Footer, Centered, Unauthorized }
})
export default class Backoffice extends Vue {
  private authorizedUser = authzService.unknown;

  async mounted(): Promise<void> {
    try {
      this.authorizedUser = await authzService.getAuthorizedUser();
    } catch (error) {
      logger.warn("Could not access autorized user");
    }
  }
}
