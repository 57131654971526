





























































































import { Component, Vue, Prop } from "vue-property-decorator";
import logger from "@/plugins/logger";
import { Asset, AssetDocument } from "@/models/asset";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import AddDocument from "@/components/apps/assetdetails/documents/crud/AddDocument.vue";
import DeleteDocument from "@/components/apps/assetdetails/documents/crud/DeleteDocument.vue";
import documentsService from "@remote-api/documents.service";
import { constructCustomerAssetUrl } from "@/services/static-assets.service";
import { FilterTuple } from "@/ui-models";
import { sortBy, uniqBy } from "remeda";
import { defaultStorage } from "@/plugins/storage";

@Component({
  components: { OpenDialog, AddDocument, DeleteDocument }
})
export default class Documents extends Vue {
  @Prop()
  private asset!: Asset;
  private documents: AssetDocument[] = [];
  private sizeOfDocuments = 0;
  private filteredDocuments: AssetDocument[] = [];

  private documentTypeTuples: FilterTuple[] = [];
  private filterType = "";
  private filterText = "";

  private isAddDocVisible = false;
  private isDeleteDocVisible = false;
  private documentToDelete: AssetDocument | undefined = undefined;

  async mounted() {
    this.documents = await documentsService.fetchDocuments(this.asset.assetId);
    this.documentTypeTuples = sortBy(
      uniqBy(
        this.documents.map((item) => new FilterTuple(item.type, item.type)),
        (tuple) => tuple.id
      ),
      (tuple) => tuple.name
    );
    this.filteredDocuments = this.documents;
    this.updateSizeOfDocuments();
  }

  updateSizeOfDocuments() {
    this.sizeOfDocuments = this.documents.reduce((acc, item) => (acc = acc + item.size), 0);
  }

  documentsAdded(newDocuments: AssetDocument[]) {
    if (newDocuments) {
      this.documents = [...this.documents, ...newDocuments];
      this.updateSizeOfDocuments();
      this.filterText = "";
      this.filterType = "";
      this.filter();
    }
    this.isAddDocVisible = false;
  }

  filterByDocumentType(type: string) {
    this.filterType = type;
    this.filter();
  }

  filterByText(textFilter: string) {
    this.filterText = textFilter;
    this.filter();
  }

  filter() {
    this.filteredDocuments = this.documents.filter((doc) => {
      const text = this.filterText || "";
      const type = this.filterType || "";
      return doc.name.toLowerCase().includes(text.toLowerCase()) && doc.type.includes(type);
    });
  }

  private async constructRemoteUrl(urlPath: string) {
    try {
      const url = await constructCustomerAssetUrl(urlPath);
      window.open(url, "_blank");
    } catch (error) {
      logger.error("Could not get remote asset url", error);
    }
  }

  private doCheckDelete(item: AssetDocument) {
    this.documentToDelete = item;
    this.isDeleteDocVisible = true;
  }

  private async deleteDocument() {
    this.isDeleteDocVisible = false;
    if (this.documentToDelete !== undefined) {
      try {
        await defaultStorage.remove(this.documentToDelete.urlPath);
        await documentsService.removeDocument(this.asset.assetId, this.documentToDelete.id);
        this.documents = this.documents.filter(
          (element) => element.id !== this.documentToDelete!.id
        );
        this.filteredDocuments = this.filteredDocuments.filter(
          (element) => element.id !== this.documentToDelete!.id
        );
        logger.info("Sucessfuly deleted file");
      } catch (error) {
        logger.error("Could not delete physical file", error);
      }
    }
    this.updateSizeOfDocuments();
  }

  get headers() {
    return [
      {
        text: this.$t("product.pam.detail.documents.name"),
        align: "start",
        value: "name"
      },
      {
        text: this.$t("product.pam.detail.documents.type"),
        value: "type"
      },
      {
        text: this.$t("product.pam.detail.documents.uploaded"),
        value: "uploaded"
      },
      {
        text: this.$t("product.pam.detail.documents.filesize"),
        value: "size"
      },
      {
        text: "",
        value: "delete"
      }
    ];
  }

  get numberOfDocuments() {
    return this.filteredDocuments.length;
  }

  get locale() {
    return this.$i18n.locale;
  }
}
