
































import { Component, Prop, Vue } from "vue-property-decorator";
import Centered from "@/components/global/Centered.vue";
import ProductOtherCluster from "@/components/ProductOtherCluster.vue";
import ProductContact from "@/components/ProductContact.vue";
import ProductExplanation from "@/components/ProductExplanation.vue";
import ProductFeatures from "@/components/ProductFeatures.vue";
import ProductTeaser from "@/components/ProductTeaser.vue";
import DescriptionSection from "@/components/DescriptionSection.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { VRCampusModel } from "./VRCampusModel";
@Component({
  components: {
    ProductTeaser,
    ProductFeatures,
    ProductExplanation,
    DescriptionSection,
    ProductContact,
    ProductOtherCluster,
    Centered
  }
})
export default class VRCampus extends Vue {
  @Prop(Boolean)
  readonly isMicrosite: boolean | undefined;

  get container(): ProductDetailsContainerModel {
    return new VRCampusModel(this.$i18n);
  }

  get padding() {
    let padding = "";
    switch (this.$vuetify?.breakpoint.name) {
      case "xs":
        padding = "padding:0 8px";
        break;
      case "sm":
        padding = "padding:0 16px";
        break;
      case "md":
        padding = "padding:0 32px";
        break;
      case "lg":
      case "xl":
        padding = "padding:0 80px";
        break;
      default:
        padding = "padding:0 80px;";
    }
    return padding;
  }
}
