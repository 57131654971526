









import { Component, Prop, Vue } from "vue-property-decorator";
import BulletLineWidget from "@/components/apps/assetdetails/BulletLineWidget.vue";
import { RangeType } from "@/components/global/charts/BulletChart.vue";
import { last } from "remeda";
import { ChartColors } from "@/components/global/charts/ChartColors";
import { StationData } from "@/models/asset";

@Component({
  components: {
    BulletLineWidget
  }
})
export default class FillingDeviceSpeed extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  widgetData!: number[];

  get fillingSpeed(): Partial<StationData<number>> {
    return {
      stationOne: last(this.widgetData) ?? 0
    };
  }
  ranges: RangeType[] = [
    {
      minValue: 0,
      maxValue: 100,
      color: ChartColors.green
    }
  ];
}
