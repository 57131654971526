









import { Component, Prop, Vue } from "vue-property-decorator";
import { WidgetDataMapper } from "./mapping/WidgetDataMapper";
import AreaWidget from "@/components/apps/assetdetails/AreaWidget.vue";
import { WidgetData } from "@/ui-models";

@Component({
  components: { AreaWidget }
})
export default class PressureWidget extends Vue {
  @Prop()
  widgetData!: WidgetDataMapper;

  get pressureData(): WidgetData {
    return this.widgetData.pressure();
  }
}
