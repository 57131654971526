import { AssetState } from "@/models/asset";
import { MachineStation } from "@/ui-models";

interface TabletThicknessData {
  meanValueSet: number;
  meanValueActual: number;
  standardDeviationSet: number;
  standardDeviationActual: number;
  t2PositivePercent: number;
  t1PositivePercent: number;
  t1NegativePercent: number;
  t2NegativePercent: number;
  timestamp: number;
}

export class TabletThickness implements TabletThicknessData {
  readonly meanValueSet: number;
  readonly meanValueActual: number;
  readonly standardDeviationSet: number;
  readonly standardDeviationActual: number;
  readonly t2PositivePercent: number;
  readonly t1PositivePercent: number;
  readonly t1NegativePercent: number;
  readonly t2NegativePercent: number;
  readonly timestamp: number;

  constructor(data: TabletThicknessData) {
    this.meanValueSet = data.meanValueSet;
    this.meanValueActual = data.meanValueActual;
    this.standardDeviationSet = data.standardDeviationSet;
    this.standardDeviationActual = data.standardDeviationActual;
    this.t2PositivePercent = data.t2PositivePercent;
    this.t1PositivePercent = data.t1PositivePercent;
    this.t1NegativePercent = data.t1NegativePercent;
    this.t2NegativePercent = data.t2NegativePercent;
    this.timestamp = data.timestamp;
  }

  private static forStationOne(anAssetState: AssetState): TabletThicknessData {
    return {
      timestamp: anAssetState.timestamp,
      meanValueSet: anAssetState.DP0096 ?? 0,
      meanValueActual: anAssetState.DP0097 ?? 0,
      standardDeviationSet: anAssetState.DP0098 ?? 0,
      standardDeviationActual: anAssetState.DP0099 ?? 0,
      t1PositivePercent: anAssetState.DP0101 ?? 0,
      t1NegativePercent: anAssetState.DP0102 ?? 0,
      t2PositivePercent: anAssetState.DP0100 ?? 0,
      t2NegativePercent: anAssetState.DP0103 ?? 0
    };
  }

  private static forStationTwo(anAssetState: AssetState): TabletThicknessData {
    return {
      timestamp: anAssetState.timestamp,
      meanValueSet: anAssetState.DP0120 ?? 0,
      meanValueActual: anAssetState.DP0121 ?? 0,
      standardDeviationSet: anAssetState.DP0122 ?? 0,
      standardDeviationActual: anAssetState.DP0123 ?? 0,
      t1PositivePercent: anAssetState.DP0125 ?? 0,
      t1NegativePercent: anAssetState.DP0126 ?? 0,
      t2PositivePercent: anAssetState.DP0124 ?? 0,
      t2NegativePercent: anAssetState.DP0127 ?? 0
    };
  }

  static fromState(anAssetState: AssetState, station: MachineStation): TabletThickness {
    switch (station) {
      case MachineStation.ONE:
        return new TabletThickness(TabletThickness.forStationOne(anAssetState));
      case MachineStation.TWO:
        return new TabletThickness(TabletThickness.forStationTwo(anAssetState));
      default:
        throw new Error(`Unsupported station ${station}`);
    }
  }

  get t1Positive(): number {
    return this.precise(this.meanValueSet * ((100 + this.t1PositivePercent) / 100));
  }

  get t1Negative(): number {
    return this.precise(this.meanValueSet * ((100 - this.t1NegativePercent) / 100));
  }

  get t2Positive(): number {
    return this.precise(this.meanValueSet * ((100 + this.t2PositivePercent) / 100));
  }

  get t2Negative(): number {
    return this.precise(this.meanValueSet * ((100 - this.t2NegativePercent) / 100));
  }

  precise(Value: number): number {
    return Math.round(Value * 10) / 10;
  }
}
