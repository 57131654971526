






















































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Asset } from "@/models/asset";
import { SmartGuide } from "@/models/smartguide";
import smartGuideService from "@remote-api/smartguide.service";

@Component
export default class Documents extends Vue {
  @Prop()
  private asset!: Asset;

  private numberOfGuides = 0;
  private guides: SmartGuide[] = [];
  private filteredGuides: SmartGuide[] = [];

  get headers() {
    return [
      {
        text: this.$t("product.pam.detail.guides.name"),
        align: "start",
        value: "name"
      },
      {
        text: this.$t("product.pam.detail.guides.type"),
        value: "smartGuideType"
      },
      {
        text: this.$t("product.pam.detail.guides.state"),
        value: "state"
      }
    ];
  }

  filterByText(textFilter: string) {
    this.filteredGuides = this.guides.filter((guide) => {
      if (textFilter && textFilter.length > 0) {
        return guide.name.includes(textFilter);
      } else return true;
    });
  }

  async mounted() {
    this.guides = await smartGuideService.fetchSmartGuidesForAsset(this.asset);
    this.numberOfGuides = this.guides.length;
    this.filterByText("");
  }

  @Emit()
  playSmartGuide(element: SmartGuide) {
    return element;
  }
}
