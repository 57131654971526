































import { Component, Vue } from "vue-property-decorator";
import { passwordValidationRules } from "@/services/input-validation.service";
import { Auth } from "@aws-amplify/auth";
import { VueForm } from "@/types.d";
import logger from "@/plugins/logger";
import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";
const authStore = getModule(AuthModule);
@Component
export default class RequireNewPassword extends Vue {
  private formValid = false;
  private password = "";
  private error = "";

  private passwordRules = passwordValidationRules;

  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  async changePassword() {
    if (this.form.validate() === false) {
      return;
    }
    try {
      const cognitoUser = await Auth.completeNewPassword(authStore.user, this.password, []);
      await Auth.verifiedContact(cognitoUser);
      logger.info("Password changed succesfuly.");
      authStore.updateAuthn("signedIn");
      authStore.login(cognitoUser);
    } catch (error) {
      logger.error("Could not reset password.", error);
      this.error = "Could not reset password.";
    }
  }
}
