import { User } from "@/ui-models";
import gql from "graphql-tag";

export type Mutation = {
  updateUserRoles: boolean;
  resetUserPassword: boolean;
  enableUser: boolean;
  disableUser: boolean;
  createUserInTenantGroup: User;
  deleteUser: boolean;
};

export const enableUser = gql`
  mutation EnableUser($username: String!) {
    enableUser(username: $username)
  }
`;

export const disableUser = gql`
  mutation DisableUser($username: String!) {
    disableUser(username: $username)
  }
`;

export const updateUserRoles = gql`
  mutation UpdateUserRoles($username: String!, $roles: [UserRoles!]!) {
    updateUserRoles(username: $username, roles: $roles)
  }
`;

export const resetUserPassword = gql`
  mutation ResetUserPassword($username: String!) {
    resetUserPassword(username: $username)
  }
`;

export const createUserInTenantGroup = gql`
  mutation CreateUserInTenantGroup($emailAsUsername: String!) {
    createUserInTenantGroup(username: $emailAsUsername) {
      username
      email
      enabled
      status
    }
  }
`;

export const deleteUser = gql`
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username)
  }
`;
