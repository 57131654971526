































import BulletChart, { RangeType, DefaultRange } from "@/components/global/charts/BulletChart.vue";
import { StationData } from "@/models/asset";
import { Component, Prop, Vue } from "vue-property-decorator";
import { hasSecondStation } from "@/services/condition.ui-service";

@Component({
  components: {
    BulletChart
  }
})
export default class BulletLineWidget extends Vue {
  @Prop()
  title!: string;
  @Prop({
    default: () => {
      return { stationOne: 0, stationTwo: undefined };
    }
  })
  currentValue!: StationData<number>;
  @Prop()
  unit!: string;
  @Prop({ default: () => [new DefaultRange()] })
  ranges!: RangeType[];

  doesHaveSecondStation = hasSecondStation;

  valueColorFromRange(ranges: RangeType[], value: number): "black" | string {
    const inRange = ranges?.find((range) => range.minValue <= value && range.maxValue >= value);
    return inRange?.color ?? "black";
  }
}
