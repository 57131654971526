























import { Component, Vue, Prop } from "vue-property-decorator";
import Contact from "@/components/Contact.vue";
import { ContactModel } from "@/models/cluster";
@Component({
  components: { Contact }
})
export default class ProductContact extends Vue {
  @Prop()
  contactTeaser!: string;

  @Prop()
  contacts!: ContactModel[];
}
