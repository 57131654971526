























import CreateEntityFormOverlay from "@/components/apps/assetmanager/crud/partials/CreateEntityFormOverlay.vue";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import logger from "@/plugins/logger";
import { emailValidationRules } from "@/services/input-validation.service";
import { Component } from "vue-property-decorator";
import { RemoteStatusService } from "@/services/remote-status.service";
import { User } from "@/ui-models";
import userManagementService from "@remote-api/user-management.service";

@Component({
  components: { CreateEntityFormOverlay }
})
export default class UserAdd extends CreateEntityInForm<User> {
  private username = "";
  private readonly statusService = new RemoteStatusService();

  get emailRules() {
    return emailValidationRules;
  }

  async doSave() {
    try {
      this.statusService.beforeRemoteCall();
      const newUser = await userManagementService.createUserInTenantGroup(this.username);
      this.notifyUser({
        message: String(this.$t("backoffice.user.new-user.success")),
        type: "success"
      });
      this.done(newUser);
    } catch (error) {
      logger.error("Could not create new user", error);
      this.notifyUser({
        message: String(this.$t("backoffice.user.new-user.failure")),
        type: "warning"
      });
    } finally {
      this.statusService.afterRemoteCall();
    }
  }
}
