









































import { Component, Vue, Watch } from "vue-property-decorator";
import CookieLaw from "vue-cookie-law";
import { Route } from "vue-router";

@Component({
  components: {
    CookieLaw
  }
})
export default class CookieBanner extends Vue {
  overlay = true;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route | null): void {
    let cookieBannerIsOpen = true;
    if (this.$refs.cookieBanner) {
      cookieBannerIsOpen = (this.$refs.cookieBanner as any).isOpen;
    }
    const notPrivacyPolicy = this.$route.name != "DataPrivacy";
    this.overlay = cookieBannerIsOpen && notPrivacyPolicy;
    this.disableScrolling(this.overlay);
  }

  mounted() {
    this.onUrlChange(null);
  }

  accept(): void {
    this.overlay = false;
    this.disableScrolling(false);
  }
  close() {
    window.location.href = "https://fette-compacting.com";
  }

  disableScrolling(disable: boolean): void {
    if (disable) {
      document.body.classList.add("scroll_lock");
    } else {
      document.body.classList.remove("scroll_lock");
    }
  }
}
