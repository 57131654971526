

























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Contact } from "@/models/asset";
import { constructCustomerAssetUrl } from "@/services/static-assets.service";

@Component
export default class ContactDrawer extends Vue {
  @Prop()
  contact!: Contact;

  @Prop()
  overlay!: boolean;

  private s3ImageUrl = "";

  async mounted() {
    if (this.contact) {
      const { image } = this.contact;
      if (image) {
        this.s3ImageUrl = await constructCustomerAssetUrl(image);
      }
    }
  }

  @Emit()
  close() {
    return true;
  }
}
