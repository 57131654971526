


























import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { SmartGuideFilterModel } from "@/ui-models";
import { SmartGuide } from "@/models/smartguide";
import { AssetType } from "@/models/asset";

@Component
export default class FilterBox extends Vue {
  @Prop()
  smartGuides!: SmartGuide[];

  @Prop()
  assetTypes!: AssetType[];

  @Prop()
  filterModel!: SmartGuideFilterModel;

  @Emit()
  filter(): SmartGuideFilterModel {
    return this.filterModel;
  }

  filterByAssetType(assetTypeId = "") {
    this.filterModel.assetTypeId = assetTypeId;
    this.filter();
  }

  filterByText(textFilter: string) {
    this.filterModel.freeText = textFilter;
    this.filter();
  }
}
