import { ClusterModel, ProductModel } from "@/models/cluster";
import VueI18n from "vue-i18n";
import { VRCampusModel } from "./VRCampusModel";
import { SmartCollegeModel } from "./SmartCollegeModel";

export class LearnClusterModel implements ClusterModel {
  constructor(private $i18n: VueI18n) {}
  id = "learn";
  get title(): string {
    return this.$i18n.tc("productCluster.learn.title");
  }
  get description(): string {
    return this.$i18n.tc("productCluster.learn.description");
  }
  get products(): ProductModel[] {
    return [new VRCampusModel(this.$i18n).product, new SmartCollegeModel(this.$i18n).product];
  }
}
