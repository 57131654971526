import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import { getContact } from "@/services/contacts.service";
import type VueI18n from "vue-i18n";
import { ControlClusterModel } from "./ControlClusterModel";

export class AssetManagerModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}
  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("assetManager.details.teaser.text")
      },
      features: [
        {
          title: this.$i18n.tc("assetManager.details.features.realTimeOverview.title"),
          text: this.$i18n.tc("assetManager.details.features.realTimeOverview.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_overview_2172952_000000.svg")
        },
        {
          title: this.$i18n.tc("assetManager.details.features.oemDocumentation.title"),
          text: this.$i18n.tc("assetManager.details.features.oemDocumentation.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_OEMcontent_3233352_000000.svg")
        },
        {
          title: this.$i18n.tc("assetManager.details.features.unrestricted.title"),
          text: this.$i18n.tc("assetManager.details.features.unrestricted.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_edit_3248033_000000.svg")
        }
      ],
      contactTeaser: this.$i18n.tc("assetManager.details.contactTeaser"),
      explanation: {
        title: this.$i18n.tc("assetManager.details.explanation.title"),
        text: this.$i18n.tc("assetManager.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/asset_manager.png")
      },
      featureTeaser: this.$i18n.tc("assetManager.details.featureTeaser")
    };
  }

  get cluster(): ClusterModel {
    return new ControlClusterModel(this.$i18n);
  }

  get product(): ProductModel {
    return {
      id: "pam",
      title: this.$i18n.tc("assetManager.product.title"),
      description: this.$i18n.tc("assetManager.product.description"),
      image: require("@/assets/ui-assets/assets/design01/FleetManager_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/FleetManager_mehrfarbig_RGB.svg"),
      link: "/assets/"
    };
  }

  get contacts(): ContactModel[] {
    return [getContact("svulp")];
  }
}
