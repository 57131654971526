import store from "@/store";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ dynamic: true, store, name: "loading" })
export default class LoadingModule extends VuexModule {
  private loading = false;

  get isLoading() {
    return this.loading;
  }

  @Mutation
  startLoading() {
    this.loading = true;
  }

  @Mutation
  stopLoading() {
    this.loading = false;
  }
}
