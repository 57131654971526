









import { User } from "@/ui-models";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import globalConfig from "@/globals/Configuration";
import userManagementService from "@remote-api/user-management.service";
import { notification } from "@/store/store.ui-service";
import UserStatusIcon from "@/components/apps/backoffice/usermanagement/UserStatusIcon.vue";

@Component({ components: { UserStatusIcon } })
export default class UserEnableDisable extends Vue {
  @Prop()
  user!: User;

  @Emit()
  changed() {
    return !this.user.enabled;
  }

  get buttonColor(): string {
    return !this.user.enabled ? globalConfig.colors.success : globalConfig.colors.warning;
  }

  get buttonText(): string {
    const action = !this.user.enabled ? "enable" : "disable";
    return String(this.$t(`global.actions.${action}`));
  }

  async changeStatus() {
    try {
      if (this.user.enabled) {
        await userManagementService.disableUser(this.user.username);
      } else {
        await userManagementService.enableUser(this.user.username);
      }
      this.changed();
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-enable-disable.success")),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-enable-disable.failure")),
        type: "error"
      });
    }
  }
}
