












import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import {
  extrapolateStates,
  groupStatesByHour,
  statesByHourToSeries,
  WidgetSeriesWrapper
} from "@/components/apps/assetdetails/widgets/mapping/BatchHistoryDataMapper";
import type { ApexOptions } from "apexcharts";
import { Component } from "vue-property-decorator";
import conditionService from "@remote-api/condition.service";
import logger from "@/plugins/logger";
import BatchBasedWidget from "@/components/apps/assetdetails/widgets/BatchBasedWidget";

@Component({
  components: {
    WidgetCard
  }
})
export default class BatchHistory extends BatchBasedWidget {
  hourlyStates = new WidgetSeriesWrapper();

  get series(): any[] {
    return [
      {
        name: this.$t("product.pam.asset.state.running"),
        data: this.hourlyStates.running
      },
      {
        name: this.$t("product.pam.asset.state.paused"),
        data: this.hourlyStates.paused
      },
      {
        name: this.$t("product.pam.asset.state.stopped"),
        data: this.hourlyStates.stopped
      },
      {
        name: this.$t("product.pam.asset.state.complete"),
        data: this.hourlyStates.finished
      },
      {
        name: this.$t("product.pam.asset.state.uncertain"),
        data: this.hourlyStates.uncertain
      }
    ];
  }

  get categories(): string[] {
    return this.hourlyStates.categories;
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      grid: {
        show: false
      },
      yaxis: {
        show: false
      },
      xaxis: {
        type: "datetime",
        categories: this.categories,
        labels: {
          datetimeFormatter: {
            hour: "H"
          }
        }
      },
      legend: {
        show: true,
        position: "bottom",
        showForSingleSeries: true
      },
      fill: {
        opacity: 1
      },
      colors: ["#42909F", "#F7CF4C", "#D95635"]
    };
  }

  async doFetchRemoteData(): Promise<void> {
    try {
      const batchHistoryDataSeries = await conditionService.fetchBatchHistory(
        this.assetId,
        this.currentBatchName
      );
      this.hourlyStates = statesByHourToSeries(
        groupStatesByHour(extrapolateStates(batchHistoryDataSeries))
      );
    } catch (error) {
      logger.error("Could not fetch remote batchHistory data", error);
    }
  }
}
