import { AssetState, ActualProduction } from "@/models/asset";
import { last } from "remeda";

export class ActualProductionDataMapper {
  constructor(readonly assetStates: AssetState[]) {}

  get mappedActualProduction(): Partial<ActualProduction> {
    const latestState = last(this.assetStates);
    return {
      goodProduction: latestState?.DP0013,
      badProduction: latestState?.DP0014
    };
  }
}
