









import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/apps/Navigation.vue";
import Centered from "@/components/global/Centered.vue";
@Component({
  components: {
    Navigation,
    Centered
  }
})
export default class Product extends Vue {}
