





























import { Component, Prop, Vue } from "vue-property-decorator";
import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";

import BulletChart, { RangeType } from "@/components/global/charts/BulletChart.vue";
import type { ActualProduction } from "@/models/asset";
@Component({
  components: {
    BulletChart,
    WidgetCard
  }
})
export default class ActualProductionWidget extends Vue {
  @Prop({
    default: () => {
      return { goodProduction: 0, badProduction: 0 };
    }
  })
  widgetData!: ActualProduction;

  allGreenRange: RangeType = {
    minValue: 0,
    maxValue: 100,
    color: "green"
  };

  get standartRanges(): RangeType[] {
    return [
      {
        minValue: 0,
        maxValue: this.widgetData.goodProduction,
        color: "green"
      },
      {
        minValue: this.widgetData.goodProduction,
        maxValue: this.widgetData.goodProduction + this.widgetData.badProduction,
        color: "red"
      }
    ];
  }

  get ranges(): RangeType[] {
    if (this.widgetData.goodProduction === 0 && this.widgetData.badProduction === 0) {
      return [this.allGreenRange];
    }
    if (this.widgetData.goodProduction <= 0 && this.widgetData.badProduction <= 0) {
      return [this.allGreenRange];
    }
    return this.standartRanges;
  }

  get badProduction(): number | "-" {
    return this.widgetData.badProduction >= 0 ? this.widgetData.badProduction : "-";
  }

  get goodProduction(): number | "-" {
    return this.widgetData.goodProduction >= 0 ? this.widgetData.goodProduction : "-";
  }
}
