



























import CreateEntityFormOverlay from "@/components/apps/assetmanager/crud/partials/CreateEntityFormOverlay.vue";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import logger from "@/plugins/logger";
import assetService from "@remote-api/asset.service";
import { requiredValidationRule } from "@/services/input-validation.service";
import { Component, Prop } from "vue-property-decorator";
import { ProcessCell } from "@/models/asset";
import { RemoteStatusService } from "@/services/remote-status.service";

@Component({
  components: { CreateEntityFormOverlay }
})
export default class EditProcessCell extends CreateEntityInForm<ProcessCell> {
  @Prop()
  processCell!: ProcessCell;
  private processCellName = "";
  private processCellDescription = "";
  private readonly statusService = new RemoteStatusService();

  mounted() {
    this.processCellName = this.processCell.name;
    this.processCellDescription = this.processCell.description || "";
  }

  nameRules = [requiredValidationRule("Process cell name")];

  async doSave() {
    try {
      this.statusService.beforeRemoteCall();
      const updatedProcessCell = await assetService.updateProcessCell(
        this.processCell.id,
        this.processCell.siteId,
        this.processCellName,
        this.processCellDescription
      );

      this.notifyUser({ message: "Process cell updated", type: "success" });
      this.done(updatedProcessCell);
    } catch (error) {
      this.notifyUser({ message: "Process cell creation failed", type: "warning" });
      logger.error("Could not save process cell", error);
    } finally {
      this.statusService.afterRemoteCall();
    }
  }
}
