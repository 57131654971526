var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"py-8"},[_c('v-container',{staticClass:"mx-0"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('span',{staticClass:"fc-text-headline H100 black-background-white-text mb-4"},[_vm._v(_vm._s(_vm.$t("liveguide.contact.start.title")))]),_c('span',{staticClass:"fc-text-body large mt-4"},[_c('p',[_vm._v(_vm._s(_vm.$t("liveguide.contact.start.steps.title")))]),_c('ol',[_c('li',[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.steps.fillForm"))+" "),_c('v-sheet',{staticClass:"pa-2"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"label":_vm.$t('liveguide.contact.start.form.licenseType'),"required":"","items":_vm.translatedLicenseTypes,"rules":_vm.validationRule('liveguide.contact.start.form.licenseType.validation')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.licenseType),callback:function ($$v) {_vm.$set(_vm.formModel, "licenseType", $$v)},expression:"formModel.licenseType"}}),_c('v-text-field',{attrs:{"label":_vm.$t('liveguide.contact.start.form.licenseCount'),"autocomplete":"off","type":"number","min":"0","required":"","rules":_vm.validationRule('liveguide.contact.start.form.licenseCount.validation')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.licenseCount),callback:function ($$v) {_vm.$set(_vm.formModel, "licenseCount", $$v)},expression:"formModel.licenseCount"}}),_c('v-select',{attrs:{"label":_vm.$t('liveguide.contact.start.form.salutation'),"autocomplete":"off","required":"","rules":_vm.validationRule('liveguide.contact.start.form.salutation.validation'),"items":_vm.translatedSalutations},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.salutation),callback:function ($$v) {_vm.$set(_vm.formModel, "salutation", $$v)},expression:"formModel.salutation"}}),_c('v-text-field',{attrs:{"label":_vm.$t('liveguide.contact.start.form.name'),"autocomplete":"off","required":"","rules":_vm.validationRule('liveguide.contact.start.form.name.validation')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.name),callback:function ($$v) {_vm.$set(_vm.formModel, "name", $$v)},expression:"formModel.name"}}),_c('v-text-field',{attrs:{"label":_vm.$t('liveguide.contact.start.form.company'),"autocomplete":"off","rules":_vm.validationRule('liveguide.contact.start.form.company.validation'),"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.company),callback:function ($$v) {_vm.$set(_vm.formModel, "company", $$v)},expression:"formModel.company"}}),_c('v-text-field',{attrs:{"label":_vm.$t('liveguide.contact.start.form.email'),"autocomplete":"off","required":"","type":"email","rules":_vm.emailRules},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.formModel.email),callback:function ($$v) {_vm.$set(_vm.formModel, "email", $$v)},expression:"formModel.email"}}),_c('v-textarea',{attrs:{"label":_vm.$t('liveguide.contact.start.form.message'),"autocomplete":"off"},model:{value:(_vm.formModel.message),callback:function ($$v) {_vm.$set(_vm.formModel, "message", $$v)},expression:"formModel.message"}}),_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.form.dataProtection.label"))+" "),_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('PrivacyPolicyModal',{on:{"accept-policy":_vm.acceptDataProtection}})]},proxy:true}]),model:{value:(_vm.dataProtectionNote),callback:function ($$v) {_vm.dataProtectionNote=$$v},expression:"dataProtectionNote"}}),_c('p',[_vm._v(_vm._s(_vm.$t("liveguide.contact.start.form.mandatory.label")))]),_c('v-btn',{staticClass:"mr-4 fc-form-button",attrs:{"disabled":!_vm.dataProtectionNote || !_vm.valid,"color":"brand","tile":"","required":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.form.submit"))+" ")])],1)],1)],1),_c('p',{staticClass:"py-6"},[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.text.purchaseEmail"))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.steps.downloadApp"))+" "),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://apps.apple.com/us/app/osdi/id1491305529","target":"_blank"}},[_vm._v("App Store")])]),_c('li',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.fettecompacting.app","target":"_blank"}},[_vm._v("Google Play Store")])]),_c('li',[_c('a',{attrs:{"href":"https://liveguide-manual.osdi-solutions.com/usermanual/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.steps.downloadApp.instruction"))+" ")])])])]),_c('li',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(_vm.$t("liveguide.contact.start.steps.createAccount"))+" ")])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }