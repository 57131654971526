import { SmartGuide, SmartGuidePage } from "@/models/smartguide";
import gql from "graphql-tag";

export interface Query {
  getSmartGuide: SmartGuide | undefined;
  getSmartGuides: SmartGuide[];
  getSmartGuidePage: SmartGuidePage;
}

export const getSmartGuide = gql`
  query GetSmartGuide($id: String!) {
    getSmartGuide(id: $id) {
      tenant
      id
      name
      smartGuideType
      state
      assetTypes
      relevantForAssets
      startPageId
    }
  }
`;

export const getSmartGuides = gql`
  query GetSmartGuides {
    getSmartGuides {
      tenant
      id
      name
      smartGuideType
      state
      assetTypes
      relevantForAssets
      startPageId
    }
  }
`;

export const getSmartGuidePage = gql`
  query GetSmartGuidePage($id: String!) {
    getSmartGuidePage(id: $id) {
      tenant
      id
      pageNumber
      warning
      text
      nextSteps {
        text
        pageId
      }
      media {
        path
        mediaType
        mimeType
      }
      informationText
      recommendationText
      progress
    }
  }
`;
