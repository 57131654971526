








import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import DataPrivacyNotice from "@/components/DataPrivacyNotice.vue";
import Navigation from "@/components/Navigation.vue";
import Centered from "@/components/global/Centered.vue";
@Component({
  components: { Footer, DataPrivacyNotice, Navigation, Centered }
})
export default class Home extends Vue {}
