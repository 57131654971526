import { AssetState } from "@/models/asset";
import type { WidgetData } from "@/ui-models";
import type { IVueI18n } from "vue-i18n";

export class PressureDataMapper {
  constructor(readonly assetStates: AssetState[], readonly i18n: IVueI18n) {}

  get mappedPressure(): WidgetData {
    return {
      labels: this.assetStates.map((it) => it.timestamp),
      series: [
        {
          name: String(this.i18n.t("product.pam.asset.conditions.widget.pressure.title")),
          data: this.assetStates.map((it) => it.DP0007 ?? null)
        }
      ]
    };
  }
}
