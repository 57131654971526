export function humanizeBytes(bytes: number): string {
  const kB = 1000;
  const MB = Math.pow(kB, 2);
  const GB = Math.pow(kB, 3);

  if (bytes > GB) {
    return (bytes / GB).toFixed(1) + " GB";
  }

  if (bytes > MB) {
    return (bytes / MB).toFixed(1) + " MB";
  }

  if (bytes > kB) {
    return (bytes / kB).toFixed(1) + " KB";
  }

  return `${bytes} B`;
}
