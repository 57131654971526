


















import type { Asset } from "@/models/asset";
import { Component, Vue, Prop } from "vue-property-decorator";
import AssetModeBadge from "@/components/apps/assetmanager/AssetModeBadge.vue";
import AssetStateBadge from "@/components/apps/assetmanager/AssetStateBadge.vue";

@Component({
  components: {
    AssetModeBadge,
    AssetStateBadge
  }
})
export default class AssetConditionsHeadline extends Vue {
  @Prop()
  asset!: Asset;
}
