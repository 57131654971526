import { ApexOptions } from "apexcharts";
import { tabletThicknessDeviation, tabletThicknessOptions } from "./tabletThickness";
import { IVueI18n } from "vue-i18n";

export default class ChartOptions {
  constructor(private readonly i18n: IVueI18n) {}

  get tabletThickness(): ApexOptions {
    return tabletThicknessOptions(this.i18n);
  }

  get tabletThicknessDeviation(): ApexOptions {
    return tabletThicknessDeviation(this.i18n);
  }
}
