





























import { Component, Vue, Prop } from "vue-property-decorator";
import ProductDetails from "@/components/ProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import Centered from "@/components/global/Centered.vue";
import ProductOtherCluster from "@/components/ProductOtherCluster.vue";
import ProductContact from "@/components/ProductContact.vue";
import ProductExplanation from "@/components/ProductExplanation.vue";
import ProductFeatures from "@/components/ProductFeatures.vue";
import ProductTeaser from "@/components/ProductTeaser.vue";
import ProductLink from "@/components/ProductLink.vue";
import Contact from "@/components/apps/liveguide/Contact.vue";
import LicenseOverview from "@/components/apps/liveguide/LicenseOverview.vue";
import DescriptionSection from "@/components/DescriptionSection.vue";
import { LiveGuideModel } from "@/components/products/LiveGuideModel";
import ClickableTile from "@/components/apps/liveguide/ClickableTile.vue";

@Component({
  components: {
    ProductDetails,
    Centered,
    ProductOtherCluster,
    ProductContact,
    ProductExplanation,
    ProductFeatures,
    ProductTeaser,
    ProductLink,
    Contact,
    ClickableTile,
    DescriptionSection,
    LicenseOverview
  }
})
export default class LiveGuide extends Vue {
  @Prop(Boolean)
  readonly isMicrosite: boolean | undefined;

  contactSent = false;

  get container(): ProductDetailsContainerModel | undefined {
    return new LiveGuideModel(this.$i18n);
  }

  get padding() {
    let padding = "";
    switch (this.$vuetify?.breakpoint.name) {
      case "xs":
        padding = "padding:0 8px";
        break;
      case "sm":
        padding = "padding:0 16px";
        break;
      case "md":
        padding = "padding:0 32px";
        break;
      case "lg":
      case "xl":
        padding = "padding:0 80px";
        break;
      default:
        padding = "padding:0 80px;";
    }
    return padding;
  }
}
