





























import { Component, Vue, Prop } from "vue-property-decorator";
import { ContactModel } from "@/models/cluster";

@Component
export default class Contact extends Vue {
  @Prop()
  contact!: ContactModel;
}
