






























import RadialBar from "@/components/global/charts/RadialBar.vue";
import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import type { ApexOptions } from "apexcharts";
import { last } from "remeda";
import { ProductOutput } from "./mapping/WidgetDataMapper";
import { colorByRange, ColorInterval } from "@/services/colorize.ui-service";
import { ChartColors } from "@/components/global/charts/ChartColors";

const colorRange: ColorInterval[] = [
  {
    color: ChartColors.red,
    start: 0,
    end: 15
  },
  {
    color: ChartColors.yellow,
    start: 16,
    end: 25
  },
  {
    color: ChartColors.green,
    start: 26,
    end: 75
  },
  {
    color: ChartColors.yellow,
    start: 76,
    end: 85
  },
  {
    color: ChartColors.red,
    start: 86,
    end: 100
  }
];

@Component({
  components: { RadialBar, WidgetCard }
})
export default class ProductOutputPerHour extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  productOutput!: ProductOutput;

  get rotorSpeedRpm(): number {
    return last(this.productOutput.rotorSpeedData) ?? 0;
  }

  get tabletsPerHour(): number {
    return last(this.productOutput.productOutputData) ?? 0;
  }

  get rotorSpeedPercent(): number {
    return last(this.productOutput.rotorSpeedPercentData) ?? 0;
  }

  get colorize(): string {
    return colorByRange(this.rotorSpeedPercent, colorRange);
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "radialBar"
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "60%"
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: "15px"
          }
        }
      }
    },
    fill: {
      colors: [() => this.colorize]
    }
  };
}
