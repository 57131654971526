




















import Header from "@/components/apps/Header.vue";

import OpenDialog from "@/components/apps/OpenDialog.vue";
import { User } from "@/ui-models";
import { Component, Vue } from "vue-property-decorator";
import userManagementService from "@remote-api/user-management.service";
import UserList from "./UserList.vue";
import UserAdd from "@/components/apps/backoffice/usermanagement/UserAdd.vue";
import HeaderAddButton from "@/components/apps/partials/HeaderAddButton.vue";
import type { BreadcrumbItem } from "@/components/apps/NavigationBreadcrumb.vue";
import { notification } from "@/store/store.ui-service";
import logger from "@/plugins/logger";

@Component({ components: { UserList, OpenDialog, UserAdd, Header, HeaderAddButton } })
export default class UserManager extends Vue {
  users: User[] = [];
  overlay = false;

  get headerItems(): BreadcrumbItem[] {
    return [
      { text: "backoffice.user.title", disabled: false, to: "/backoffice/users", exact: true }
    ];
  }

  async mounted() {
    try {
      this.users = await userManagementService.fetchUsersInTenantGroup();
    } catch (error) {
      const message = "Could not load remote users.";
      logger.error(message, error);
      notification.notifyUser({ message, type: "error" });
    }
  }

  addNewUser() {
    this.overlay = true;
  }

  userCreated(theNewUser: User) {
    this.users = [...this.users, theNewUser];
    this.closeOverlay();
  }

  userDeleted(theDeletedUser: User) {
    this.users = this.users.filter((aUser) => aUser.username !== theDeletedUser.username);
  }

  userUpdated(theUpdatedUser: User) {
    this.users = this.users.map((aUser) => {
      if (aUser.username !== theUpdatedUser.username) {
        return aUser;
      }
      return {
        ...aUser,
        ...theUpdatedUser
      };
    });
  }

  closeOverlay() {
    this.overlay = false;
  }
}
