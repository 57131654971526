














import { Component, Vue, Prop } from "vue-property-decorator";
import SmartGuidePlayerPage from "@/components/apps/smartguide/SmartGuidePlayerPage.vue";
import { SmartGuide, SmartGuidePage } from "@/models/smartguide";
import smartGuideService from "@remote-api/smartguide.service";
import { staticSupportPage, staticFinishedPage } from "@/services/smartguide.ui-service";
@Component({
  components: {
    SmartGuidePlayerPage
  }
})
export default class SmartGuidePlayer extends Vue {
  @Prop()
  private smartGuide!: SmartGuide;
  private currentPageId = this.smartGuide.startPageId;
  private currentPage: SmartGuidePage = {} as SmartGuidePage;
  private pageIdHistory: string[] = [];

  async loadContent() {
    switch (this.currentPageId) {
      case "support":
        this.currentPage = staticSupportPage;
        break;

      case "finished":
        this.currentPage = staticFinishedPage;
        break;

      default:
        this.currentPage = await smartGuideService.fetchSmartGuidePage(this.currentPageId);
    }
  }

  async mounted() {
    this.pageIdHistory = [];
    this.pageIdHistory.push(this.currentPageId);
    await this.loadContent();
  }

  async switchPage(pageId: string) {
    this.currentPageId = pageId;
    this.pageIdHistory.push(this.currentPageId);
    await this.loadContent();
  }

  async goBack() {
    if (this.pageIdHistory.length > 1) {
      this.pageIdHistory.pop();
      this.currentPageId = this.pageIdHistory[this.pageIdHistory.length - 1];
      await this.loadContent();
    }
  }
}
