



































import { Component, Emit, Vue } from "vue-property-decorator";
@Component
export default class PrivacyPolicyModal extends Vue {
  private dialog = false;

  @Emit()
  private acceptPolicy(acceptPolicyFlag: boolean): boolean {
    return acceptPolicyFlag;
  }

  private hideDialog() {
    this.dialog = false;
  }
  agree() {
    this.hideDialog();
    this.acceptPolicy(true);
  }
  disagree() {
    this.hideDialog();
    this.acceptPolicy(false);
  }
}
