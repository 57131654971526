
















































import BasicCard from "@/components/apps/assetdetails/widgets/BasicCard.vue";

import RadialBar from "@/components/global/charts/RadialBar.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import type { ApexOptions } from "apexcharts";
import { Progress as ProgressData } from "@/models/asset";
import { colorByRange, ColorInterval } from "@/services/colorize.ui-service";
import { ChartColors } from "@/components/global/charts/ChartColors";

const colorRange: ColorInterval[] = [
  {
    color: ChartColors.red,
    start: 0,
    end: 15
  },
  {
    color: ChartColors.yellow,
    start: 16,
    end: 25
  },
  {
    color: ChartColors.yellow,
    start: 26,
    end: 75
  },
  {
    color: ChartColors.yellow,
    start: 76,
    end: 85
  },
  {
    color: ChartColors.green,
    start: 86,
    end: 100
  }
];

@Component({
  components: { RadialBar, BasicCard }
})
export default class Progress extends Vue {
  @Prop({
    default: () => {
      return { percent: 0 };
    }
  })
  progress!: ProgressData;

  chartOptions: ApexOptions = {
    chart: {
      type: "radialBar",
      height: "100%"
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: false
          },

          value: {
            offsetY: 5,
            fontSize: "18px",
            fontWeight: "normal",
            formatter: function (val) {
              return val.toFixed(0) + "%";
            }
          }
        }
      }
    },
    fill: {
      type: "gradient",
      colors: ["#1BB1BB", "#006C80"],
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100]
      }
    }
  };

  get colorize(): string {
    return colorByRange(this.progress.percent, colorRange);
  }
}
