import { TimeSeriesData } from "@/models/asset";
import logger from "@/plugins/logger";
import { eachMinuteOfInterval, endOfHour, isValid } from "date-fns";
import { groupBy, uniqBy } from "remeda";

export interface StateHistory {
  running: number;
  paused: number;
  stopped: number;
  uncertain: number;
  finished: number;
}
export type HourlyStateHistory = Record<string, StateHistory>;

export function extrapolateState(
  timelineData: [string, string | number],
  nextTimelineData?: [string, string | number]
): [Date, string | number][] {
  const [givenTimestamp, givenState] = timelineData;

  let endOfEventStateRange = endOfHour(new Date(givenTimestamp));
  if (isValid(new Date(nextTimelineData?.[0] as any))) {
    endOfEventStateRange = new Date(nextTimelineData?.[0] as any);
  }

  const missingMinutes = eachMinuteOfInterval({
    start: new Date(givenTimestamp),
    end: endOfEventStateRange
  });

  logger.debug(`Found ${missingMinutes.length} missing state minutes.`);
  return missingMinutes.map((missingTimestamp) => {
    //    logger.debug(`Filling state ${givenState} for missing minute ${missingTimestamp}`);
    return [missingTimestamp, givenState];
  });
}

export function extrapolateStates(timelineData: TimeSeriesData): [Date, string | number][] {
  const extrapolatedStates = timelineData.flatMap((timeline, index, timelineList) =>
    extrapolateState(timeline, timelineList[index + 1])
  );
  // remove duplicates caused by inclusive intervals
  return uniqBy(
    extrapolatedStates,
    ([givenTimestamp, givenState]) => `${givenTimestamp}#${givenState}`
  );
}

export function groupStatesByHour(timelineStates: [Date, string | number][]): HourlyStateHistory {
  const statesByHour = groupBy(timelineStates, ([givenTimestamp]) => endOfHour(givenTimestamp));
  const hourlyRunningHistory: HourlyStateHistory = {};
  Object.entries(statesByHour).forEach((entry) => {
    const [hourTimestamp, timelineData] = entry;
    const accState = timelineData.reduce(
      (state, [timestamp, value]) => {
        switch (value) {
          case 0:
            state.uncertain = state.uncertain + 1;
            break;
          case 1:
            state.running = state.running + 1;
            break;
          case 2:
            state.running = state.running + 1;
            break;
          case 3:
            state.stopped = state.stopped + 1;
            break;
          case 4:
            state.paused = state.paused + 1;
            break;
          case 5:
            state.finished = state.finished + 1;
            break;
          default:
            logger.warn(`Undefined state ${value} detected at ${timestamp}`);
            break;
        }
        return state;
      },
      {
        running: 0,
        paused: 0,
        stopped: 0,
        uncertain: 0,
        finished: 0
      }
    );
    hourlyRunningHistory[hourTimestamp] = accState;
  });
  return hourlyRunningHistory;
}

export class WidgetSeriesWrapper {
  categories: string[] = [];
  finished: number[] = [];
  paused: number[] = [];
  running: number[] = [];
  stopped: number[] = [];
  uncertain: number[] = [];
}

export function statesByHourToSeries(hourlyStates: HourlyStateHistory): WidgetSeriesWrapper {
  const result = new WidgetSeriesWrapper();
  Object.entries(hourlyStates).forEach(([hour, stateHistory]) => {
    result.categories.push(hour);
    result.running.push(stateHistory.running ?? 0);
    result.paused.push(stateHistory.paused ?? 0);
    result.stopped.push(stateHistory.stopped ?? 0);
    result.finished.push(stateHistory.finished ?? 0);
    result.uncertain.push(stateHistory.uncertain ?? 0);
  });
  return result;
}
