





















































import { Component, Prop } from "vue-property-decorator";
import documentsService from "@remote-api/documents.service";
import logger from "@/plugins/logger";
import CreateEntityInForm from "@/components/apps/assetmanager/crud/partials/CreateEntityInForm";
import globalConfig from "@/globals/Configuration";
import * as authnService from "@/services/authentication.service";
import { Storage } from "@aws-amplify/storage";
import { requiredValidationRule } from "@/services/input-validation.service";
import { AssetDocument } from "@/models/asset";
import * as R from "remeda";

@Component
export default class AddDocument extends CreateEntityInForm<AssetDocument[]> {
  @Prop()
  private assetId!: string;

  @Prop()
  private sizeOfDocuments!: number;

  private files: File[] = [];
  private documentType = "";
  private fileRules = [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) =>
      !value ||
      this.sizeOfDocuments + this.calculateFilesSize(value) < 100000000 ||
      "You cannot upload more than 100 MB per Asset!"
  ];
  private typeRules = [requiredValidationRule("Document Type")];

  private documentTypes = [
    "Manual",
    "SOP",
    "BOM",
    "Qualification",
    "Circuit Diagram",
    "Training Instructions",
    "Customer Reports",
    "Service Reports",
    "Data Sheets"
  ].sort();

  private uploading = false;

  calculateFilesSize(files: File[]) {
    return files.reduce((acc, item) => (acc = acc + item.size), 0);
  }

  async doSaveFile(file: File | null): Promise<string> {
    if (file) {
      const { name, type: mimeType } = file;
      const visibility = globalConfig.customerAssets.defaultVisibility;
      const tenant = await authnService.getTenant();
      const documentsFolder = globalConfig.customerAssets.documents.folderName;
      const entropy = R.randomString(5);
      const key = `${visibility}/${tenant}/${documentsFolder}/${this.assetId}/${entropy}-${name}`;
      const s3Upload = (await Storage.put(key, file, {
        contentType: mimeType
      })) as { key: string };
      return s3Upload.key;
    } else {
      return "";
    }
  }

  async doSaveOneUpload(file: File): Promise<AssetDocument> {
    const { name, type, size } = file;
    const urlPath = await this.doSaveFile(file);

    return await documentsService.createDocument({
      assetId: this.assetId,
      name,
      size,
      type: this.documentType,
      fileType: type,
      urlPath
    });
  }

  async doSave() {
    try {
      this.uploading = true;
      const documents = await Promise.all(this.files.map((f) => this.doSaveOneUpload(f)));

      this.notifyUser({ message: "Documents created", type: "success" });
      logger.info("Created new document", documents);
      this.done(documents);
    } catch (error) {
      this.uploading = false;
      this.notifyUser({ message: "Could not save document", type: "warning" });
      logger.error("Could not save document", error);
    }
  }
}
