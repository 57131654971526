











































import { Component, Vue } from "vue-property-decorator";
import { Auth } from "@aws-amplify/auth";
import { VueForm } from "@/types.d";
import { getModule } from "vuex-module-decorators";
import { emailValidationRules, requiredValidationRule } from "@/services/input-validation.service";
import AuthModule from "@/store/modules/auth";
import logger from "@/plugins/logger";
import { AuthStorage } from "@/services/authentication.storage";
const authStore = getModule(AuthModule);

@Component
export default class Login extends Vue {
  private username = "";
  private password = "";
  private valid = true;
  private error = "";

  private passwordRules = [requiredValidationRule("Password")];

  private emailRules = emailValidationRules;

  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  forgotPassword() {
    authStore.updateAuthn("forgotPassword");
  }

  login() {
    if (this.form.validate()) {
      this.doLogin();
    }
  }

  reset() {
    this.form.reset();
  }

  async doLogin() {
    try {
      AuthStorage.clear();
      const cognitoUser = await Auth.signIn(this.username.toLowerCase(), this.password);
      logger.info("sign in successful");

      /**
       * original logic:
       * @see https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-vue/src/components/authenticator/SignIn.vue
       */
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.info("require new password");
        authStore.updateAuthn("requireNewPassword");
      } else {
        authStore.updateAuthn("signedIn");
      }

      authStore.login(cognitoUser);
      this.error = "";
    } catch (error) {
      authStore.login(null);
      logger.error(error);
      this.error = "Could not log you in.";
    }
  }
}
