









import BulletLineWidget from "@/components/apps/assetdetails/BulletLineWidget.vue";
import { RangeType } from "@/components/global/charts/BulletChart.vue";
import { ChartColors } from "@/components/global/charts/ChartColors";
import { StationData } from "@/models/asset";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    BulletLineWidget
  }
})
export default class FillingDeviceTemperature extends Vue {
  @Prop()
  widgetData!: StationData<number>;

  @Prop()
  maxTemperature!: number;

  get ranges(): RangeType[] {
    if (this.maxTemperature) {
      return [
        {
          minValue: 0,
          maxValue: this.maxTemperature,
          color: ChartColors.green,
          defaultCase: true
        },
        {
          minValue: this.maxTemperature,
          maxValue: this.maxTemperature + 10,
          color: ChartColors.red
        }
      ];
    } else {
      return [
        {
          minValue: 0,
          maxValue: Math.max(this.widgetData.stationOne, this.widgetData.stationTwo),
          color: ChartColors.green,
          defaultCase: true
        }
      ];
    }
  }
}
