



































import { Component, Vue, Prop } from "vue-property-decorator";
import ProductOtherCluster from "@/components/ProductOtherCluster.vue";
import ProductContact from "@/components/ProductContact.vue";
import ProductExplanation from "@/components/ProductExplanation.vue";
import ProductFeatures from "@/components/ProductFeatures.vue";
import ProductTeaser from "@/components/ProductTeaser.vue";
import { ProductDetailContainerModel } from "@/models/cluster";
@Component({
  components: {
    ProductOtherCluster,
    ProductContact,
    ProductExplanation,
    ProductFeatures,
    ProductTeaser
  }
})
export default class ProductDetails extends Vue {
  @Prop()
  container!: ProductDetailContainerModel;

  get padding() {
    let padding = "";
    switch (this.$vuetify?.breakpoint.name) {
      case "xs":
        padding = "padding:0 8px";
        break;
      case "sm":
        padding = "padding:0 16px";
        break;
      case "md":
        padding = "padding:0 32px";
        break;
      case "lg":
      case "xl":
        padding = "padding:0 80px";
        break;
      default:
        padding = "padding:0 80px;";
    }
    return padding;
  }
}
