
















import { Component, Prop, Vue } from "vue-property-decorator";
import AssetManager from "@/components/products/AssetManager.vue";
import ConditionMonitor from "@/components/products/ConditionMonitor.vue";
import VRCampus from "@/components/products/VRCampus.vue";
import SmartCollege from "@/components/products/SmartCollege.vue";
import PerformanceManager from "@/components/products/PerformanceManager.vue";
import SmartInterface from "@/components/products/SmartInterface.vue";
import ToolManagement from "@/components/products/ToolManagement.vue";
import PredictiveMaintenance from "@/components/products/PredictiveMaintenance.vue";
import LiveGuide from "@/components/products/LiveGuide.vue";

@Component({
  components: {
    AssetManager,
    ConditionMonitor,
    VRCampus,
    SmartCollege,
    PerformanceManager,
    SmartInterface,
    ToolManagement,
    PredictiveMaintenance,
    LiveGuide
  }
})
export default class ProductDetailsV2 extends Vue {
  @Prop()
  productId!: string;
}
