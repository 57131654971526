























import { Component, Vue, Prop } from "vue-property-decorator";
import Product from "@/components/Product.vue";
import { ClusterModel } from "@/models/cluster";
@Component({
  components: { Product }
})
export default class Cluster extends Vue {
  @Prop()
  cluster!: ClusterModel;
}
