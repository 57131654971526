import { constructCustomerAssetUrl, retryUntilFileExists } from "@/services/static-assets.service";
import type { StorageClass } from "@aws-amplify/storage";

export type MediaType = "image" | "video" | "document";

export interface Media {
  path: string;
  mediaType: MediaType;
  mimeType: string;
}

export interface ResolvedMedia {
  thumbnailUrl: string;
  resolvedUrl: string;
  mediaType: MediaType;
}

export async function thumbnailMediaURL(
  media: Media,
  storage: Partial<StorageClass>
): Promise<string> {
  if (media.mediaType === "image") {
    return await constructCustomerAssetUrl(media.path!, storage);
  } else if (media.mediaType === "video") {
    const previewPath = media.path!.replace(/\.\w+$/, `-preview.jpg`);
    await retryUntilFileExists(previewPath, storage);
    return await constructCustomerAssetUrl(previewPath, storage);
  } else {
    throw new Error("Unsupported media type!");
  }
}

export async function resolveMediaURLs(
  media: Media[],
  storage: Partial<StorageClass>
): Promise<ResolvedMedia[]> {
  return await Promise.all(
    media.map(async (media) => ({
      thumbnailUrl: await thumbnailMediaURL(media, storage),
      resolvedUrl: await constructCustomerAssetUrl(media.path!, storage),
      mediaType: media.mediaType
    }))
  );
}

export function readableFileName(media: Media): string {
  const fileName = media.path.split("/").pop() || "";
  return fileName.substring(fileName.indexOf("-") + 1, fileName.length);
}
