



















import { Component, Vue } from "vue-property-decorator";
import CookieBanner from "@/components/CookieBanner.vue";

@Component({
  components: { CookieBanner }
})
export default class Footer extends Vue {
  get chineseExtraFooterInfo(): string {
    return "<pre><a href='https://beian.miit.gov.cn'>苏ICP备16062361号</a>     菲特（中国）制药科技有限公司</pre>";
  }
}
