




















































import { Component, Prop, Vue } from "vue-property-decorator";
import globalConfig from "@/globals/Configuration";
import LanguageSelect from "@/components/global/LanguageSelect.vue";

@Component({
  components: { LanguageSelect }
})
export default class Navigation extends Vue {
  get headerHeight(): number {
    return globalConfig.offsets.header;
  }

  navigationLinks = [
    { to: "/#control", name: "global.control" },
    { to: "/#guide", name: "global.guide" },
    { to: "/#learn", name: "global.learn" },
    { to: "/#contacts", name: "global.contact", cssClasses: "ml-9 font-weight-thin" }
  ];
}
