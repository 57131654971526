var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[(_vm.page)?[_c('v-card',{class:{ selected: _vm.isSelected },attrs:{"width":"200","outlined":""}},[(_vm.isMenuShown)?[_c('div',[_c('v-text-field',{attrs:{"autocomplete":"off"},model:{value:(_vm.decisionText),callback:function ($$v) {_vm.decisionText=$$v},expression:"decisionText"}})],1),_c('v-divider')]:_vm._e(),_c('v-card-title',{attrs:{"primary-title":""}},[_c('h6',{staticClass:"title m-0"},[_vm._v(_vm._s(_vm.page.pageNumber))])]),_c('v-card-text',[_c('p',{staticClass:"body-2 ellipsize"},[_vm._v(_vm._s(_vm.page.text))])]),_c('v-card-actions',[(_vm.isMenuShown)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","open-on-click":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"brand","absolute":"","left":"","icon":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3488746306)},[_c('v-list',[(_vm.canBeMarkedAs('finished'))?_c('v-list-item',{on:{"click":function($event){return _vm.changePageType('finished')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.mark-as-finished")))])],1):_vm._e(),(_vm.canBeMarkedAs('support'))?_c('v-list-item',{on:{"click":function($event){return _vm.changePageType('support')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.mark-as-support")))])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.remove}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.t("global.actions.delete")))])],1)],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"brand","absolute":"","right":"","dark":"","icon":""},on:{"click":function($event){return _vm.openPage(_vm.page.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,921506577)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.tooltip.edit-page-details")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"brand","dark":"","icon":""},on:{"click":_vm.addChild}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus-circle")])],1)]}}],null,false,737785060)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.tooltip.add-page")))])])],1)],2),(_vm.page.nextSteps.length > 0)?_c('ul',_vm._l((_vm.page.nextSteps),function(nextStep){return _c('SmartGuideEditorPageNode',{key:nextStep.pageId,attrs:{"page-type":_vm.pageTypeOfPage(nextStep.pageId),"parentPage":_vm.page,"page":_vm.pageById(nextStep.pageId)},on:{"open-page":_vm.openPage}})}),1):_vm._e()]:[_c('v-card',{attrs:{"width":"200","outlined":""}},[(_vm.parentPage)?[_c('div',[_c('v-text-field',{attrs:{"autocomplete":"off"},model:{value:(_vm.decisionText),callback:function ($$v) {_vm.decisionText=$$v},expression:"decisionText"}})],1),_c('v-divider')]:_vm._e(),_c('v-card-text',[(_vm.pageType === 'support')?[_c('v-icon',{attrs:{"large":"","dark":"","color":"brand"}},[_vm._v("mdi-phone")]),_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.pagetype.support")))])]:_vm._e(),(_vm.pageType === 'finished')?[_c('v-icon',{attrs:{"large":"","dark":"","color":"brand"}},[_vm._v("mdi-check-bold")]),_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.pagetype.finished")))])]:_vm._e()],2),_c('v-card-actions',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"brand","dark":"","icon":""},on:{"click":_vm.remove}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("product.smartguide.tooltip.delete-page")))])])],1)],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }