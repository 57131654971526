







import { Component, Vue } from "vue-property-decorator";
import UserOverview from "@/components/global/user/UserOverview.vue";
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/apps/Navigation.vue";
import Centered from "@/components/global/Centered.vue";

@Component({
  components: { UserOverview, Navigation, Footer, Centered }
})
export default class User extends Vue {}
