import globalConfig from "@/globals/Configuration";
import { subMinutes, format, toDate } from "date-fns";

export function timestampToReadableDate(ts: number): string {
  return format(toDate(ts), globalConfig.date.format);
}

export function subtractMinutesFromNow(minusMinutes: number): number {
  return subMinutes(Date.now(), minusMinutes).getTime();
}
