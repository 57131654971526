import { UserRole, UserRoleString } from "@/ui-models";

export function stringsToUserRoles(userRolesList: string[]): UserRole[] {
  const reverseEnumValues = Object.values(UserRole);
  const result = userRolesList
    .map((role) => reverseEnumValues.find((it) => it === role))
    .filter((maybeRole) => maybeRole !== undefined && maybeRole !== null);
  return result as UserRole[];
}

export function userRolesToStrings(userRolesList: string[]): UserRoleString[] {
  const enumEntries = Object.entries(UserRole);
  const result = userRolesList
    .map((role) => enumEntries.find(([, value]) => value === role)?.[0])
    .filter((maybeRole) => maybeRole !== undefined && maybeRole !== null);
  return result as UserRoleString[];
}

export function hasRole(currentRoles: string[], desiredRole: UserRole): boolean {
  return stringsToUserRoles(currentRoles).includes(desiredRole);
}
