














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Card extends Vue {
  @Prop()
  title!: string;
  @Prop()
  subTitle!: string;
}
