




















import { Component, Vue } from "vue-property-decorator";
import * as authnService from "@/services/authentication.service";
import Auth from "@aws-amplify/auth";
import { UserRole } from "@/ui-models";
import { stringsToUserRoles } from "@/services/user.ui-service";

@Component
export default class UserOverview extends Vue {
  tenant = "";
  jwt = "";
  user = "";
  subscriptions = "";
  cognitogroups: string[] = [];
  userRoles: UserRole[] = [];

  async mounted() {
    this.tenant = await authnService.getTenant();
    this.jwt = await authnService.getJwToken();
    this.user = await Auth.currentUserInfo();
    this.cognitogroups = await authnService.getUserGroups();
    this.subscriptions = (await Auth.currentSession()).getIdToken().payload["osdi:subscriptions"];
    this.userRoles = stringsToUserRoles(this.cognitogroups);
  }
}
