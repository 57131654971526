




import { Component, Vue, Prop } from "vue-property-decorator";
import globalConfig from "@/globals/Configuration";

@Component
export default class UserStatusIcon extends Vue {
  @Prop()
  userEnabled!: boolean;

  @Prop()
  colored!: boolean;

  get statusIcon(): string {
    return this.userEnabled ? "mdi-cloud-outline" : "mdi-cloud-off-outline";
  }

  get statusColor(): string {
    if (!this.colored) return "";
    return this.userEnabled ? globalConfig.colors.success : globalConfig.colors.warning;
  }
}
