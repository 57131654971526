









import BulletLineWidget from "@/components/apps/assetdetails/BulletLineWidget.vue";
import { RangeType } from "@/components/global/charts/BulletChart.vue";
import { ChartColors } from "@/components/global/charts/ChartColors";
import { SensorValue, StationData } from "@/models/asset";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    BulletLineWidget
  }
})
export default class MainMotorTemperature extends Vue {
  @Prop()
  temperature!: SensorValue;

  get mainMotorTemperature(): Partial<StationData<number>> {
    return {
      stationOne: this.temperature.actual
    };
  }

  get ranges(): RangeType[] {
    if (this.temperature.set) {
      return [
        {
          minValue: 0,
          maxValue: this.temperature.set,
          color: ChartColors.green,
          defaultCase: true
        },
        {
          minValue: this.temperature.set,
          maxValue: this.temperature.set + 10,
          color: ChartColors.red
        }
      ];
    } else {
      return [
        {
          minValue: 0,
          maxValue: this.temperature.actual,
          color: ChartColors.green,
          defaultCase: true
        }
      ];
    }
  }
}
