







import { BadgeModel, unitModeLut } from "@/services/asset.ui-service";
import ConditionModule from "@/store/modules/condition";
import { last } from "remeda";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
const conditionStore = getModule(ConditionModule);

@Component
export default class AssetModeBadge extends Vue {
  @Prop()
  private assetId!: string;

  @Prop()
  private isConnected!: boolean;

  get assetStateMode(): number {
    const noRemoteAssetStateMode = -1;
    if (!this.assetId || !this.isConnected) {
      return noRemoteAssetStateMode;
    }

    return (
      last(conditionStore.latestAssetStates?.map(({ DP0027 }) => DP0027)) ?? noRemoteAssetStateMode
    );
  }

  get mode(): BadgeModel {
    return unitModeLut[this.assetStateMode] ?? "";
  }
}
