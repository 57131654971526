




















import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/apps/Header.vue";
import SmartCollegeCard from "@/components/apps/smartcollege/SmartCollegeCard.vue";
import { getModule } from "vuex-module-decorators";
import SmartCollegeStore from "@/components/apps/smartcollege/smartcollege-module";
import { Course, Lesson, Module } from "@/components/apps/smartcollege/smart-college";
import { BreadcrumbItem } from "@/components/apps/NavigationBreadcrumb.vue";

const smartCollegeStore = getModule(SmartCollegeStore);

@Component({
  components: { Header, SmartCollegeCard }
})
export default class ModuleView extends Vue {
  private breadcrumbItems: BreadcrumbItem[] = [
    { text: "product.smartcollege", disabled: false, to: { name: "CourseList" }, exact: true }
  ];

  mounted() {
    const currentCourse = smartCollegeStore.currentCourse;
    const moduleId = this.$route.params.moduleId;
    const currentModule = currentCourse?.modules?.find((module) => module.id === moduleId);

    this.updateBreadcrumbs(currentCourse, currentModule);

    this.lessons = currentModule?.lessons;
  }

  private updateBreadcrumbs(currentCourse?: Course, currentModule?: Module) {
    this.breadcrumbItems.push(
      {
        text: currentCourse!.name,
        disabled: false,
        to: { name: "Course", params: { courseId: currentCourse!.id } },
        exact: true
      },
      { text: currentModule!.name, disabled: true }
    );
  }

  private lessons: Lesson[] | undefined = [];
}
