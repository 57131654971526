import { AssetDocument, AssetDocumentInput } from "@/models/asset";
import { assetManagerClient } from "@/plugins/graphql";
import * as queries from "@/graphql/assetmanager/queries";
import * as mutations from "@/graphql/assetmanager/mutations";

export interface DocumentsService {
  fetchDocuments(assetId: string): Promise<AssetDocument[]>;
  createDocument(assetDocument: AssetDocumentInput): Promise<AssetDocument>;
  removeDocument(assetId: string, id: string): Promise<string>;
}

export class RemoteDocumentsService implements DocumentsService {
  async fetchDocuments(assetId: string): Promise<AssetDocument[]> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getDocuments,
      variables: { assetId }
    });

    const { data } = gqlResult;
    return data.getDocuments;
  }

  async createDocument(assetDocument: AssetDocumentInput): Promise<AssetDocument> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addDocument,
      variables: { documentInput: assetDocument }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "addDocument") as AssetDocument;
  }

  private resultOrThrow(
    data: mutations.Mutation | null | undefined,
    operation: keyof mutations.Mutation
  ) {
    const operationType = data?.[operation];
    if (!operationType) {
      throw new Error("Empty response");
    }
    return operationType;
  }

  async removeDocument(assetId: string, id: string): Promise<string> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.removeDocument,
      variables: { assetId, id }
    });

    const { data } = gqlResult;
    return (this.resultOrThrow(data, "removeDocument") as { id: string })?.id;
  }
}
export default new RemoteDocumentsService();
