import { AssetsDTO } from "@/graphql/assetmanager/queries";
import { Asset, Identifiable, AssetInput } from "@/models/asset";
import { FilterModel } from "@/ui-models";
import globalConfig from "@/globals/Configuration";

export interface BadgeModel {
  name: string;
  color: string;
}
export interface AssetStateLut {
  [key: string]: BadgeModel;
}

export const UNDEFINED_STATE_COLOR: BadgeModel = { name: "unknown", color: "#FFE0B2" };

export const unitModeLut: AssetStateLut = {
  "-1": {
    name: "product.pam.asset.unit-mode.outdated",
    color: globalConfig.colors.assetStateOutdated
  },
  "0": {
    name: "product.pam.asset.unit-mode.computer-run",
    color: globalConfig.colors.unitModeComputerRun
  },
  "1": {
    name: "product.pam.asset.unit-mode.test-run",
    color: globalConfig.colors.unitModeTestRun
  },
  "2": {
    name: "product.pam.asset.unit-mode.setup-run",
    color: globalConfig.colors.unitModeSetupRun
  },
  "3": {
    name: "product.pam.asset.unit-mode.standard-run",
    color: globalConfig.colors.unitModeStandardRun
  }
};

export const assetStateLut: AssetStateLut = {
  "-1": {
    name: "product.pam.asset.state.outdated",
    color: globalConfig.colors.assetStateOutdated
  },
  "0": {
    name: "product.pam.asset.state.uncertain",
    color: globalConfig.colors.assetStateUncertain
  },
  "1": {
    name: "product.pam.asset.state.idle",
    color: globalConfig.colors.assetStateIdle
  },
  "2": {
    name: "product.pam.asset.state.running",
    color: globalConfig.colors.assetStateRunning
  },
  "3": {
    name: "product.pam.asset.state.held",
    color: globalConfig.colors.assetStateHeld
  },
  "4": {
    name: "product.pam.asset.state.paused",
    color: globalConfig.colors.assetStatePaused
  },
  "5": {
    name: "product.pam.asset.state.complete",
    color: globalConfig.colors.assetStateComplete
  },
  "6": {
    name: "product.pam.asset.state.wash",
    color: globalConfig.colors.assetStateWash
  }
};

export function filterAssets(assets: Asset[], filter: FilterModel = new FilterModel()): Asset[] {
  if (Array.isArray(assets) === false || assets.length < 1) {
    return [];
  }

  if (filter.isActive() === false) {
    return assets;
  }

  return assets.filter((asset) => {
    const filterBySite = asset.site?.id === filter.siteId;
    const filterByAssetType = asset.type?.id === filter.assetTypeId;

    let filterByProcessCell = false;
    if (filter.processCellId !== "") {
      filterByProcessCell = asset.processCell?.id === filter.processCellId;
    }

    let filterByFreeText = false;
    if (filter.freeText) {
      const { name, serialNumber } = asset;
      filterByFreeText =
        [name, serialNumber, asset.type.name]
          .filter((it) => it !== undefined)
          .find((it) => it?.toLowerCase().includes(filter.freeText.toLowerCase())) !== undefined;
    }

    return filterBySite || filterByProcessCell || filterByAssetType || filterByFreeText;
  });
}

export function stichAssets(assetsDTO: AssetsDTO): Asset[] {
  if (!assetsDTO || !assetsDTO.getAssets) {
    return [];
  }

  type LUTAccumulator<T> = { [key: string]: T };

  const siteLUT = assetsDTO.getSites.reduce((acc, it) => {
    acc[it.id] = it;
    return acc;
  }, {} as LUTAccumulator<Identifiable>);

  const processCellLUT = assetsDTO.getProcessCells.reduce((acc, it) => {
    acc[it.id] = it;
    return acc;
  }, {} as LUTAccumulator<Identifiable>);

  const assetTypeLUT = assetsDTO.getAssetTypes.reduce((acc, it) => {
    acc[it.id] = it;
    return acc;
  }, {} as LUTAccumulator<Identifiable>);

  return assetsDTO.getAssets.map((it) => {
    const result: any = {};
    if (it.processCellId) {
      result.processCell = processCellLUT[it.processCellId];
    }
    result.type = assetTypeLUT[it.typeId];
    result.site = siteLUT[it.siteId];
    return { ...it, ...result };
  });
}

export function dismantleAsset(asset: Asset): AssetInput {
  const result: any = { ...asset };
  if (result.tenant) {
    delete result.tenant;
  }
  if (result.assetId) {
    delete result.assetId;
  }
  if (result.processCell) {
    result.processCellId = result.processCell.id;
    delete result["processCell"];
  }
  result.typeId = result.type.id;
  delete result["type"];
  result.siteId = result.site.id;
  delete result["site"];

  if (result.contactPerson) {
    result.contactPersonId = result.contactPerson.id;
    delete result["contactPerson"];
  }
  return result;
}
