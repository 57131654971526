import * as mutations from "@/graphql/assetmanager/mutations";
import * as queries from "@/graphql/assetmanager/queries";
import {
  Asset,
  AssetDTO,
  AssetKey,
  AssetType,
  Contact,
  ContactInput,
  ProcessCell,
  Site
} from "@/models/asset";
import { assetManagerClient } from "@/plugins/graphql";
import * as uiService from "@/services/asset.ui-service";
import { RemoteStatusService } from "@/services/remote-status.service";

export interface AssetService {
  fetchAssetTypes(): Promise<AssetType[]>;
  fetchSites(): Promise<Site[]>;
  fetchProcessCells(): Promise<ProcessCell[]>;
  fetchProcessCellNames(): Promise<Partial<ProcessCell>[]>;
  fetchAssets(): Promise<Asset[]>;
  fetchAssetsInProcessCell(processCellId: string): Promise<Asset[]>;
  fetchAsset(assetId: string): Promise<Asset>;
  fetchContactPersons(): Promise<Contact[]>;
  createAsset(asset: Asset): Promise<Asset>;
  updateAsset(asset: Asset): Promise<Asset>;
  removeAsset(assetId: string): Promise<AssetKey>;
  createAssetType(name: string, manufacturer: string, image?: string): Promise<AssetType>;
  updateAssetType(
    id: string,
    name: string,
    manufacturer: string,
    image?: string
  ): Promise<AssetType>;
  createProcessCell(
    siteId: string,
    name: string,
    description?: string
  ): Promise<ProcessCell | undefined>;
  createSite(theNewSite: Omit<Site, "id">): Promise<Site | undefined>;
  createContactPerson(contactInput2: ContactInput): Promise<Contact>;
  updateProcessCell(
    id: string,
    siteId: string,
    name: string,
    description: string
  ): Promise<ProcessCell>;
  updateContactPerson(contact: Contact): Promise<Contact>;
}

export class RemoteAssetService implements AssetService {
  private readonly statusService = new RemoteStatusService();

  async createSite(theNewSite: Omit<Site, "id">): Promise<Site> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addSite,
      variables: { newSite: theNewSite }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "addSite") as Site;
  }

  async fetchProcessCellNames(): Promise<Partial<ProcessCell>[]> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getProcessCellNames
    });

    const { data } = gqlResult;
    return data.getProcessCells;
  }

  async createProcessCell(
    siteId: string,
    name: string,
    description?: string
  ): Promise<ProcessCell | undefined> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addProcessCell,
      variables: { siteId, name, description }
    });

    const { data } = gqlResult;
    return data?.addProcessCell;
  }

  async fetchProcessCells(): Promise<ProcessCell[]> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getProcessCells
    });

    const { data } = gqlResult;
    return data.getProcessCells;
  }

  async fetchAssetsInProcessCell(processCellId: string): Promise<Asset[]> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getAssetsInProcessCell,
      variables: { processCellId }
    });

    const { data } = gqlResult;
    const assets = data.getAssetsInProcessCell;
    const assetTypes = await this.fetchAssetTypes();
    assets.forEach((it) => {
      it.type = assetTypes.find((type) => type.id === (it as any).typeId)!;
    });
    return assets;
  }

  async createContactPerson(contactInput: ContactInput): Promise<Contact> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addContactPerson,
      variables: { contact: contactInput }
    });
    const { data } = gqlResult;
    return this.resultOrThrow(data, "addContactPerson") as Contact;
  }

  async fetchContactPersons(): Promise<Contact[]> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getContactPersons
    });

    const { data } = gqlResult;
    return data.getContactPersons;
  }

  async createAssetType(name: string, manufacturer: string, image?: string): Promise<AssetType> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addAssetType,
      variables: { name, image: image || null, manufacturer }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "addAssetType") as AssetType;
  }

  async updateAssetType(
    id: string,
    name: string,
    manufacturer: string,
    image?: string
  ): Promise<AssetType> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.updateAssetType,
      variables: { id, name, manufacturer, image }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "updateAssetType") as AssetType;
  }

  private resultOrThrow(
    data: mutations.Mutation | null | undefined,
    operation: keyof mutations.Mutation
  ) {
    const operationType = data?.[operation];
    if (!operationType) {
      throw new Error("Empty response");
    }
    return operationType;
  }

  async createAsset(asset: Asset): Promise<Asset> {
    const assetDTO = uiService.dismantleAsset(asset);
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.addAsset,
      variables: { asset: assetDTO }
    });

    const { data } = gqlResult;
    const addedAsset = this.resultOrThrow(data, "updateAssetType") as Asset;

    return { ...asset, tenant: addedAsset.tenant, assetId: addedAsset.assetId };
  }

  async updateAsset(asset: Asset): Promise<Asset> {
    const assetDTO = uiService.dismantleAsset(asset);
    await assetManagerClient.mutate<AssetDTO>({
      mutation: mutations.updateAsset,
      variables: { assetId: asset.assetId, asset: assetDTO }
    });

    return { ...asset };
  }

  async removeAsset(assetId: string): Promise<AssetKey> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.removeAsset,
      variables: { assetId }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "removeAsset") as AssetKey;
  }

  fetchSites(): Promise<Site[]> {
    return assetManagerClient
      .query<queries.Query>({
        query: queries.getSites
      })
      .then(({ data }) => data.getSites);
  }

  fetchAssetTypes(): Promise<AssetType[]> {
    return assetManagerClient
      .query<queries.Query>({
        query: queries.getAssetTypes
      })
      .then(({ data }) => data.getAssetTypes);
  }

  fetchAssets(): Promise<Asset[]> {
    this.statusService.beforeRemoteCall();
    return assetManagerClient
      .query<queries.Query>({
        query: queries.getAssetsWithReferences,
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => {
        return uiService.stichAssets((data as unknown) as queries.AssetsDTO);
      })
      .finally(this.statusService.afterRemoteCall);
  }

  async fetchAsset(assetId: string): Promise<Asset> {
    const gqlResult = await assetManagerClient.query<queries.Query>({
      query: queries.getAsset,
      variables: { assetId }
    });

    const { data } = gqlResult;
    const getAsset = data?.getAsset;

    const assetTypes = await this.fetchAssetTypes();
    const type = assetTypes.find((assetType) => assetType.id === getAsset.typeId);

    const sites = await this.fetchSites();
    const site = sites.find((site) => site.id === getAsset.siteId);

    let processCell = undefined;
    if (getAsset.processCellId) {
      const processCells = await this.fetchProcessCells();
      processCell = processCells.find((cell) => cell.id === getAsset.processCellId);
    }

    let contactPerson = undefined;
    if (getAsset.contactPersonId) {
      const contactPersons = await this.fetchContactPersons();
      contactPerson = contactPersons.find((contact) => contact.id === getAsset.contactPersonId);
    }
    return { ...getAsset, type, site, processCell, contactPerson } as Asset;
  }

  async updateProcessCell(
    id: string,
    siteId: string,
    name: string,
    description: string
  ): Promise<ProcessCell> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.updateProcessCell,
      variables: { id, siteId, name, description }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "addProcessCell") as ProcessCell;
  }

  async updateContactPerson(contact: Contact): Promise<Contact> {
    const gqlResult = await assetManagerClient.mutate<mutations.Mutation>({
      mutation: mutations.updateContactPerson,
      variables: { contact }
    });

    const { data } = gqlResult;
    return this.resultOrThrow(data, "addContactPerson") as Contact;
  }
}

export default new RemoteAssetService();
