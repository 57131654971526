import globalConfig from "@/globals/Configuration";
import { Site } from "@/models/asset";
export class FilterTuple {
  constructor(public readonly name: string, public readonly id: string) {}
}

export class FilterModel {
  constructor(
    public siteId = "",
    public processCellId = "",
    public assetTypeId = "",
    public freeText = ""
  ) {}

  isActive(): boolean {
    return this.siteId + this.processCellId + this.assetTypeId + this.freeText !== "";
  }
}

export class SmartGuideFilterModel {
  constructor(
    public assetTypeId: string | undefined = undefined,
    public freeText: string | undefined = undefined
  ) {}

  isActive(): boolean {
    return this.assetTypeId !== undefined || this.freeText !== undefined;
  }
}

export type UserNotificationType = "error" | "warning" | "success" | "info";
export class UserNotification {
  message = "";
  type: UserNotificationType = "info";
  timeout? = globalConfig.notificationTimeout;
}

export interface WidgetData {
  labels: (number | string)[];
  series: WidgetDataSeries[];
}

export interface WidgetDataSeries {
  name: string;
  legendName?: string;
  unit?: string;
  data: (number | string | null | undefined)[];
}

export interface TableDataHeader {
  text: string;
  align?: "start" | "end";
  value: string;
}
export interface WidgetTableData {
  labels: TableDataHeader[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series: any[];
}

export interface SiteWithAssetsCount extends Site {
  count: number;
}

export enum MachineStation {
  ONE = 1,
  TWO = 2
}

export type UserStatus =
  | "UNCONFIRMED"
  | "CONFIRMED"
  | "ARCHIVED"
  | "COMPROMISED"
  | "UNKNOWN"
  | "RESET_REQUIRED"
  | "FORCE_CHANGE_PASSWORD";

export interface User {
  username: string;
  email: string;
  status: UserStatus;
  enabled: boolean;
}

export enum UserRole {
  ADMINISTRATOR = "role:Administrator",
  MAINTENANCE = "role:Maintenance",
  USER = "role:User"
}
export type UserRoleString = keyof typeof UserRole;

export interface FullUser extends User {
  groups: string[];
}

export interface PaginatedUsers {
  users: User[];
  nextToken?: string;
}

export interface DescriptionSectionModel {
  title: string;
  text: string;
  image?: string;
  containImage?: boolean;
  optionalFooter?: string[];
}
