
























import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Site } from "@/models/asset";

@Component({
  components: { LMap, LTileLayer, LMarker, LTooltip }
})
export default class SiteMap extends Vue {
  @Prop()
  site!: Site;

  private zoom = 7;

  private tileProvider = {
    name: "OpenStreetMap",
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png"
  };

  private tooltipOptions = {
    permanent: true,
    direction: "right"
  };
}
