import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { getCourses } from "@/components/apps/smartcollege/smartcollege-service";
import { Course } from "@/components/apps/smartcollege/smart-college";

@Module({ dynamic: true, store, name: "smartcollege" })
export default class SmartCollegeModule extends VuexModule {
  currentCourseId?: string;

  get courses() {
    return getCourses();
  }

  get currentCourse(): Course | undefined {
    return getCourses().find((it) => it.id === this.currentCourseId);
  }

  @Mutation
  setCurrentCourse(id: string) {
    this.currentCourseId = id;
  }
}
