






































import { Component, Vue, Prop } from "vue-property-decorator";
import LineChart from "@/components/global/charts/LineChart.vue";
import { MachineStation, WidgetData } from "@/ui-models";
import ChartOptions from "@/components/apps/assetdetails/widgets/configurations/ChartOptions";
import TwoRowWidget from "@/components/apps/assetdetails/TwoRowWidget.vue";
import { WidgetDataMapper } from "./mapping/WidgetDataMapper";

@Component({
  components: { TwoRowWidget, LineChart }
})
export default class TabletThicknessWidget extends Vue {
  @Prop()
  widgetData!: WidgetDataMapper;

  station = this.stationOneToggle;

  get stationOneToggle() {
    return MachineStation.ONE;
  }
  get stationTwoToggle() {
    return MachineStation.TWO;
  }

  get stationTogglerValue() {
    return `${this.$i18n.t(
      "product.pam.asset.conditions.widget.tablet-thickness.station"
    )} ${this.station.toString()}`;
  }

  get tabletThicknessStationData(): WidgetData {
    return this.widgetData.tabletThickness(this.station);
  }
  get tabletThicknessDeviationData(): WidgetData {
    return this.widgetData.tabletThicknessDeviation(this.station);
  }

  private get chartOptions() {
    return new ChartOptions(this.$i18n);
  }
}
