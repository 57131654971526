


















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class OpenDialog extends Vue {
  @Prop()
  overlay!: boolean;

  @Emit()
  close() {
    return true;
  }
}
