import { render, staticRenderFns } from "./VRCampus.vue?vue&type=template&id=19ec5015&scoped=true&"
import script from "./VRCampus.vue?vue&type=script&lang=ts&"
export * from "./VRCampus.vue?vue&type=script&lang=ts&"
import style0 from "./VRCampus.vue?vue&type=style&index=0&id=19ec5015&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ec5015",
  null
  
)

export default component.exports