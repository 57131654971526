


























import type { User } from "@/ui-models";
import { Component, Prop, Vue } from "vue-property-decorator";
import userManagementService from "@remote-api/user-management.service";
import { notification } from "@/store/store.ui-service";

@Component
export default class UserResetPassword extends Vue {
  @Prop()
  user!: User;
  dialog = false;

  async resetPassword() {
    try {
      await userManagementService.resetUserPassword(this.user.username);
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-reset-password.success")),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-reset-password.failure")),
        type: "error"
      });
    } finally {
      this.dialog = false;
    }
  }
}
