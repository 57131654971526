import awsconfig from "@/configs/aws-exports";
import globalConfig from "@/globals/Configuration";
import { getJwToken } from "@/services/authentication.service";
import Auth from "@aws-amplify/auth";
import AWSAppSyncClient, { AUTH_TYPE, AWSAppSyncClientOptions } from "aws-appsync";

function configureClient(apiUrl: string): AWSAppSyncClientOptions {
  return {
    url: apiUrl,
    region: awsconfig.awsAppsyncRegion,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => getJwToken()
    },
    cacheOptions: {
      addTypename: false
    },
    disableOffline: true
  };
}

export const assetManagerClient = new AWSAppSyncClient(
  configureClient(globalConfig.remoteApi.API_ASSETMANAGER_URL)
);
export const conditionMonitorClient = new AWSAppSyncClient(
  configureClient(globalConfig.remoteApi.API_CONDITIONMONITOR_URL)
);
export const smartGuideClient = new AWSAppSyncClient(
  configureClient(globalConfig.remoteApi.API_SMARTGUIDE_URL)
);
export const userManagementClient = new AWSAppSyncClient(
  configureClient(globalConfig.remoteApi.API_USER_MANAGEMENT_URL)
);
export const liveguideClient = new AWSAppSyncClient(
  Object.assign({}, configureClient(globalConfig.remoteApi.API_LIVEGUIDE_URL), {
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => Auth.currentCredentials()
    }
  })
);
