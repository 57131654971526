
















import { Component, Vue, Prop } from "vue-property-decorator";
import { WidgetTableData } from "@/ui-models";

@Component({
  components: {}
})
export default class TimelineWidget extends Vue {
  @Prop()
  title!: string;

  @Prop()
  data!: WidgetTableData;

  get sortBy() {
    if (!this.data) {
      return "name";
    }
    return this.data.labels[0]?.value;
  }
}
