




















































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SmartGuideEditorModule from "@/store/modules/smartguide-editor";
import { SmartGuidePage, SmartGuidePageType } from "@/models/smartguide";

const smartGuideEditorStore = getModule(SmartGuideEditorModule);

@Component({
  name: "SmartGuideEditorPageNode"
})
export default class SmartGuideEditorPageNode extends Vue {
  @Prop()
  pageType!: SmartGuidePageType;

  @Prop()
  page!: SmartGuidePage;

  @Prop()
  parentPage!: SmartGuidePage;

  get isSelected() {
    return this.page.id === smartGuideEditorStore?.currentPage?.id;
  }

  get isMenuShown() {
    return this.parentPage && this.page.nextSteps.length === 0;
  }

  get decisionText(): string {
    switch (this.pageType) {
      case "regular":
        if (this.parentPage) {
          return smartGuideEditorStore.decisionTextForPage(this.page.id, this.parentPage.id);
        }
        break;
      case "support":
      case "finished":
        return smartGuideEditorStore.decisionTextForPage(this.pageType, this.parentPage.id);
    }

    return "";
  }

  set decisionText(text: string) {
    const pageId = this.pageType === "regular" ? this.page.id : this.pageType;

    smartGuideEditorStore.updateDecisionText({
      parentPageId: this.parentPage.id,
      pageId,
      text
    });
  }

  canBeMarkedAs(type: "support" | "finished") {
    if (!this.parentPage) {
      return false;
    }
    return !this.parentPage.nextSteps.find((step) => step.pageId === type);
  }

  pageById(id: string): SmartGuidePage | undefined {
    if (id === "support" || id === "finished") {
      return undefined;
    }
    return smartGuideEditorStore.pageById(id);
  }

  pageTypeOfPage(id: string): SmartGuidePageType {
    if (id === "support" || id === "finished") {
      return id;
    } else {
      return "regular";
    }
  }

  addChild() {
    smartGuideEditorStore.insertPageAfter(this.page);
  }

  remove() {
    smartGuideEditorStore.removePage({
      pageId: this.pageType === "regular" ? this.page.id : this.pageType,
      parentPageId: this.parentPage.id
    });
  }

  changePageType(newType: "support" | "finished") {
    if (this.page && this.pageType === "regular") {
      smartGuideEditorStore.changePageType({
        pageId: this.page.id,
        newType
      });
    }
  }

  @Emit()
  openPage() {
    this.$emit("openPage", this.page.id);
  }
}
