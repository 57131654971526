









import { Component, Prop, Vue } from "vue-property-decorator";
import BulletLineWidget from "@/components/apps/assetdetails/BulletLineWidget.vue";
import { RangeType } from "@/components/global/charts/BulletChart.vue";
import { StationData } from "@/models/asset";
import { last } from "remeda";
import { ChartColors } from "@/components/global/charts/ChartColors";

@Component({
  components: {
    BulletLineWidget
  }
})
export default class EjectionForce extends Vue {
  @Prop()
  widgetData!: StationData<number[]>;

  get ejectionForce(): StationData<number> {
    return {
      stationOne: last(this.widgetData?.stationOne) ?? 0,
      stationTwo: last(this.widgetData?.stationTwo) ?? 0
    };
  }

  ranges: RangeType[] = [
    {
      minValue: 0,
      maxValue: 500,
      color: ChartColors.green
    },
    {
      minValue: 501,
      maxValue: 1000,
      color: ChartColors.yellow
    },
    {
      minValue: 1001,
      maxValue: 5000,
      color: ChartColors.red
    }
  ];
}
