import { ChartColors } from "@/components/global/charts/ChartColors";
import configuration from "@/globals/Configuration";
import { ApexOptions } from "apexcharts";
import type { IVueI18n } from "vue-i18n";

export function tabletThicknessOptions(i18nService: IVueI18n): ApexOptions {
  return {
    chart: {
      type: "line",
      group: "tabletThickness",
      id: `${Math.random()}-tabletThickness`,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: [
      ChartColors.blue,
      ChartColors.blueLight,
      ChartColors.red,
      ChartColors.orange,
      ChartColors.orange,
      ChartColors.red
    ],
    dataLabels: {
      enabled: false,
      enabledOnSeries: [0]
    },
    stroke: {
      width: [2, 3, 3, 2, 3, 3],
      curve: "straight"
    },
    grid: {
      show: true
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        format: configuration.chart.dateFormat,
        datetimeUTC: false
      }
    },
    yaxis: {
      title: {
        text: i18nService.t("product.pam.asset.conditions.widget.tablet-thickness.title"),
        style: {}
      },
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      },
      labels: {
        show: true
      }
    },
    legend: {
      show: true
    },
    tooltip: { x: { format: "yyyy-MM-dd HH:mm" }, enabledOnSeries: [0] }
  } as ApexOptions;
}

export function tabletThicknessDeviation(i18nService: IVueI18n): ApexOptions {
  return Object.assign({}, tabletThicknessOptions(i18nService), {
    yaxis: {
      title: {
        text: i18nService.t("product.pam.asset.conditions.widget.tablet-thickness.title"),
        style: {}
      },
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      },
      labels: {
        show: true
      }
    }
  }) as ApexOptions;
}
