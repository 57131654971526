









































import { StationData } from "@/models/asset";
import { Component, Vue, Prop } from "vue-property-decorator";
import { hasSecondStation } from "@/services/condition.ui-service";
import { ChartColors } from "./ChartColors";
export interface RangeType {
  minValue: number;
  maxValue: number;
  color: string;
  defaultCase?: boolean;
}

export class DefaultRange implements RangeType {
  minValue = 0;
  maxValue = Number.MAX_SAFE_INTEGER;
  color = ChartColors.green;
  defaultCase = true;
}

@Component
export default class BulletChart extends Vue {
  @Prop({
    default: () => {
      return { stationOne: 0, stationTwo: undefined };
    }
  })
  currentValue!: StationData<number>;
  @Prop({ default: () => [new DefaultRange()] }) ranges!: RangeType[];
  @Prop({ default: false }) pointerMark!: boolean;
  @Prop({ default: true }) pointer!: boolean;
  @Prop({ default: true }) fullGridHeight!: boolean;

  get max(): number {
    const maximumValuesofArray = this.ranges.map((x) => x.maxValue);
    return Math.max(...maximumValuesofArray);
  }
  get min(): number {
    const minimumValuesofArray = this.ranges.map((x) => x.minValue);
    return Math.min(...minimumValuesofArray);
  }
  get totalValue(): number {
    return this.max - this.min;
  }
  pointerPosition(value: number): number {
    if (value < this.min) return 0;
    if (value > this.max) return 100;
    return ((value - this.min) / this.totalValue) * 100;
  }

  pointerStyle(value: number): any {
    return {
      left: `${this.pointerPosition(value)}%`
    };
  }
  pointerTriangleStyle(value: number): any {
    return {
      left: `${this.pointerPosition(value)}%`
    };
  }

  doesHaveSecondStation = hasSecondStation;

  get defaultRange(): RangeType | undefined {
    return this.ranges.find((range) => range.defaultCase);
  }

  get inDefaultRange(): boolean {
    const currentValue = this.currentValue.stationOne;
    const defaultRange = this.defaultRange;
    if (!defaultRange) return false;
    return defaultRange.minValue < currentValue && defaultRange.maxValue >= currentValue;
  }

  defaultBoxStyle(): any {
    const range = this.defaultRange;
    const color = range?.color ?? ChartColors.green;
    const weight = 100;
    return {
      background: `linear-gradient(to right, ${color} ${weight}%, ${color})`,
      width: `${weight}%`
    };
  }

  boxStyle(range: RangeType, index: number): any {
    const nextRange = this.ranges[index + 1];
    const gradientColor = nextRange?.color ?? range.color;
    const weight = ((range.maxValue - range.minValue) / this.totalValue) * 100;
    return {
      background: `linear-gradient(to right, ${range.color} ${weight}%, ${gradientColor})`,
      width: `${weight}%`
    };
  }
}
