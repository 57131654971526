























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { SmartGuidePage, SmartGuide } from "@/models/smartguide";
import PageDetailsEditor from "@/components/apps/smartguide/PageDetailsEditor.vue";
import GuideDetailsEditor from "@/components/apps/smartguide/GuideDetailsEditor.vue";

@Component({
  components: { PageDetailsEditor, GuideDetailsEditor }
})
export default class EditorDrawer extends Vue {
  private _overlay = true;

  @Prop()
  page!: SmartGuidePage | null;

  @Prop()
  uploading!: boolean;

  @Prop()
  guide!: SmartGuide | null;

  get overlay() {
    return this._overlay || true;
  }

  set overlay(newOverlay: boolean) {
    this._overlay = newOverlay;
    if (newOverlay === false) {
      this.close();
    }
  }

  @Emit()
  close() {
    return true;
  }

  @Emit()
  savePageChanges(page: SmartGuidePage) {
    this.close();
    return page;
  }

  @Emit()
  saveGuideChanges(guide: SmartGuide) {
    this.close();
    return guide;
  }

  @Emit()
  uploadPageMedia(filesToUpload: File[]) {
    return filesToUpload;
  }
}
