





























import { Component, Prop } from "vue-property-decorator";
import CreateEntityFormCard from "./CreateEntityFormCard.vue";
@Component
export default class CreateEntityFormOverlay extends CreateEntityFormCard {
  @Prop()
  icon!: string;

  @Prop()
  withSave!: string;

  @Prop()
  withCancel!: string;
}
