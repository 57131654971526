








import { Component, Vue } from "vue-property-decorator";
import smartGuideService from "@remote-api/smartguide.service";
import SmartGuidePlayer from "@/components/apps/smartguide/SmartGuidePlayer.vue";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import { SmartGuide } from "@/models/smartguide";

@Component({
  components: { OpenDialog, SmartGuidePlayer }
})
export default class SmartGuideDetails extends Vue {
  private smartGuide: SmartGuide | null = null;

  get currentSmartGuide() {
    return this.smartGuide;
  }

  closeDialog() {
    // TODO: reliably go to smartguide overview
    this.$router.back();
  }

  async mounted() {
    const smartGuide = await smartGuideService.fetchSmartGuide(this.$route.params.smartGuideId);
    this.smartGuide = smartGuide || null;
  }
}
