






























import { Component, Prop, Vue } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
import conditionService from "@remote-api/condition.service";

@Component
export default class PressureWidgetV2 extends Vue {
  @Prop()
  assetId!: string;
  pressureDataSeries: any[][] = [];
  timeWindow = 0;
  timeRange = 1;
  aggregateFunction = 0;
  readonly timeRangesInHours = [1, 2, 5, 10, 24, 48];
  readonly timeWindows = ["15s", "1m", "15m", "30m"];
  readonly aggregateFunctions = ["mean", "median", "last"];

  get selectedTimeRange(): number {
    return this.timeRangesInHours[this.timeRange ?? 0];
  }

  get selectedTimeWindow(): string {
    return this.timeWindows[this.timeWindow ?? 0];
  }
  get selectedAggregateFunction(): string {
    return this.aggregateFunctions[this.aggregateFunction ?? 0];
  }

  async mounted(): Promise<void> {
    this.fetchRemoteData();
  }

  async fetchRemoteData(): Promise<void> {
    this.pressureDataSeries = await conditionService.fetchPressureV2(
      this.assetId,
      this.selectedTimeRange,
      this.selectedTimeWindow,
      this.selectedAggregateFunction
    );
  }

  async onTimeConditionChange(): Promise<void> {
    await this.fetchRemoteData();
  }

  get series(): any {
    return [
      {
        name: "Pressure",
        data: this.pressureDataSeries
      }
    ];
  }

  get options(): ApexOptions {
    return {
      chart: {
        type: "area"
      },
      xaxis: {
        type: "datetime"
      }
    };
  }
}
