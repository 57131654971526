import { render, staticRenderFns } from "./UserAdd.vue?vue&type=template&id=4635fa92&"
import script from "./UserAdd.vue?vue&type=script&lang=ts&"
export * from "./UserAdd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VTextField})
