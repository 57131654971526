




























import { Component, Vue, Prop } from "vue-property-decorator";
import AssetStateBadge from "@/components/apps/assetmanager/AssetStateBadge.vue";
import { Asset as AssetModel, AssetSummaryState } from "@/models/asset";
import { constructCustomerAssetUrl } from "@/services/static-assets.service";
import conditionService from "@remote-api/condition.service";
import logger from "@/plugins/logger";

@Component({
  components: {
    AssetStateBadge
  }
})
export default class Asset extends Vue {
  @Prop()
  private asset!: AssetModel;

  private assetSummaryState: AssetSummaryState = {} as AssetSummaryState;
  private s3ImageUrl = "";

  async mounted() {
    if (this.asset) {
      if (this.asset.isConnected) {
        try {
          this.assetSummaryState = await conditionService.fetchAssetSummaryState(
            this.asset.assetId
          );
        } catch (error) {
          logger.error(
            `Could not load the asset summary state for asset: ${this.asset.assetId}`,
            error
          );
        }
      }
      const { image } = this.asset.type;
      this.s3ImageUrl = await constructCustomerAssetUrl(image);
    }
  }
}
