import { AuthenticationState } from "@/store/modules/auth";

export class AuthenticatorDisplayMap {
  showLogIn(authenticationState: AuthenticationState): boolean {
    return authenticationState === "signedOut" || authenticationState === "signIn";
  }
  showRequireNewPassword(authenticationState: AuthenticationState): boolean {
    return authenticationState === "requireNewPassword";
  }
  showForgotPassword(authenticationState: AuthenticationState): boolean {
    return authenticationState === "forgotPassword";
  }
}

export default new AuthenticatorDisplayMap();
