










































import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import { ChartColors } from "@/components/global/charts/ChartColors";
import { PunchStiffness as PunchStiffnessModel } from "@/models/asset";
import { Component, Prop, Vue } from "vue-property-decorator";
import BulletChart, { RangeType } from "@/components/global/charts/BulletChart.vue";
@Component({
  components: { BulletChart, WidgetCard }
})
export default class PunchStiffness extends Vue {
  @Prop({
    default: (): PunchStiffnessModel => {
      return { upper: 0, lower: 0 };
    }
  })
  stiffness!: PunchStiffnessModel;

  ranges: RangeType[] = [
    {
      minValue: 0,
      maxValue: 200,
      color: ChartColors.green,
      defaultCase: true
    },
    {
      minValue: 201,
      maxValue: 500,
      color: ChartColors.yellow
    },
    {
      minValue: 501,
      maxValue: 2500,
      color: ChartColors.red
    }
  ];

  toKilo(measurement = 0): number {
    return measurement / 1000;
  }
}
