








import { Component, Vue, Prop } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Authenticator from "@/components/global/authentication/Authenticator.vue";
import Navigation from "@/components/Navigation.vue";
import Centered from "@/components/global/Centered.vue";
@Component({
  components: { Footer, Authenticator, Navigation, Centered }
})
export default class Home extends Vue {
  @Prop()
  to!: string;
}
