




















































































import AreaWidget from "@/components/apps/assetdetails/AreaWidget.vue";
import ActualProductionWidget from "@/components/apps/assetdetails/widgets/ActualProductionWidget.vue";
import BatchHistory from "@/components/apps/assetdetails/widgets/BatchHistory.vue";
import BatchOee from "@/components/apps/assetdetails/widgets/BatchOee.vue";
import EjectionForce from "@/components/apps/assetdetails/widgets/EjectionForce.vue";
import EnergyConsumption from "@/components/apps/assetdetails/widgets/EnergyConsumption.vue";
import FillingDeviceMotorCurrent from "@/components/apps/assetdetails/widgets/FillingDeviceMotorCurrent.vue";
import FillingDeviceSpeed from "@/components/apps/assetdetails/widgets/FillingDeviceSpeed.vue";
import MainCompressionForce from "@/components/apps/assetdetails/widgets/MainCompressionForce.vue";
import MainCompressionForceSRelWidget from "@/components/apps/assetdetails/widgets/MainCompressionForceSRelWidget.vue";
import MainMotorCurrent from "@/components/apps/assetdetails/widgets/MainMotorCurrent.vue";
import MainMotorTemperature from "@/components/apps/assetdetails/widgets/MainMotorTemperature.vue";
import FillingDeviceTemperature from "@/components/apps/assetdetails/widgets/FillingDeviceTemperature.vue";
import { WidgetDataMapper } from "@/components/apps/assetdetails/widgets/mapping/WidgetDataMapper";
import PreCompressionForce from "@/components/apps/assetdetails/widgets/PreCompressionForce.vue";
import PressureWidget from "@/components/apps/assetdetails/widgets/PressureWidget.vue";
import PressureWidgetV2 from "@/components/apps/assetdetails/widgets/PressureWidgetV2.vue";
import ProductOutputPerHour from "@/components/apps/assetdetails/widgets/ProductOutputPerHour.vue";
import Progress from "@/components/apps/assetdetails/widgets/Progress.vue";
import PunchStiffness from "@/components/apps/assetdetails/widgets/PunchStiffness.vue";
import Recipe from "@/components/apps/assetdetails/widgets/Recipe.vue";
import TabletThicknessWidget from "@/components/apps/assetdetails/widgets/TabletThicknessWidget.vue";
import TopErrorsWidget from "@/components/apps/assetdetails/widgets/TopErrorsWidget.vue";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import { AssetState } from "@/models/asset";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AreaWidget,
    TabletThicknessWidget,
    PressureWidget,
    PressureWidgetV2,
    OpenDialog,
    ActualProductionWidget,
    MainCompressionForceSRelWidget,
    ProductOutputPerHour,
    MainCompressionForce,
    PreCompressionForce,
    EjectionForce,
    MainMotorCurrent,
    TopErrorsWidget,
    FillingDeviceSpeed,
    FillingDeviceMotorCurrent,
    Progress,
    Recipe,
    EnergyConsumption,
    BatchOee,
    PunchStiffness,
    BatchHistory,
    MainMotorTemperature,
    FillingDeviceTemperature
  }
})
export default class AssetConditions extends Vue {
  @Prop()
  assetId!: string;

  @Prop({ default: () => [] })
  assetStates!: AssetState[];
  zoomOnPressure = false;

  get isAssetStatesNotEmpty(): boolean {
    return Array.isArray(this.assetStates) && this.assetStates.length > 0;
  }

  get widgetData(): WidgetDataMapper {
    return new WidgetDataMapper(this.assetStates, this.$i18n);
  }
}
