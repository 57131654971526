import { Media } from "@/models/media";

export interface SmartGuide {
  tenant: string;
  id: string;
  name: string;
  smartGuideType: string;
  state: number;
  assetTypes: string[];
  relevantForAssets: string[];
  startPageId: string;
}

export interface SmartGuideNextStep {
  text: string;
  pageId: string;
}

export interface SmartGuidePage {
  tenant: string;
  id: string;
  pageNumber: string;
  warning?: string;
  text: string;
  nextSteps: SmartGuideNextStep[];
  progress?: number;
  media: Media[];
  informationText?: string;
  recommendationText?: string;
}

export type SmartGuidePageType = "regular" | "support" | "finished";

export const createPage = (
  tenant: string,
  id: string,
  text = "Page text",
  pageNumber = "Page title"
): SmartGuidePage => ({
  tenant,
  id,
  text,
  pageNumber,
  media: [],
  nextSteps: []
});
