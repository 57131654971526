






import { Component, Vue, Prop } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
@Component
export default class RadialBar extends Vue {
  @Prop()
  percentage!: number;

  chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%"
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            formatter: function (val) {
              return val.toFixed(2) + "%";
            }
          }
        }
      }
    }
  };
}
