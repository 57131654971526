import LoadingStore from "@/store/modules/loading";
import { getModule } from "vuex-module-decorators";
const loadingStore = getModule(LoadingStore);

export class RemoteStatusService {
  beforeRemoteCall() {
    loadingStore.startLoading();
  }
  afterRemoteCall() {
    loadingStore.stopLoading();
  }
}
