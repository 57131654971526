import { AssetState, TimelineSeriesGrouped } from "@/models/asset";
export function mapFromAssetStates(assetStates: AssetState[]): number[] {
  return assetStates
    .map((it) => (it.DP0056 ?? 0) + (it.DP0057 ?? 0) + (it.DP0058 ?? 0))
    .filter(Boolean) as number[];
}

export function mapFromTimeSeries(timeSeriesData: TimelineSeriesGrouped): number[] {
  const consumptionSum: number[] = [];
  timeSeriesData.energyConsumption?.map((ec) => {
    const [_, level1, level2, level3] = ec;
    consumptionSum.push((Number(level1) ?? 0) + (Number(level2) ?? 0) + (Number(level3) ?? 0));
  });
  return consumptionSum;
}
