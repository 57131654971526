import {
  ActualProduction,
  AssetState,
  defaultTreshold,
  Progress,
  PunchStiffness,
  SensorValue,
  StationData,
  Treshold
} from "@/models/asset";
import { TabletThickness } from "@/models/TabletThickness";
import { MachineStation, WidgetData } from "@/ui-models";
import { last } from "remeda";
import type { IVueI18n } from "vue-i18n";
import { ActualProductionDataMapper } from "./ActualProductionDataMapper";
import { mapFromAssetStates } from "./EnergyConsumptionDataMapper";
import { PressureDataMapper } from "./PressureDataMapper";
import { ProgressDataMapper } from "./ProgressDataMapper";
import { TabletThicknessDataMapper } from "./TabletThicknessDataMapper";
export interface ProductOutput {
  rotorSpeedData: number[];
  productOutputData: number[];
  rotorSpeedPercentData: number[];
}

export class WidgetDataMapper {
  private readonly assetStates: AssetState[];
  private readonly i18n: IVueI18n;

  constructor(assetStates: AssetState[], i18n: IVueI18n) {
    this.assetStates = Array.isArray(assetStates) ? assetStates.slice() : [];
    this.i18n = i18n;
  }

  private get emptyWidgetData(): WidgetData {
    return { labels: [], series: [] };
  }

  get latestState(): AssetState | undefined {
    return last(this.assetStates);
  }

  private getTabletThicknessForStation(station: MachineStation): TabletThicknessDataMapper {
    const tabletThicknesses = this.assetStates.map((anAssetState) =>
      TabletThickness.fromState(anAssetState, station)
    );

    return new TabletThicknessDataMapper(tabletThicknesses, this.i18n);
  }

  tabletThickness(aStation: MachineStation): WidgetData {
    if (this.assetStates.length < 1) {
      return this.emptyWidgetData;
    }
    return this.getTabletThicknessForStation(aStation).mappedTabletThickness;
  }

  tabletThicknessDeviation(aStation: MachineStation): WidgetData {
    if (this.assetStates.length < 1) {
      return this.emptyWidgetData;
    }
    return this.getTabletThicknessForStation(aStation).mappedTabletThicknessDeviation;
  }

  pressure(): WidgetData {
    if (this.assetStates.length < 1) {
      return this.emptyWidgetData;
    }
    return new PressureDataMapper(this.assetStates, this.i18n).mappedPressure;
  }

  get actualProduction(): Partial<ActualProduction> {
    return new ActualProductionDataMapper(this.assetStates).mappedActualProduction;
  }

  get mainCompressionForceSRel(): StationData<number[]> {
    return {
      stationOne: this.assetStates.map((states) => states.DP0009) as number[],
      stationTwo: this.assetStates.map((states) => states.DP0038) as number[]
    };
  }

  get mainCompressionForceSRelTreshold(): Partial<StationData<Treshold>> {
    return {
      stationOne: {
        normal: defaultTreshold.normal,
        warning: this.latestState?.DP0008 ?? defaultTreshold.warning,
        danger: this.latestState?.DP0371 ?? defaultTreshold.danger
      },
      stationTwo: {
        normal: defaultTreshold.normal,
        warning: this.latestState?.DP0037 ?? defaultTreshold.warning,
        danger: this.latestState?.DP03xx ?? defaultTreshold.danger
      }
    };
  }

  get mainCompressionForce(): StationData<number[]> {
    return {
      stationOne: this.assetStates.map((states) => states.DP0007) as number[],
      stationTwo: this.assetStates.map((states) => states.DP0036) as number[]
    };
  }

  get preCompressionForce(): StationData<number[]> {
    return {
      stationOne: this.assetStates.map((states) => states.DP0011) as number[],
      stationTwo: this.assetStates.map((states) => states.DP0040) as number[]
    };
  }

  get ejectionForce(): StationData<number[]> {
    return {
      stationOne: this.assetStates.map((states) => states.DP0201) as number[],
      stationTwo: this.assetStates.map((states) => states.DP0203) as number[]
    };
  }

  get currentBatchNames(): string[] {
    return (
      (this.assetStates.map((assetState) => assetState.DP0020).filter(Boolean) as string[]) ?? []
    );
  }

  get recipes(): string[] {
    return (
      (this.assetStates.map((assetState) => assetState.DP0021).filter(Boolean) as string[]) ?? []
    );
  }

  get productOutputPerHour(): ProductOutput {
    return {
      productOutputData: this.assetStates.map((it) => it.DP0002).filter(Boolean) as number[],
      rotorSpeedData: this.assetStates.map((it) => it.DP0003).filter(Boolean) as number[],
      rotorSpeedPercentData: this.assetStates.map((it) => it.DP0084).filter(Boolean) as number[]
    };
  }

  get mainMotorCurrent(): number[] {
    return this.assetStates.map((it) => it.DP0158).filter(Boolean) as number[];
  }

  get fillingDeviceSpeed(): number[] {
    return this.assetStates.map((it) => it.DP0005).filter(Boolean) as number[];
  }

  get fillingDeviceMotorCurrent(): number[] {
    return this.assetStates.map((it) => it.DP0160).filter(Boolean) as number[];
  }

  get progress(): Partial<Progress> {
    return new ProgressDataMapper(this.assetStates).mappedProgress;
  }

  get energyConsumption(): number[] {
    return mapFromAssetStates(this.assetStates);
  }

  get punchStiffness(): PunchStiffness {
    const lastState = last(this.assetStates);
    return {
      lower: lastState?.DP0151 ?? 0,
      upper: lastState?.DP0149 ?? 0
    };
  }

  get mainMotorTemperature(): SensorValue {
    const lastState = last(this.assetStates);
    return {
      actual: lastState?.DP0377 ?? 0,
      set: lastState?.DP0376 ?? 0
    };
  }

  get fillingDeviceTemperature(): StationData<number> {
    const lastState = last(this.assetStates);
    return {
      stationTwo: lastState?.DP0375 ?? 0,
      stationOne: lastState?.DP0373 ?? 0
    };
  }

  get fillingDeviceTemperatureSet(): number {
    const lastState = last(this.assetStates);
    return Math.max(lastState?.DP0372 ?? 0, lastState?.DP0374 ?? 0);
  }
}
