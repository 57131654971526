



















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Site } from "@/models/asset";
import SiteMap from "@/components/apps/SiteMap.vue";
import SitesMap from "@/components/apps/SitesMap.vue";
import { SiteWithAssetsCount } from "@/ui-models";

@Component({
  components: { SitesMap, SiteMap }
})
export default class SiteMapDrawer extends Vue {
  @Prop()
  siteGroups!: Record<string, Site[]>;

  @Prop()
  site!: SiteWithAssetsCount;

  private _overlay = true;
  get overlay() {
    return this._overlay || true;
  }

  set overlay(newOverlay: boolean) {
    this._overlay = newOverlay;
    if (newOverlay === false) {
      this.close();
    }
  }

  @Emit()
  siteSelected(siteId: string) {
    return siteId;
  }

  @Emit()
  close() {
    return true;
  }
}
