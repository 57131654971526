import { ContactModel } from "@/models/cluster";
export enum ContactNames {
  bvselchow = "bvselchow",
  tschley = "tschley",
  tklingenhoff = "tklingenhoff",
  nmang = "nmang",
  mwiarda = "mwiarda",
  svulp = "svulp",
  cackermann = "cackermann"
}

export type OsdiContact =
  | "bvselchow"
  | "tschley"
  | "tklingenhoff"
  | "nmang"
  | "mwiarda"
  | "svulp"
  | "cackermann"
  | "nkudelko";

export const contacts: { [key: string]: ContactModel } = {
  bvselchow: {
    name: "Britta von Selchow",
    email: "BSelchow@fette-compacting.com",
    jobTitle: "Head of OSDi Digital Solutions by Fette Compacting",
    image: require("@/assets/ui-assets/assets/contact-images/bvselchow2.jpg"),
    phone: "+49 151 7023-1308"
  },
  tschley: {
    name: "Thorben Schley",
    email: "TSchley@fette-compacting.com",
    jobTitle: "Senior Innovation Architect",
    image: require("@/assets/ui-assets/assets/contact-images/tschley2.jpg"),
    phone: "+49 4151 12-773"
  },
  tklingenhoff: {
    name: "Tim Klingenhof",
    email: "TKlingenhof@fette-compacting.com",
    jobTitle: "Head of Training",
    image: require("@/assets/ui-assets/assets/contact-images/tklingenhof2.jpg"),
    phone: "+49 4151 12-763"
  },
  nmang: {
    name: "Nina Mang",
    email: "NMang@fette-compacting.com",
    jobTitle: "Senior Innovation Architect",
    image: require("@/assets/ui-assets/assets/contact-images/nmang4.jpg"),
    phone: "+49 4151 12-917"
  },
  mwiarda: {
    name: "Michael Wiarda",
    email: "MWiarda@fette-compacting.com",
    jobTitle: "Senior Solution Archtect",
    image: require("@/assets/ui-assets/assets/contact-images/mwiarda3.jpg"),
    phone: "+49 4151 12-929"
  },
  svulp: {
    name: "Sven Vulp",
    email: "SVulp@fette-compacting.com",
    jobTitle: "Program Owner Dynamic Data",
    image: require("@/assets/ui-assets/assets/contact-images/svulp3.jpg"),
    phone: "+49 4151 12-939"
  },
  cackermann: {
    name: "Carmen Ackermann",
    email: "CAckermann@fette-compacting.com",
    jobTitle: "Product Manager Tableting Tools",
    image: require("@/assets/ui-assets/assets/contact-images/cackermann1.png"),
    phone: "+49 4151 12-766"
  },
  nkudelko: {
    name: "Niklas Kudelko",
    email: "NKudelko@fette-compacting.com",
    jobTitle: "Junior Product Manager",
    image: require("@/assets/ui-assets/assets/contact-images/nkudelko2.jpg"),
    phone: "+49 4151 12-270"
  }
};
export function getContact(contact: OsdiContact): ContactModel {
  return contacts[contact];
}
