














import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Site as SiteModel, Asset as AssetModel } from "@/models/asset";
import ProcessCell from "@/components/apps/assetmanager/ProcessCell.vue";
import Asset from "@/components/apps/assetmanager/Asset.vue";
import * as R from "remeda";

@Component({
  components: { ProcessCell, Asset }
})
export default class Site extends Vue {
  @Prop()
  assets!: AssetModel[];

  @Emit()
  addAsset() {
    return true;
  }

  @Emit()
  editProcessCell(processCell: ProcessCell) {
    return processCell;
  }

  @Emit()
  openMapOverlay(element: Asset) {
    return element;
  }

  get processCellsGroup() {
    return R.pipe(
      this.assets,
      R.sort((asset1, asset2) => {
        if (!asset1.processCell) {
          return 1;
        } else if (!asset2.processCell) {
          return -1;
        } else {
          return asset1.processCell.name.localeCompare(asset2.processCell.name);
        }
      }),
      R.groupBy((asset) => asset.processCell?.name)
    );
  }

  get site(): SiteModel {
    const assetRepresentor = R.first(this.assets);
    return assetRepresentor!.site;
  }
}
