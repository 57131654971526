





























import { Component, Vue, Prop } from "vue-property-decorator";
import { ProductModel } from "@/models/cluster";
@Component
export default class ProductLink extends Vue {
  @Prop()
  product!: ProductModel;
}
