





















import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/apps/Header.vue";
import SmartCollegeCard from "@/components/apps/smartcollege/SmartCollegeCard.vue";
import { getModule } from "vuex-module-decorators";
import SmartCollegeStore from "@/components/apps/smartcollege/smartcollege-module";
import { Module } from "@/components/apps/smartcollege/smart-college";
import { BreadcrumbItem } from "@/components/apps/NavigationBreadcrumb.vue";

const smartCollegeStore = getModule(SmartCollegeStore);

@Component({
  components: { Header, SmartCollegeCard }
})
export default class CourseView extends Vue {
  private breadcrumbItems: BreadcrumbItem[] = [
    { text: "product.smartcollege", disabled: false, to: "/smartcollege", exact: true }
  ];

  mounted() {
    smartCollegeStore.setCurrentCourse(this.$route.params.courseId);
    // console.log(`set current course: ${this.$route.params.id}`);
    const currentCourse = smartCollegeStore.currentCourse;
    this.modules = currentCourse?.modules;
    this.breadcrumbItems.push({ text: currentCourse?.name ?? "", disabled: true });
  }

  private modules: Module[] | undefined = [];
}
