import { liveguideClient } from "@/plugins/graphql";
import * as mutations from "@/graphql/liveguide/mutations";
export interface ContactRequest {
  email: string;
  licenseType: string;
  licenseCount: number;
  salutation: string;
  name: string;
  company: string;
  message: string;
}

export interface LiveguideService {
  contact(contactRequest: ContactRequest): Promise<boolean>;
}

export class RemoteLiveguideService implements LiveguideService {
  contact(contactRequest: ContactRequest): Promise<boolean> {
    return liveguideClient
      .mutate({
        mutation: mutations.contact,
        variables: { contactRequest }
      })
      .then(({ data }) => (data as any)?.contact ?? true);
  }
}

export default new RemoteLiveguideService();
