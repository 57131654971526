












import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component
export default class Drawer extends Vue {
  @Prop()
  overlay!: boolean;

  @Prop()
  title!: string;

  get overlayModel() {
    return this.overlay;
  }

  set overlayModel(model: boolean) {
    // inform parent on route change triggered close
    if (model === false) {
      this.close();
    }
  }

  @Emit()
  close() {
    return true;
  }
}
