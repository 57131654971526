

















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { SmartGuide } from "@/models/smartguide";
import { AssetType, Asset } from "@/models/asset";
import { sortBy } from "remeda";
import assetService from "@remote-api/asset.service";

@Component
export default class GuideDetailsEditor extends Vue {
  @Prop()
  guide!: SmartGuide;

  availableAssetTypes: AssetType[] = [];
  availableAssets: Asset[] = [];
  possibleStates = [
    { value: 0, title: this.$t("product.smartguide.details.state.draft") },
    { value: 1, title: this.$t("product.smartguide.details.state.published") }
  ];

  async mounted() {
    this.availableAssetTypes = sortBy(await assetService.fetchAssetTypes(), (type) =>
      type.name.toLowerCase()
    );

    this.availableAssets = sortBy(await assetService.fetchAssets(), (asset) =>
      asset.name.toLowerCase()
    );
  }

  get formValid() {
    return this.guide.name.length > 0;
  }

  @Emit()
  saveChanges() {
    return this.guide;
  }
}
