import AssetDetails from "@/components/apps/AssetDetails.vue";
import AssetManager from "@/components/apps/AssetManager.vue";
import ConditionMonitorMachineView from "@/components/apps/conditionMonitor/MachineView.vue";
import ConditionMonitorSiteView from "@/components/apps/conditionMonitor/SiteView.vue";
import CourseList from "@/components/apps/smartcollege/CourseList.vue";
import CourseView from "@/components/apps/smartcollege/CourseView.vue";
import ModuleView from "@/components/apps/smartcollege/ModuleView.vue";
import SmartGuideDetails from "@/components/apps/smartguide/SmartGuideDetails.vue";
import SmartGuideEditor from "@/components/apps/smartguide/SmartGuideEditor.vue";
import SmartGuideOverview from "@/components/apps/smartguide/SmartGuideOverview.vue";
import DataPrivacy from "@/views/DataPrivacy.vue";
import AuthModule from "@/store/modules/auth";
import Authentication from "@/views/Authentication.vue";
import Backoffice from "@/views/Backoffice.vue";
import Home from "@/views/Home.vue";
import Product from "@/views/Product.vue";
import ProductView from "@/views/ProductView.vue";
import User from "@/views/User.vue";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import i18n from "@/i18n";
import logger from "@/plugins/logger";

const authStore = getModule(AuthModule);

Vue.use(VueRouter);
type Position = {
  x: number;
  y: number;
};
const routes: RouteConfig[] = [
  {
    path: "/",
    name: "LandingPage",
    component: Home
  },
  {
    path: "/login/:to",
    name: "Login",
    component: Authentication,
    props: true
  },
  {
    path: "/details/:product",
    name: "ProductDetails",
    component: ProductView,
    props: true
  },
  {
    path: "/user",
    component: User,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/data-privacy",
    name: "DataPrivacy",
    component: DataPrivacy
  },
  {
    path: "/backoffice",
    component: Backoffice,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/assets",
    component: Product,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/",
        name: "AssetManager",
        component: AssetManager,
        meta: {
          requireAuth: true
        }
      },
      {
        path: ":assetId",
        name: "AssetDetails",
        component: AssetDetails,
        props: true,
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: "/cm",
    component: Product,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/",
        name: "SiteView",
        component: ConditionMonitorSiteView,
        meta: {
          requireAuth: true
        }
      },
      {
        path: ":assetId",
        name: "MachineView",
        component: ConditionMonitorMachineView,
        props: true,
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: "/smartguide",
    component: Product,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/",
        name: "SmartGuideOverview",
        component: SmartGuideOverview,
        meta: {
          requireAuth: true
        }
      },
      {
        path: ":smartGuideId",
        name: "SmartGuideDetails",
        component: SmartGuideDetails,
        props: true,
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: "/smartguide/add",
    name: "CreateNewSmartGuide",
    component: SmartGuideEditor,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/smartguide/:smartGuideId/edit",
    name: "SmartGuideEditor",
    component: SmartGuideEditor,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/smartguide/:smartGuideId/delete",
    name: "DeleteSmartGuide",
    component: SmartGuideEditor,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/alva",
    component: Product,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/",
        name: "CourseList",
        component: CourseList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: ":courseId",
        name: "Course",
        component: CourseView,
        props: true,
        meta: {
          requireAuth: true
        }
      },
      {
        path: ":courseId/modules/:moduleId",
        name: "Module",
        component: ModuleView,
        props: true,
        meta: {
          requireAuth: true
        }
      }
    ]
  }
];

// eslint-disable-next-line
// @typescript-eslint/no-explicit-any
const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void): any => {
  if (to.hash) {
    return { selector: to.hash };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach(async (to, from, next) => {
  // Set language
  if (to.query.lang) {
    try {
      i18n.locale = to.query.lang as string;
    } catch (error) {
      logger.error("could not select language", error);
    }
  }

  // Set title
  document.title = i18n.t("global.page.title").toString();

  // Check authentication
  const { requireAuth } = to.meta;
  if (requireAuth) {
    await authStore.reloadUser();
    if (authStore.isLoggedIn) {
      return next();
    } else {
      const toAfterLogin = to.name;
      return next({
        name: "Login",
        params: {
          to: toAfterLogin || "/"
        }
      });
    }
  } else {
    return next();
  }
});

export default router;
