

























import AreaChart from "@/components/global/charts/AreaChart.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import globalConfig from "@/globals/Configuration";
import * as R from "remeda";
import { WidgetData } from "@/ui-models";
import Card from "@/components/apps/assetdetails/widgets/Card.vue";

@Component({
  components: { AreaChart, Card }
})
export default class TwoRowWidget extends Vue {
  @Prop()
  title!: string;
  @Prop()
  unitTitle!: string;

  @Prop()
  data!: WidgetData;

  last(seriesData: (number | null)[]): string | number {
    if (seriesData) {
      return R.last(seriesData) || "-";
    } else {
      return 0;
    }
  }

  get colors(): string[] {
    return [globalConfig.colors.brand, globalConfig.colors.warning];
  }
}
