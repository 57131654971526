











import type { User } from "@/ui-models";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import UserEnableDisable from "@/components/apps/backoffice/usermanagement/UserEnableDisable.vue";
import UserResetPassword from "@/components/apps/backoffice/usermanagement/UserResetPassword.vue";
import UserDelete from "@/components/apps/backoffice/usermanagement/UserDelete.vue";

@Component({
  components: { UserEnableDisable, UserResetPassword, UserDelete }
})
export default class UserEdit extends Vue {
  @Prop()
  user!: User;

  @Emit()
  update(theUpdatedUser: User) {
    return theUpdatedUser;
  }
  @Emit()
  deleted() {
    return this.user;
  }

  onStatusUpdate(theUserStatus: boolean) {
    this.update(Object.assign({}, { ...this.user, enabled: theUserStatus }));
  }
}
