


































import { Component, Vue, Prop } from "vue-property-decorator";
import ProductLink from "@/components/ProductLink.vue";
import { ProductModel } from "@/models/cluster";

@Component({
  components: {
    ProductLink
  }
})
export default class Product extends Vue {
  @Prop()
  product!: ProductModel;

  get detailsButtonStyle() {
    const style = {
      tile: true,
      text: true,
      depressed: false,
      color: "neutral-500",
      class: ""
    };

    if (!(this.product.link || this.product.remoteUrl)) {
      style.color = "brand";
      style.text = false;
      style.depressed = true;
      style.class = "text-color-white";
    }
    return style;
  }
}
