import * as authnService from "@/services/authentication.service";
import { UserRole } from "@/ui-models";
import { hasRole } from "./user.ui-service";

export interface AuthorizedUser {
  backoffice: boolean;
}

const admin: AuthorizedUser = Object.freeze({ backoffice: true });
const user: AuthorizedUser = Object.freeze({ backoffice: false });
export const unknown: AuthorizedUser = Object.freeze({ backoffice: false });

export async function getAuthorizedUser(): Promise<AuthorizedUser> {
  const cognitoGroups = await authnService.getUserGroups();
  if (hasRole(cognitoGroups, UserRole.ADMINISTRATOR)) return admin;
  return user;
}
