import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import { getContact } from "@/services/contacts.service";
import type VueI18n from "vue-i18n";
import { ControlClusterModel } from "./ControlClusterModel";

export class PerformanceManagerModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}
  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("performanceManager.details.teaser.text")
      },
      featureTeaser: this.$i18n.tc("performanceManager.details.featureTeaser"),
      features: [
        {
          title: this.$i18n.tc("performanceManager.details.features.analytics.title"),
          text: this.$i18n.tc("performanceManager.details.features.analytics.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_realtime_591026_000000.svg")
        },
        {
          title: this.$i18n.tc("performanceManager.details.features.humaninloop.title"),
          text: this.$i18n.tc("performanceManager.details.features.humaninloop.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_dashboard_3048219_000000.svg")
        },
        {
          title: this.$i18n.tc("performanceManager.details.features.understandable.title"),
          text: this.$i18n.tc("performanceManager.details.features.understandable.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_edit_3248033_000000.svg")
        }
      ],
      explanation: {
        title: this.$i18n.tc("performanceManager.details.explanation.title"),
        text: this.$i18n.tc("performanceManager.details.explanation.text")
      },
      contactTeaser: this.$i18n.tc("performanceManager.details.contactTeaser")
    };
  }

  get cluster(): ClusterModel {
    return new ControlClusterModel(this.$i18n);
  }
  get product(): ProductModel {
    return {
      id: "perfman",
      title: this.$i18n.tc("performanceManager.product.title"),
      description: this.$i18n.tc("performanceManager.product.description"),
      image: require("@/assets/ui-assets/assets/design01/PerformanceManager_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/PerformanceManager_mehrfarbig_RGB.svg")
    };
  }

  get contacts(): ContactModel[] {
    return [getContact("svulp")];
  }
}
