import { Component, Vue, Prop } from "vue-property-decorator";
import ConditionModule from "@/store/modules/condition";
import { last } from "remeda";
import { getModule } from "vuex-module-decorators";

const conditionStore = getModule(ConditionModule);

@Component
export default class BatchBasedWidget extends Vue {
  @Prop()
  assetId!: string;
  @Prop({ default: () => [] })
  batchNames!: string[];

  get currentBatchName(): string {
    return (
      conditionStore.assetSummaryState(this.assetId)?.currentBatchName ??
      last(this.batchNames) ??
      ""
    );
  }

  async mounted(): Promise<void> {
    await this.fetchRemoteData();
  }

  async doFetchRemoteData(): Promise<void> {
    throw new Error("Not implemented!");
  }

  async fetchRemoteData(): Promise<void> {
    if (!this.assetId || !this.currentBatchName) {
      return;
    }
    return this.doFetchRemoteData();
  }
}
