import SnackbarModule from "@/store/modules/snackbar";
import { VueForm } from "@/types.d";
import { UserNotification } from "@/ui-models";
import { Component, Emit, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
const notificationStore = getModule(SnackbarModule);

@Component
export default class CreateEntityInForm<T> extends Vue {
  @Emit()
  cancel() {
    return false;
  }

  @Emit()
  done(newEntity: T): T {
    return newEntity;
  }

  protected formValid = true;

  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  protected notifyUser(notification: UserNotification) {
    notificationStore.notifyUser(notification);
  }

  save() {
    if (this.form.validate()) {
      this.doSave();
    }
  }

  doSave() {
    // implement me
  }
}
