import { PaginatedUsers } from "@/ui-models";
import gql from "graphql-tag";

export interface Query {
  listUsersInTenantGroup: PaginatedUsers;
  listGroupsForUser: string[];
}

export const listUsersInTenantGroup = gql`
  query ListUsersInTenantGroup($nextToken: String) {
    listUsersInTenantGroup(nextToken: $nextToken) {
      nextToken
      users {
        email
        enabled
        status
        username
      }
    }
  }
`;

export const listGroupsForUser = gql`
  query ListGroupsForUser($username: String!) {
    listGroupsForUser(username: $username)
  }
`;
