



import GenericProductDetails from "@/components/products/GenericProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { Component, Vue } from "vue-property-decorator";
import { ConditionMonitorModel } from "./ConditionMonitorModel";
@Component({
  components: {
    GenericProductDetails
  }
})
export default class ConditionMonitor extends Vue {
  get container(): ProductDetailsContainerModel {
    return new ConditionMonitorModel(this.$i18n);
  }
}
