












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class WidgetCard extends Vue {
  @Prop({ default: false })
  containsChart!: boolean;
}
