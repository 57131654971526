import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import type VueI18n from "vue-i18n";
import { getContact } from "@/services/contacts.service";
import { ControlClusterModel } from "./ControlClusterModel";

export class SmartInterfaceModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}

  get product(): ProductModel {
    return {
      id: "smartinterface",
      title: this.$i18n.tc("smartinterface.product.title"),
      description: this.$i18n.tc("smartinterface.product.description"),
      image: require("@/assets/ui-assets/assets/design01/SmartInterface_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/SmartInterface_mehrfarbig_RGB.svg")
    };
  }

  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("smartinterface.details.teaser.text")
      },
      features: [
        {
          title: this.$i18n.tc("smartinterface.details.features.visibility.title"),
          text: this.$i18n.tc("smartinterface.details.features.visibility.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_OEMcontent_3233352_000000.svg")
        },
        {
          title: this.$i18n.tc("smartinterface.details.features.everywhere.title"),
          text: this.$i18n.tc("smartinterface.details.features.everywhere.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_edit_3248033_000000.svg")
        },
        {
          title: this.$i18n.tc("smartinterface.details.features.nocloud.title"),
          text: this.$i18n.tc("smartinterface.details.features.nocloud.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_nocloud_3248487_000000.svg")
        }
      ],
      contactTeaser: this.$i18n.tc("smartinterface.details.contactTeaser"),
      explanation: {
        title: this.$i18n.tc("smartinterface.details.explanation.title"),
        text: this.$i18n.tc("smartinterface.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/design01/SmartInterface_mehrfarbig_RGB.svg"),
        video: "https://www.youtube.com/embed/dNLnNgZXMmw"
      },
      featureTeaser: this.$i18n.tc("smartinterface.details.featureteaser")
    };
  }

  get cluster(): ClusterModel {
    return new ControlClusterModel(this.$i18n);
  }

  get contacts(): ContactModel[] {
    return [getContact("nkudelko")];
  }
}
