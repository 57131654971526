










import { Component, Vue, Prop } from "vue-property-decorator";
import { Location } from "vue-router";
export interface BreadcrumbItem {
  text: string;
  disabled?: boolean;
  to?: string | Location;
  exact?: boolean;
}

@Component
export default class NavigationBreadcrumb extends Vue {
  @Prop()
  items!: BreadcrumbItem[];

  get translatedItems(): BreadcrumbItem[] {
    if (!this.items) {
      return [];
    }
    return this.items.map((it) => {
      return { ...it, text: this.$t(it.text).toString() };
    });
  }
}
