




















import { Component, Vue, Prop } from "vue-property-decorator";
import Header from "./Header.vue";
import AssetSummary from "@/components/apps/assetdetails/AssetSummary.vue";
import AssetDataSummary from "@/components/apps/assetdetails/AssetDataSummary.vue";
import AssetConditions from "@/components/apps/assetdetails/AssetConditions.vue";
import Documents from "@/components/apps/assetdetails/documents/Documents.vue";
import SmartGuides from "@/components/apps/assetdetails/SmartGuides.vue";
import Drawer from "@/components/apps/partials/Drawer.vue";
import ProcessCellList from "@/components/apps/assetdetails/ProcessCellList.vue";
import { BreadcrumbItem } from "../NavigationBreadcrumb.vue";
import assetService from "@remote-api/asset.service";
import { Asset, AssetState } from "@/models/asset";
import ContactDrawer from "@/components/apps/ContactDrawer.vue";
import SiteMapDrawer from "@/components/apps/SiteMapDrawer.vue";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import AssetDelete from "@/components/apps/assetdetails/AssetDelete.vue";
import conditionService from "@remote-api/condition.service";
import AssetEdit from "@/components/apps/assetmanager/crud/AssetEdit.vue";
import SmartGuidePlayer from "@/components/apps/smartguide/SmartGuidePlayer.vue";
import { SmartGuide } from "@/models/smartguide";
import logger from "@/plugins/logger";
import { getModule } from "vuex-module-decorators";
import LoadingStore from "@/store/modules/loading";
import ConditionModule from "@/store/modules/condition";
import globalConfig from "@/globals/Configuration";
import { subtractMinutesFromNow } from "@/services/date.service";
import AssetConditionsHeadline from "@/components/apps/assetdetails/AssetConditionsHeadline.vue";
const loadingStore = getModule(LoadingStore);
const conditionStore = getModule(ConditionModule);
@Component({
  components: {
    AssetSummary,
    AssetDataSummary,
    AssetConditions,
    Header,
    Documents,
    SmartGuides,
    ContactDrawer,
    SiteMapDrawer,
    OpenDialog,
    AssetEdit,
    AssetDelete,
    SmartGuidePlayer,
    Drawer,
    ProcessCellList,
    AssetConditionsHeadline
  }
})
export default class MachineView extends Vue {
  @Prop()
  assetId!: string;

  private asset: Asset | null = null;

  private contactOverlay = false;
  private mapOverlay = false;
  private processCellOverlay = false;

  private isEditAssetVisible = false;
  private assetStates: AssetState[] = [];

  private isSmartGuideVisible = false;
  private visibleSmartGuide = {} as SmartGuide;
  private statesPollerId: any = 0;
  private latestNonEmptyPoll = Date.now();
  private readonly assetStateTimeRangeMinutes = 60;

  async mounted(): Promise<void> {
    if (this.assetId) {
      try {
        loadingStore.startLoading();
        this.asset = await assetService.fetchAsset(this.assetId);
        if (this.asset.isConnected) {
          await this.fetchAssetStates();
          await this.fetchAndUpdateSummaryStatesStore();
          this.latestNonEmptyPoll = Date.now();
          this.initPoller();
        }
      } catch (error) {
        logger.error("Could not load remote data", error);
      } finally {
        loadingStore.stopLoading();
      }
    }
  }

  destroyed(): void {
    if (this.statesPollerId) {
      logger.debug("Destroying statesPoller");
      clearInterval(this.statesPollerId);
    }
  }

  private initPoller() {
    logger.debug("Initializing statesPoller");
    this.statesPollerId = setInterval(this.updateAssetStates, globalConfig.pollingOffset);
  }

  private async fetchAssetStates() {
    try {
      loadingStore.startLoading();
      if (this.asset!.isConnected) {
        this.assetStates = await conditionService.fetchAssetState(
          this.assetId,
          this.asset?.site?.id,
          subtractMinutesFromNow(this.assetStateTimeRangeMinutes)
        );
        conditionStore.updateLatestAssetStates(this.assetStates);
        await this.fetchAndUpdateSummaryStatesStore();
        logger.debug("Asset states updated");
      }
    } catch (error) {
      logger.error("Could not load remote data", error);
    } finally {
      loadingStore.stopLoading();
    }
  }

  private async updateAssetStates() {
    try {
      loadingStore.startLoading();
      if (this.asset!.isConnected) {
        const newAssetStates = await conditionService.fetchAssetState(
          this.assetId,
          this.asset?.site?.id,
          this.latestNonEmptyPoll
        );

        // merge polling results and update poll timer only on non empty results
        // otherwise we might miss a beat
        if (Array.isArray(newAssetStates) && newAssetStates.length > 0) {
          this.assetStates = [...this.assetStates, ...newAssetStates];
          this.latestNonEmptyPoll = Date.now();
          conditionStore.updateLatestAssetStates(newAssetStates);
          logger.debug("Asset states updated");
        }

        await this.fetchAndUpdateSummaryStatesStore();
      }
    } catch (error) {
      logger.error("Could not update remote data", error);
    } finally {
      loadingStore.stopLoading();
    }
  }

  private async fetchAndUpdateSummaryStatesStore() {
    const summaryStates = await conditionService.fetchAssetSummaryStates([this.assetId]);
    conditionStore.updateAssetSummaryStates(summaryStates);
  }

  get breadcrumbItems(): BreadcrumbItem[] {
    const breadCrumbs = [];
    breadCrumbs.push({ text: "product.pam.title", disabled: false, to: "/assets", exact: true });
    if (this.asset?.name) {
      breadCrumbs.push({ text: this.asset.name, disabled: true });
    }
    return breadCrumbs;
  }

  assetEdited(asset: Asset) {
    this.isEditAssetVisible = false;
    this.asset = asset;
  }

  openContact() {
    this.contactOverlay = true;
    this.mapOverlay = false;
  }

  openMap() {
    this.contactOverlay = false;
    this.mapOverlay = true;
  }

  playSmartGuide(guide: SmartGuide) {
    this.visibleSmartGuide = guide;
    this.isSmartGuideVisible = true;
  }

  playSmartGuideClose() {
    this.isSmartGuideVisible = false;
    this.visibleSmartGuide = {} as SmartGuide;
  }
}
