import { AssetSummaryState } from "@/models/asset";
import gql from "graphql-tag";

export interface Query {
  getAssetSummaryState: AssetSummaryState;
  getAssetSummaryStates: AssetSummaryState[];
  getAssetStates: AssetSummaryState[];
  getMainCompressionForceSRel: string;
}

export const getAssetStates = gql`
  query GetAssetStates($assetId: String!, $siteId: String!, $after: AWSTimestamp) {
    getAssetStates(assetId: $assetId, siteId: $siteId, after: $after) {
      timestamp
      DP0002
      DP0003
      DP0005
      DP0007
      DP0008
      DP0009
      DP0012
      DP0013
      DP0014
      DP0015
      DP0020
      DP0021
      DP0027
      DP0028
      DP0030
      DP0036
      DP0037
      DP0056
      DP0057
      DP0058
      DP0078
      DP0084
      DP0096
      DP0097
      DP0098
      DP0099
      DP0100
      DP0101
      DP0102
      DP0103
      DP0120
      DP0121
      DP0122
      DP0123
      DP0124
      DP0125
      DP0126
      DP0127
      DP0149
      DP0151
      DP0158
      DP0160
      DP0201
      DP0203
      DP0371
      DP0372
      DP0373
      DP0374
      DP0375
      DP0376
      DP0377
    }
  }
`;

export const getAssetSummaryState = gql`
  query GetAssetSummaryState($assetId: String!) {
    getAssetSummaryState(assetId: $assetId) {
      assetId
      tenant
      state
      currentBatchName
      timestamp
    }
  }
`;

export const getAssetSummaryStates = gql`
  query GetAssetSummaryStates($assetIds: [String]!) {
    getAssetSummaryStates(assetIds: $assetIds) {
      assetId
      tenant
      state
      currentBatchName
      timestamp
    }
  }
`;

export const getPressureV2 = gql`
  query GetPressureV2(
    $assetId: String!
    $beforeHours: Int!
    $aggregateFunction: String
    $timeWindow: String
  ) {
    getPressureV2(
      assetId: $assetId
      beforeHours: $beforeHours
      aggregateFunction: $aggregateFunction
      timeWindow: $timeWindow
    )
  }
`;
export const getMainCompressionForceSRel = gql`
  query GetMainCompressionForceSRel($assetId: String!, $batchName: String!) {
    getMainCompressionForceSRel(assetId: $assetId, batchName: $batchName)
  }
`;
export const getEnergyConsumption = gql`
  query GetEnergyConsumption($assetId: String!, $batchName: String!) {
    getEnergyConsumption(assetId: $assetId, batchName: $batchName)
  }
`;
export const getOee = gql`
  query GetOee($assetId: String!, $batchName: String!) {
    getOee(assetId: $assetId, batchName: $batchName)
  }
`;
export const getBatchHistory = gql`
  query GetBatchHistory($assetId: String!, $batchName: String!) {
    getBatchHistory(assetId: $assetId, batchName: $batchName)
  }
`;
