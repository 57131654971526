import {
  Asset,
  AssetDocument,
  AssetDTO,
  AssetType,
  Contact,
  ProcessCell,
  Site
} from "@/models/asset";
import gql from "graphql-tag";

export interface AssetsDTO {
  getAssets: AssetDTO[];
  getAssetTypes: AssetType[];
  getSites: Site[];
  getProcessCells: ProcessCell[];
  getContactPersons: Contact[];
}

export interface Query {
  getSites: Site[];
  getProcessCells: ProcessCell[];
  getContactPersons: Contact[];
  getAsset: AssetDTO;
  getAssets: Asset[];
  getAssetsWithReferences: AssetsDTO;
  getAssetTypes: AssetType[];
  getDocuments: AssetDocument[];
  getAssetsInProcessCell: Asset[];
}

export const getAssetsInProcessCell = gql`
  query GetAssetsInProcessCell($processCellId: String!) {
    getAssetsInProcessCell(processCellId: $processCellId) {
      assetId
      name
      serialNumber
      typeId
    }
  }
`;

const getAssetFields = `
      tenant
      assetId
      typeId
      name
      processCellId
      siteId
      description
      serialNumber
      isConnected
      constructionYear
      contactPersonId
`;

export const getAssets = gql`
  query GetAssets {
    getAssets {
      ${getAssetFields}
    }
}
`;
const getAssetTypesQueryPayload = `
    getAssetTypes {
      id
      name
      manufacturer
      image
    }`;

export const getAssetTypes = gql`
  query GetAssetTypes {
    ${getAssetTypesQueryPayload}
  }
`;

const getSitesQueryPayload = `
    getSites {
      id
      name
      description
      location {
        longitude
        latitude
      }
    }
`;

export const getSites = gql`
  query GetSites {
  ${getSitesQueryPayload}
  }
`;

const getProcessCellsQueryPayload = `
    getProcessCells {
      id
      siteId
      name
      description
    }
`;

export const getProcessCells = gql`
  query GetProcessCells {
    ${getProcessCellsQueryPayload}
  }
`;

export const getProcessCellNames = gql`
  query GetProcessCellNames {
    getProcessCells {
      name
    }
  }
`;

const getContactPersonsQueryPayload = `
    getContactPersons {
      id
      name
      description
      role
      company
      email
      phone
      image
    }
`;

export const getContactPersons = gql`
  query GetContactPersons {
    ${getContactPersonsQueryPayload}
  }
`;

export const getAsset = gql`
  query GetAsset($assetId: String!) {
    getAsset(assetId: $assetId) {
      ${getAssetFields}
    }
  }
`;

export const getDocuments = gql`
  query GetDocuments($assetId: String!) {
    getDocuments(assetId: $assetId) {
      id
      assetId
      urlPath
      name
      type
      fileType
      uploaded
      size
    }
  }
`;

export const getAssetsWithReferences = gql`
  query GetAssetsWithReferences {
    getAssets{
      ${getAssetFields}
    }
    ${getAssetTypesQueryPayload}
    ${getSitesQueryPayload}
    ${getProcessCellsQueryPayload}
  }
`;
