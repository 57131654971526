






import { Component, Vue, Prop } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";

@Component
export default class LineChart extends Vue {
  @Prop()
  series!: { data: number[]; name: string }[];

  @Prop()
  labels!: string[];

  @Prop()
  chartOptions!: ApexOptions;

  get options(): ApexOptions {
    return { ...this.chartOptions, labels: this.labels };
  }
}
