
































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SmartCollegeCard extends Vue {
  @Prop()
  to!: string | Location | undefined;

  @Prop()
  title!: string;

  @Prop()
  text!: string;

  @Prop()
  subtitle!: string;

  @Prop()
  status!: string;

  @Prop()
  progressPercent!: number;

  @Prop()
  tags!: string[];

  @Prop()
  image!: string;

  get progressBarValue() {
    return this.progressPercent >= 100 ? "Completed" : `${this.progressPercent}%`;
  }

  get showProgress() {
    return this.progressPercent >= 0;
  }
}
