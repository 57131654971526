




















import { User, UserRoleString } from "@/ui-models";
import { Component, Prop, Vue } from "vue-property-decorator";
import userManagementService from "@remote-api/user-management.service";
import { notification } from "@/store/store.ui-service";
import { userRolesToStrings } from "@/services/user.ui-service";

@Component
export default class UserRoles extends Vue {
  @Prop()
  user!: User;
  assignedRoles: string[] = [];
  availableRoles: string[] = [];
  private debounceTimerId = -1;

  async mounted() {
    this.availableRoles = await userManagementService.fetchAvailableRoles();
    this.assignedRoles = userRolesToStrings(
      await userManagementService.fetchGroupsForUser(this.user.username)
    );
  }

  get availableRolesEnums(): UserRoleString[] {
    return userRolesToStrings(this.availableRoles);
  }

  async changeRolesDebounce(activeRoles: UserRoleString[]) {
    clearTimeout(this.debounceTimerId);

    this.debounceTimerId = (setTimeout(async () => {
      await this.changeRoles(activeRoles);
    }, 500) as any) as number;
  }

  async changeRoles(activeRoles: UserRoleString[]) {
    try {
      await userManagementService.updateUserRoles(this.user.username, activeRoles);
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-roles.success")),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: String(this.$t("backoffice.user.user-roles.failure")),
        type: "error"
      });
    }
  }
}
