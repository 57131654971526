




































































import { Component, Vue } from "vue-property-decorator";
import FilterBox from "@/components/apps/assetmanager/FilterBox.vue";
import Site from "@/components/apps/assetmanager/Site.vue";
import { Asset, ProcessCell } from "@/models/asset";
import assetService from "@remote-api/asset.service";
import { FilterModel, SiteWithAssetsCount } from "@/ui-models/index";
import { filterAssets } from "@/services/asset.ui-service";
import HeaderAddButton from "@/components/apps/partials/HeaderAddButton.vue";
import AssetAdd from "@/components/apps/assetmanager/crud/AssetAdd.vue";
import EditProcessCell from "@/components/apps/assetmanager/crud/EditProcessCell.vue";
import OpenDialog from "@/components/apps/OpenDialog.vue";
import Header from "@/components/apps/Header.vue";
import SiteMapDrawer from "@/components/apps/SiteMapDrawer.vue";
import TransparencyManager from "@/components/apps/TransparencyManager.vue";
import * as R from "remeda";
import logger from "@/plugins/logger";
import conditionService from "@remote-api/condition.service";
import globalConfig from "@/globals/Configuration";
import ConditionModule from "@/store/modules/condition";
import { getModule } from "vuex-module-decorators";
const conditionStore = getModule(ConditionModule);

@Component({
  components: {
    FilterBox,
    Site,
    Header,
    OpenDialog,
    HeaderAddButton,
    AssetAdd,
    EditProcessCell,
    SiteMapDrawer,
    TransparencyManager
  }
})
export default class SiteView extends Vue {
  private assets: Asset[] = [];
  private filter = new FilterModel();
  private mapViewActive = false;
  private breadcrumbItems = [{ text: "product.pam.title", disabled: false }];

  private mapOverlay = false;
  private mapSite: SiteWithAssetsCount | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private summaryStatesPollerId: any = 0;

  private processCellToEdit: ProcessCell | null = null;
  private tab = null;

  private overlay = {
    isAddAssetVisible: false,
    isEditProcessCellVisible: false
  };

  private doEditProcessCell(processCell: ProcessCell) {
    this.processCellToEdit = processCell;
    this.overlay.isEditProcessCellVisible = true;
  }

  get shouldShowOverlay(): boolean {
    return this.overlay.isAddAssetVisible || this.overlay.isEditProcessCellVisible;
  }

  private closeOverlay() {
    this.overlay.isAddAssetVisible = false;
    this.overlay.isEditProcessCellVisible = false;
    this.processCellToEdit = null;
  }

  async processCellEdited(): Promise<void> {
    this.closeOverlay();
    this.assets = await assetService.fetchAssets();
  }

  async mounted() {
    this.assets = await assetService.fetchAssets();
    await this.fetchAndUpdateSummaryStatesStore();
    this.initPoller();
  }

  destroyed() {
    if (this.summaryStatesPollerId) {
      logger.debug("Destroying summaryStatesPoller");
      clearInterval(this.summaryStatesPollerId);
    }
  }

  initPoller() {
    logger.debug("Initializing summaryStatesPoller");
    this.summaryStatesPollerId = setInterval(async () => {
      await this.fetchAndUpdateSummaryStatesStore();
    }, globalConfig.pollingOffset);
  }

  private async fetchAndUpdateSummaryStatesStore() {
    const summaryStates = await conditionService.fetchAssetSummaryStates(this.connectedAssetIds);
    conditionStore.updateAssetSummaryStates(summaryStates);
    logger.debug("summaryStates polled:", summaryStates);
  }

  get connectedAssetIds(): string[] {
    return this.assets.filter((it) => it.isConnected).map((it) => it.assetId);
  }

  newAssetAdded(newAsset: Asset) {
    this.overlay.isAddAssetVisible = false;
    this.assets.push(newAsset);
  }

  openMapOverlay(siteName: string, assets: Asset[]) {
    if (Array.isArray(assets) && assets.length > 0) {
      const site = assets[0].site;
      this.mapSite = {
        ...site,
        count: assets.length
      };
    }
    this.mapOverlay = true;
  }
  closeMapOverlay() {
    this.mapSite = null;
    this.mapOverlay = false;
  }

  get sites(): Record<string, Asset[]> {
    return R.pipe(
      this.filteredAssets,
      R.sortBy((asset) => asset.site.name.toLowerCase()),
      R.groupBy((asset) => asset.site.name)
    );
  }

  get filteredAssets(): Asset[] {
    return filterAssets(this.assets, this.filter);
  }

  private siteViaMapSelected(siteId: string) {
    this.filter.siteId = siteId;
  }
}
