
































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Teaser extends Vue {
  get imageUrl1(): string {
    return require("@/assets/ui-assets/assets/design01/OSDi_header_RZ.svg");
  }

  get imageUrl2(): string {
    return require("@/assets/ui-assets/assets/design01/OSDi-Claim_negativ_RGB.svg");
  }

  get ImageSourceset(): string {
    return this.imageUrl1 + " 1200w, " + this.imageUrl2 + " 600w";
  }

  get ImageSourcesetMobile(): string {
    return this.imageUrl1 + " 1x, " + this.imageUrl2 + " 2x";
  }

  get imageHeight(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return "220px";
      case "sm":
        return "265px";
      case "md":
        return "310px";
      case "lg":
        return "355px";
      case "xl":
        return "382px";
      default:
        return "382px";
    }
  }

  get isMobile(): string {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      return "true";
    } else {
      return "false";
    }
  }
}
