




























































































































































import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import CreateAssetType from "@/components/apps/assetmanager/crud/CreateAssetType.vue";
import CreateProcessCell from "@/components/apps/assetmanager/crud/CreateProcessCell.vue";
import CreateContactPerson from "@/components/apps/assetmanager/crud/CreateContactPerson.vue";
import { AssetType, Site, Asset, ProcessCell, Contact } from "@/models/asset";
import assetService from "@remote-api/asset.service";
import {
  requiredValidationRule,
  constructionYearValidation
} from "@/services/input-validation.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar";
import logger from "@/plugins/logger";
import { UserNotification } from "@/ui-models";
import { sortBy } from "remeda";
const notificationStore = getModule(SnackbarModule);

@Component({
  components: {
    CreateAssetType,
    CreateProcessCell,
    CreateContactPerson
  }
})
export default class AssetEdit extends Vue {
  formValid = true;
  showCreateAssetType = false;
  showCreateProcessCell = false;
  showCreateContactPerson = false;

  @Prop()
  private asset!: Asset;

  assetTypes: AssetType[] = [];
  sites: Site[] = [];
  processCells: ProcessCell[] = [];
  contactPersons: Contact[] = [];

  nameRules = [requiredValidationRule("Asset name")];
  siteRules = [requiredValidationRule("Site")];
  constructionYearRules = [(year: any) => constructionYearValidation(year)];

  @Emit()
  done(asset: Asset): Asset {
    return asset;
  }

  async mounted() {
    await this.fetchRemoteData();
  }

  private async fetchRemoteData() {
    this.assetTypes = sortBy(await assetService.fetchAssetTypes(), (type) =>
      type.name.toLowerCase()
    );
    this.sites = sortBy(await assetService.fetchSites(), (site) => site.name.toLowerCase());
    this.processCells = sortBy(await assetService.fetchProcessCells(), (cell) =>
      cell.name.toLowerCase()
    );
    this.contactPersons = sortBy(await assetService.fetchContactPersons(), (Contact) =>
      Contact.name.toLowerCase()
    );
  }

  private notifyUser(notification: UserNotification) {
    notificationStore.notifyUser(notification);
  }

  createAssetType() {
    this.showCreateAssetType = !this.showCreateAssetType;
  }

  assetTypeCreated(newAssetType: AssetType) {
    this.showCreateAssetType = false;
    this.assetTypes.push(newAssetType);
  }

  processCellCreated(newProcessCell: ProcessCell) {
    this.showCreateProcessCell = false;
    this.processCells.push(newProcessCell);
  }

  contactPersonCreated(newContactPerson: Contact) {
    this.showCreateContactPerson = false;
    this.contactPersons.push(newContactPerson);
  }

  editAsset() {
    return this.doEditAsset();
  }

  async doEditAsset() {
    try {
      logger.debug("Trying to update asset");
      const updatedAsset = await assetService.updateAsset(this.asset);
      this.notifyUser({ message: "Asset updated", type: "success" });
      logger.info("Update asset", updatedAsset);
      this.done(updatedAsset);
    } catch (error) {
      this.notifyUser({ message: "Asset update failed", type: "warning" });
      logger.error("Could not update asset", error);
    }
  }
}
