








import { Component, Vue, Prop } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import ProductDetailsV2 from "@/components/products/ProductDetailsV2.vue";
import Centered from "@/components/global/Centered.vue";

@Component({
  components: {
    ProductDetailsV2,
    Footer,
    Navigation,
    Centered
  }
})
export default class ProductView extends Vue {
  @Prop()
  product!: string;
}
