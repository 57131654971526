




































import CreateEntityFormCard from "@/components/apps/assetmanager/crud/partials/CreateEntityFormCard.vue";
import { Site } from "@/models/asset";
import { requiredValidationRule } from "@/services/input-validation.service";
import { Component } from "vue-property-decorator";
import CreateEntityInForm from "./partials/CreateEntityInForm";
import assetService from "@remote-api/asset.service";
import logger from "@/plugins/logger";

@Component({
  components: { CreateEntityFormCard }
})
export default class CreateSite extends CreateEntityInForm<Site> {
  private newSite: Omit<Site, "id"> = {
    name: "",
    description: "",
    location: {
      longitude: 0,
      latitude: 0
    }
  };
  private nameRules = [requiredValidationRule("Site name")];

  async doSave(): Promise<void> {
    try {
      const createdSite = await assetService.createSite(this.newSite);
      this.notifyUser({ message: "Site created", type: "success" });
      logger.info("Created new site", createdSite);
      this.done(createdSite);
    } catch (error) {
      this.notifyUser({ message: "Site creation failed", type: "warning" });
      logger.error("Could not save site", error);
    }
  }
}
