import { ControlClusterModel } from "@/components/products/ControlClusterModel";
import { GuideClusterModel } from "@/components/products/GuideClusterModel";
import { LearnClusterModel } from "@/components/products/LearnClusterModel";
import { LandingPageContainerModel } from "@/models/cluster";
import { ContactNames, contacts } from "@/services/contacts.service";
import VueI18n from "vue-i18n";

export function fetchClusters(i18n: VueI18n): LandingPageContainerModel {
  return {
    clusters: [
      new ControlClusterModel(i18n),
      new GuideClusterModel(i18n),
      new LearnClusterModel(i18n)
    ],
    contactClusterModel: {
      teaser: i18n.tc("landingpage.teaser"),
      contactPersons: [contacts[ContactNames.bvselchow]]
    }
  };
}
