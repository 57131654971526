









import TableWidget from "@/components/apps/assetdetails/TableWidget.vue";
import { WidgetTableData } from "@/ui-models";
import { Component, Prop, Vue } from "vue-property-decorator";
import { take } from "remeda";
import { AssetState } from "@/models/asset";

@Component({
  components: { TableWidget }
})
export default class TopErrorsWidget extends Vue {
  @Prop({ default: () => [] })
  assetStates!: AssetState[];

  get errorData(): WidgetTableData {
    if (this.assetStates.length <= 0) {
      return { labels: [], series: [] };
    } else {
      type ComparablePayload = { timestamp: number; code: number; message?: string; count: number };
      const groupedDiags = this.assetStates.reduce((acc, it) => {
        // ignore negative diagnose codes
        if (it.DP0028! < 0) {
          return acc;
        }

        const payload = { timestamp: it.timestamp, code: it.DP0028!, message: it.DP0030, count: 1 };

        if (acc[payload.code]) {
          const foundPayload = acc[payload.code];
          foundPayload["count"] = foundPayload["count"] + 1;
        } else {
          acc[payload.code] = payload;
        }
        return acc;
      }, {} as { [key: string]: ComparablePayload });

      const sortedgroupedDiags = Object.values(groupedDiags).sort((a, b) =>
        a.count > b.count ? -1 : 1
      );
      return {
        labels: [
          {
            text: this.$t("product.pam.asset.conditions.widget.errors.amount").toString(),
            align: "start",
            value: "count"
          },
          {
            text: this.$t("product.pam.asset.conditions.widget.errors.code").toString(),
            value: "code"
          },
          {
            text: this.$t("product.pam.asset.conditions.widget.errors.message").toString(),
            value: "message"
          }
        ],
        series: take(sortedgroupedDiags, 5)
      };
    }
  }
}
