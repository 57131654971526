



import GenericProductDetails from "@/components/products/GenericProductDetails.vue";
import { ProductDetailsContainerModel } from "@/models/cluster";
import { Component, Vue } from "vue-property-decorator";
import { ToolManagementModel } from "./ToolManagementModel";
@Component({
  components: {
    GenericProductDetails
  }
})
export default class PerformanceManager extends Vue {
  get container(): ProductDetailsContainerModel {
    return new ToolManagementModel(this.$i18n);
  }
}
