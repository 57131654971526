import { Course } from "@/components/apps/smartcollege/smart-college";

export const getCourses: () => Course[] = () => {
  return [
    {
      id: "1",
      name: "Mashinetype 1",
      image: "http://lorempixel.com/400/200/technics/9247",
      progress: 22,
      modules: [
        {
          id: "1a",
          name: "Module A",
          image: "http://lorempixel.com/400/200/technics/31415",
          description: "A very decent module. You'll learn a lot.",
          progress: 75,
          lessons: [
            {
              id: "1a1",
              name: "Lesson 1 - Installation",
              description: "The first lesson!",
              progress: 100,
              pages: ["https://foo", "https://bar"]
            },
            {
              id: "1a1",
              name: "Lesson 2 - Configuration",
              description: "The second lesson!",
              progress: 0,
              pages: ["https://foo", "https://bar"]
            }
          ]
        },
        {
          id: "1b",
          name: "Module B",
          image: "http://lorempixel.com/400/200/technics/1337",
          description: "A very decent module. You'll learn even more.",
          progress: 0,
          lessons: [
            {
              id: "1b1",
              name: "Lesson 1 - The good parts",
              description: "The first lesson!",
              progress: 0,
              pages: ["https://foo", "https://bar"]
            },
            {
              id: "1b2",
              name: "Lesson 2 - The bad parts",
              description: "The second lesson!",
              progress: 0,
              pages: ["https://foo", "https://bar"]
            }
          ]
        }
      ]
    }
  ];
};
