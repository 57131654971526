import { AuthStorage } from "@/services/authentication.storage";

const awsconfig = {
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID!,
    storage: AuthStorage
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_S3_BUCKET_CUSTOMER_ASSETS!,
      region: process.env.VUE_APP_AWS_REGION!
    },
    customPrefix: {
      public: ""
    }
  },
  Analytics: {
    endpoint: "/analytics/",
    apiSecret: process.env.VUE_APP_GOOGLE_ANALYTICS_API_SECRET!,
    measurementId: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!
  },
  awsAppsyncRegion: process.env.VUE_APP_AWS_REGION!
};

export default awsconfig;
