
















































import { Component, Vue, Prop } from "vue-property-decorator";
import { ProductExplanationModel } from "@/models/cluster";
@Component
export default class ProductExplanation extends Vue {
  @Prop()
  explanation!: ProductExplanationModel;

  private NameAsLink = true;

  get videoUrl(): string {
    if (this.explanation.video?.includes("http")) return `${this.explanation.video}`;
    else {
      this.NameAsLink = false;
      return this.explanation.video || "";
    }
  }
}
