























import { Component, Prop, Vue } from "vue-property-decorator";
import { DescriptionSectionModel } from "@/ui-models";
@Component
export default class DescriptionSection extends Vue {
  @Prop()
  dataModel!: DescriptionSectionModel;

  get contentSize() {
    if (this.dataModel.image) return { xl: "6", lg: "6", md: "6", sm: "12", xs: "12" };
    else return { xl: "12", lg: "12", md: "12", sm: "12", xs: "12" };
  }
}
