export interface AssetKey {
  tenant: string;
  assetId: string;
}

export interface Identifiable {
  id: string;
  name: string;
  description?: string;
}

export interface AssetType extends Identifiable {
  image: string;
  manufacturer: string;
}

export interface Location {
  longitude: number;
  latitude: number;
}

export interface Asset extends AssetKey {
  type: AssetType;
  name: string;
  description?: string;
  serialNumber?: string;
  isConnected?: boolean;
  processCell?: ProcessCell;
  site: Site;
  constructionYear?: number;
  contactPerson?: Contact;
}

export interface AssetDTO extends AssetKey {
  typeId: string;
  name: string;
  description?: string;
  serialNumber?: string;
  isConnected?: boolean;
  processCellId?: string;
  siteId: string;
  constructionYear?: number;
  contactPersonId?: string;
}

export interface AssetInput {
  typeId: string;
  name: string;
  description?: string;
  serialNumber?: string;
  isConnected?: boolean;
  processCellId?: string;
  siteId: string;
  contactPersonId?: string;
}

export interface ProcessCell extends Identifiable {
  siteId: string;
}

export interface Site extends Identifiable {
  location: Location;
}

export interface AssetSummaryState extends AssetKey {
  state: number;
  timestamp: number;
  currentBatchName?: string;
}

export interface AssetState extends AssetKey {
  timestamp: number;
  DP0002?: number;
  DP0003?: number;
  DP0005?: number; // Fill-o-matic speed rpm (act)
  DP0006?: number; // Main compr. force MV kN (set) Stat.1
  DP0007?: number; // Main compr. force MV kN (act) Stat.1
  DP0008?: number; //Main compr. force srel % (set) Stat.1: limit value for yellow
  DP0009?: number; //mainCompressionForceSRel Station1
  DP0011?: number; //Pre compr. force MV kN (act) Stat.1
  DP0012?: number; // Good production (set) Stat.1
  DP0013?: number; // Good production (act) Stat.1
  DP0014?: number; // Good production
  DP0015?: number; // Bad production
  DP0020?: string; // Current batch name
  DP0021?: string;
  DP0025?: number;
  DP0027?: number; // indicates the current operating mode of the machine as number
  DP0028?: number;
  DP0029?: string;
  DP0030?: string;
  DP0036?: number; // Main compr. force MV kN (act) Stat.2
  DP0037?: number; //Main compr. force srel % (set) Stat.2: limit value for yellow
  DP0038?: number; //mainCompressionForceSRel Station2
  DP0040?: number; // Pre compr. force MV kN (act) Stat.2
  DP0056?: number; // Energy_L1 W/h
  DP0057?: number; // Energy_L2 W/h
  DP0058?: number; // Energy_L3 W/h
  DP0068?: number;
  DP0069?: number;
  DP0070?: number;
  DP0071?: number;
  DP0078?: string; // the remaining time of the current batch until it reaches the set value of the production counter
  DP0082?: string; // indicates the current operating mode of the machine as text
  DP0084?: number; //Rotor speed rpm (act)
  DP0096?: number; //Tablet thickness: mean value (set) Stat. 1                  N (number type)         mm
  DP0097?: number; //Tablet thickness: mean value (act) Stat. 1                  N (number type)         mm
  DP0098?: number; //Tablet thickness: relative standard deviation (set) Stat. 1 N (number type)         %
  DP0099?: number; //Tablet thickness: relative standard deviation (act) Stat. 1 N (number type)         %
  DP0100?: number; //Tablet thickness: +T2 (set) Stat. 1  N (number type)         %
  DP0101?: number; //Tablet thickness: +T1 (set) Stat. 1  N (number type)         %
  DP0102?: number; //Tablet thickness: -T1 (set) Stat. 1  N (number type)         %
  DP0103?: number; //Tablet thickness: -T2 (set) Stat. 1  N (number type)         %
  DP0120?: number; //Tablet thickness: mean value (set) Stat. 2 N (number type) mm
  DP0121?: number; //Tablet thickness: mean value (act) Stat. 2 N (number type) mm
  DP0122?: number; //Tablet thickness: relative standard deviation (set) Stat. 2 N (number type)  %
  DP0123?: number; //Tablet thickness: relative standard deviation (act) Stat. 2 N (number type)  %
  DP0124?: number; //Tablet thickness: +T2 (set) Stat. 2  N (number type) %
  DP0125?: number; //Tablet thickness: +T1 (set) Stat. 2  N (number type) %
  DP0126?: number; //Tablet thickness: -T1 (set) Stat. 2  N (number type) %
  DP0127?: number; //Tablet thickness: -T2 (set) Stat. 2  N (number type) %
  DP0149?: number; // Upper punch stiffness (act) Stat.1 N
  DP0151?: number; // Lower punch stiffness (act) Stat.1 N
  DP0158?: number; //Main motor current (act) Stat.1
  DP0160?: number; //Filling device motor current max (act) Stat.1
  DP0201?: number; // Max. ejection force (act) Stat.1
  DP0203?: number; // Max. ejection force (act) Stat.2
  DP0371?: number; // Main compr. force srel % (set) Stat.1: 0.5% before that value limit value for red)
  DP0372?: number; // Filling device motor temperature (set) Stat.1
  DP0373?: number; // Filling device motor temperature (act) Stat.1
  DP0374?: number; // Filling device motor temperature (set) Stat.2
  DP0375?: number; // Filling device motor temperature (act) Stat.2
  DP0376?: number; // Main motor temperature (set) Stat.1
  DP0377?: number; // Main motor temperature (act) Stat.1
  DP03xx?: number; //Main compr. force srel % (set) Stat.2: 0.5% before that value limit value for red)
}

export interface ContactInput {
  name: string;
  description?: string;
  role?: string;
  company?: string;
  email?: string;
  phone?: string;
  image?: string;
}

export interface Contact extends ContactInput {
  id: string;
}

export interface AssetDocumentInput {
  assetId: string;
  name: string;
  type: string;
  fileType: string;
  size: number; // in bytes
  urlPath: string;
}

export interface AssetDocument extends AssetDocumentInput {
  id: string;
  uploaded: number; // timestamp
}

export interface ActualProduction {
  goodProduction: number;
  badProduction: number;
}
export interface Progress {
  actual: number;
  set: number;
  percent: number;
  remainingTime: string;
}

export type TimeSeriesData = [string, string | number][];
export type TimelineSeries = string | number[];
export type TimelineSeriesGrouped = Record<string, TimelineSeries[]>;
export interface StationData<T> {
  stationOne: T;
  stationTwo: T;
}

export interface PunchStiffness {
  lower: number;
  upper: number;
}

export interface SensorValue {
  set?: number;
  actual: number;
}

export interface Treshold {
  warning: number;
  danger: number;
  normal: number;
}

export const defaultTreshold: Treshold = {
  normal: 5,
  warning: 10,
  danger: 100
};
