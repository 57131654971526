
































import WidgetCard from "@/components/apps/assetdetails/widgets/WidgetCard.vue";
import { ChartColors } from "@/components/global/charts/ChartColors";
import RadialBar from "@/components/global/charts/RadialBar.vue";
import { last } from "remeda";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConditionModule from "@/store/modules/condition";
import { getModule } from "vuex-module-decorators";
import conditionService from "@remote-api/condition.service";
import logger from "@/plugins/logger";
import { mapFromTimeSeries } from "./mapping/EnergyConsumptionDataMapper";
const conditionStore = getModule(ConditionModule);

@Component({
  components: { RadialBar, WidgetCard }
})
export default class EnergyConsumption extends Vue {
  @Prop({
    default: () => []
  })
  energyConsumption!: number[];
  @Prop()
  assetId!: string;
  @Prop()
  batchNames!: string[];

  batchEnergyConsumption: number[] = [];

  readonly gradient = [ChartColors.green];

  get energyCondumptionInKWh(): number[] {
    return this.energyConsumption.map((it) => it / 1000);
  }

  get currentEnergyConsumption(): number {
    return (last(this.energyConsumption) ?? 0) / 1000;
  }

  get summedEnergyConsumption(): number[] {
    if (this.batchEnergyConsumption.length > 0) return this.batchEnergyConsumption;
    else return this.energyConsumption;
  }

  get currentBatchName(): string {
    return (
      conditionStore.assetSummaryState(this.assetId)?.currentBatchName ??
      last(this.batchNames) ??
      ""
    );
  }

  async fetchRemoteData(): Promise<void> {
    if (!this.assetId || !this.currentBatchName) {
      return;
    }
    try {
      const energyConsumptionDataSeries = await conditionService.fetchEnergyConsumption(
        this.assetId,
        this.currentBatchName
      );
      this.batchEnergyConsumption = mapFromTimeSeries(energyConsumptionDataSeries);
    } catch (error) {
      logger.error("Could not fetch remote energy consumption data", error);
    }
  }

  async mounted(): Promise<void> {
    await this.fetchRemoteData();
  }
}
