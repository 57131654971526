























import { Component, Vue, Prop } from "vue-property-decorator";
import { ProductFeatureModel } from "@/models/cluster";

@Component
export default class ProductFeatures extends Vue {
  @Prop()
  featureTeaser!: string;

  @Prop()
  features!: ProductFeatureModel[];
}
