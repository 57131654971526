






import { Component, Vue, Prop } from "vue-property-decorator";
import { ApexOptions } from "apexcharts";
import configuration from "@/globals/Configuration";

@Component
export default class AreaChart extends Vue {
  @Prop()
  series!: { data: number[]; name: string }[];

  @Prop()
  labels!: string[];

  @Prop()
  colors!: string[];

  get options(): ApexOptions {
    return { ...this.chartOptions, labels: this.labels };
  }

  chartOptions: ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: [...this.colors],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      curve: "straight"
    },
    grid: {
      show: true
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        format: configuration.chart.dateFormat,
        datetimeUTC: false
      }
    },
    yaxis: {
      labels: {
        show: true
      }
    },
    legend: {
      show: false
    },
    tooltip: { x: { format: configuration.chart.dateFormat } }
  };
}
