import type {
  ClusterModel,
  ContactModel,
  ProductDetailsContainerModel,
  ProductDetailsV2,
  ProductModel
} from "@/models/cluster";
import type VueI18n from "vue-i18n";
import { getContact } from "@/services/contacts.service";
import { LearnClusterModel } from "./LearnClusterModel";
import { DescriptionSectionModel } from "@/ui-models";

export class VRCampusModel implements ProductDetailsContainerModel {
  constructor(private $i18n: VueI18n) {}

  get product(): ProductModel {
    return {
      id: "vrcampus",
      title: this.$i18n.tc("vrcampus.product.title"),
      description: this.$i18n.tc("vrcampus.product.description"),
      image: require("@/assets/ui-assets/assets/design01/VRCampus_einfarbig_RGB.svg"),
      imagehover: require("@/assets/ui-assets/assets/design01/VRCampus_mehrfarbig_RGB.svg")
    };
  }

  get details(): ProductDetailsV2 {
    return {
      teaser: {
        text: this.$i18n.tc("vrcampus.details.teaser.text")
      },
      features: [
        {
          title: this.$i18n.tc("vrcampus.details.features.ondemand.title"),
          text: this.$i18n.tc("vrcampus.details.features.ondemand.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_ondemandtraining_2946579_000000.svg")
        },
        {
          title: this.$i18n.tc("vrcampus.details.features.gamification.title"),
          text: this.$i18n.tc("vrcampus.details.features.gamification.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_gamification_3181746_000000.svg")
        },
        {
          title: this.$i18n.tc("vrcampus.details.features.habit.title"),
          text: this.$i18n.tc("vrcampus.details.features.habit.text"),
          icon: require("@/assets/ui-assets/assets/icons/np_virtual-reality_866012_000000.svg")
        }
      ],
      contactTeaser: this.$i18n.tc("vrcampus.details.contactTeaser"),
      explanation: {
        title: this.$i18n.tc("vrcampus.details.explanation.title"),
        text: this.$i18n.tc("vrcampus.details.explanation.text"),
        image: require("@/assets/ui-assets/assets/VRCampus_Helmut.png"),
        video: "https://www.youtube.com/embed/dzdxW9PVOe8"
      },
      featureTeaser: this.$i18n.tc("vrcampus.details.featureTeaser")
    };
  }

  get advantages(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("vrcampus.details.advantages.title").toString(),
      text: this.$i18n.t("vrcampus.details.advantages.text").toString()
    };
  }

  get additionalDescription(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("vrcampus.details.productinfo.title").toString(),
      text: this.$i18n.t("vrcampus.details.productinfo.text").toString(),
      image: require("@/assets/ui-assets/assets/VRCampus_Helmut.png"),
      containImage: true
    };
  }

  get interest(): DescriptionSectionModel {
    return {
      title: this.$i18n.t("vrcampus.details.interest.title").toString(),
      text: this.$i18n.t("vrcampus.details.interest.text").toString()
    };
  }

  get cluster(): ClusterModel {
    return new LearnClusterModel(this.$i18n);
  }

  get contacts(): ContactModel[] {
    return [getContact("tklingenhoff")];
  }
}
