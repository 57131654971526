












import { Component, Prop, Vue } from "vue-property-decorator";
import { Media, thumbnailMediaURL } from "@/models/media";
import { smartGuideStorage } from "@/plugins/storage";

@Component
export default class RemoteImage extends Vue {
  private thumbnailUrl: string | null = null;

  get url() {
    return this.thumbnailUrl;
  }
  @Prop()
  media!: Media;

  async mounted() {
    this.thumbnailUrl = await thumbnailMediaURL(this.media, smartGuideStorage);
  }
}
