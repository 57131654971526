































import { Component, Vue, Prop } from "vue-property-decorator";
import logger from "@/plugins/logger";
@Component
export default class LanguageSelect extends Vue {
  @Prop()
  big!: boolean;

  @Prop()
  list!: boolean;

  private supportedLanguages = [
    { title: "EN", code: "en" },
    { title: "DE", code: "de" },
    { title: "PT", code: "pt" },
    { title: "ES", code: "es" },
    { title: "中文", code: "zh" }
  ];

  get enabledLanguages() {
    return this.supportedLanguages.filter((lang) =>
      this.$i18n.availableLocales.includes(lang.code)
    );
  }

  get selectedLanguage() {
    const locale = this.$i18n.locale;
    return (
      this.enabledLanguages.find((it) => locale.toLowerCase().startsWith(it.code.toLowerCase())) ||
      this.enabledLanguages[0]
    );
  }
  private changeLanguage(newLanguage: { title: string; code: string }) {
    try {
      this.$i18n.locale = newLanguage.code;
      document.title = this.$i18n.t("global.page.title").toString();
    } catch (error) {
      logger.error("could not select language", error);
    }
  }
}
