import { IVueI18n } from "vue-i18n";

type ValidationFn = (input: string) => boolean | string;

export const passwordValidationRules: ValidationFn[] = [
  (a) => (a && a.length >= 8) || "The password must be at least 8 characters long.",
  (a) => /[A-Z]/.test(a) || "The password must contain one upper-case letter (e.g. A-Z.)",
  (a) => /[a-z]/.test(a) || "The password must contain at least one lower-case letter (e.g. a-z.)",
  (a) => /[0-9]/.test(a) || "The password must contain at least one number (e.g. 0-9.)",
  (a) =>
    /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(a) ||
    "The password must contain a special character" // from this set https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies..html
];

const emailValidationRegex = /.+@.+\..+/;

export function validatePassword(input: string): boolean | string {
  return (
    passwordValidationRules.map((it) => it(input)).find((it) => typeof it === "string") || true
  );
}

export function requiredValidationRule(message: string): ValidationFn {
  return (v: string) => !!v || message;
}

export function translatedRequiredValidationRule(inputName: string, i18n: IVueI18n): ValidationFn {
  const message = i18n.t("global.form.validation.required", [i18n.t(inputName)]);
  return (v: string) => !!v || String(message);
}

export function emailValidationRule(): ValidationFn {
  return (v: string) => emailValidationRegex.test(v) || "global.form.validation.email.invalid";
}

export const emailValidationRules: ValidationFn[] = [
  requiredValidationRule("E-mail"),
  (v: string) => emailValidationRegex.test(v) || "E-mail must be valid"
];

export function translatedEmailValidationRules(): ValidationFn[] {
  return [requiredValidationRule("global.form.validation.email.required"), emailValidationRule()];
}
export const phoneValidationRules: ValidationFn[] = [
  requiredValidationRule("Phone"),
  (v: string) =>
    /^\+\d{1,3}\s\d{2,3}\s\d{2,3}\s\d{4}|^\+\d{1,3}\s\d{1,14}(\s\d{1,13})?|^\(\d{3}\)\s\d{3}\s\d{4}?/im.test(
      v
    ) || "Phone must be valid"
];

export function constructionYearValidation(year: string): boolean | string {
  if (!year || year.length === 0) {
    return true;
  }
  const yearValue = parseInt(year, 10);
  if (!isNaN(yearValue)) {
    return yearValue >= 1950 || "Please enter a year >= 1950";
  } else {
    return "Could not parse year!";
  }
}
